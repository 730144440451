import ConceptualizationImage from "../../assets/img/studio/Studios-InnovationCenter-Conceptualization.jpg";
import UserJourneyMappingImage from "../../assets/img/studio/Studios-InnovationCenter-UserJourneyMapping.jpg";
import PrototypesImage from "../../assets/img/studio/Studios-InnovationCenter-PrototypesandPOCs.jpg";
import GtmAccelerationImage from "../../assets/img/studio/GTMAcceleration.jpg";
import TCOModelingImage from "../../assets/img/studio/Studios-InnovationCenter-TCOModeling.jpg";

import cicd from "../../assets/img/studio/Studios-DevOps-CICD.jpg";
import CloudService from "../../assets/img/studio/Studios-DevOps-CloudServiceManagement.jpg";
import SiteReliability from "../../assets/img/studio/Studios-DevOps-SRE.jpg";
import ProcessAutomation from "../../assets/img/studio/Studios-DevOps-ProcessAutomation.jpg";
import LabOps from "../../assets/img/studio/Studios-DataLab-LabOps.jpg";

import ExperienceEngineeringImage from "../../assets/img/studio/Studios-DesignStudio-ExperienceEngineering.jpg";
import AICharacterCreationImage from "../../assets/img/studio/Studios-DesignStudio-AICharacterCreation.jpg";
import BrandedCustomVoicesImage from "../../assets/img/studio/Studios-DesignStudio-BrandedCustomVoices.jpg";
import ProfessionalVideoProductionImage from "../../assets/img/studio/Studios-DesignStudio-ProfessionalVideoProduction.jpg";
import SoundDesignImage from "../../assets/img/studio/Studios-DesignStudio-SoundDesign.jpg";

import DataScienceImage from "../../assets/img/studio/Studios-DataLab-DataScience.jpg";
import DataPipelineImage from "../../assets/img/studio/Studios-DataLab-DataPipeline.jpg";
import DataGovernanceImage from "../../assets/img/studio/Studios-DataLab-DataGovernance.jpg";
import DataVisualizationImage from "../../assets/img/studio/Studios-DataLab-DataVisualization.png";

import PerformanceBenchmarkingImage from "../../assets/img/studio/Studios-HPCStudio-PerformanceBenchmarking.jpg";
import PurposeBuiltCloudInfrastructureImage from "../../assets/img/studio/Studios-HPCStudio-Purpose-builtCloudInfrastructure.jpg";
import HyperscaleParallelApplicationsImage from "../../assets/img/studio/Studios-HPCStudio-HyperscaleParallelizedApplications.jpg";
import WorkloadManagementTemplatesImage from "../../assets/img/studio/Studios-HPCStudio-WorkloadManagementTemplates.jpg";
import HPCModelingSimulationImage from "../../assets/img/studio/Studios-HPCStudio-HPCModeling&Simulation.jpg";

import GreenTransformationImage from "../../assets/img/studio/Studios-GreenRoom-GreenTransformation.jpg";
import AccountableESGImage from "../../assets/img/studio/Studios-GreenRoom-AccountableESGMetrics&KPIs.jpg";
import EnergyEfficientComputingImage from "../../assets/img/studio/Studios-GreenRoom-Energy-EfficientComputing.jpg";
import EnvironmentalImpactBlueprintsImage from "../../assets/img/studio/Studios-GreenRoom-EnvironmentalImpactBlueprints.jpg";
import SustainabilitySimulationsImage from "../../assets/img/studio/Studios-GreenRoom-SustainabilitySimulations.jpg";

/*
helmet: [
  {
    title: "abc",
    description: "abc",
    keywords: "abc"
  }
],
*/
const StudiosContentObject = {
  InnovationCenter: {
    helmet: [
      {
        title: "Terawe Corp | Digital Technology Labs & Innovation Center",
        description:
          "Turn your business aspirations into reality with our digital innovation framework, innovation solutions, and our innovation-as-a-service model",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Innovation Center",
            "image": "",
            "description": "Turn your business aspirations into reality with our digital innovation framework, innovation solutions, and our innovation-as-a-service model",
            "brand": "Terawe"
          }`
      }
    ],
    pageTitle: {
      title: "Innovation Center | Terawe Corporation",
      paragraph:
        "Providing the capabilities, experience, and working environment for direct, face-to-face collaborative efforts that can best operationalize and implement your solution or service while avoiding devastating pitfalls.",
      link: "/InnovationCenter",
      heading: "Innovation Center"
    },
    conceptualization: {
      title: "Conceptualization",
      image: ConceptualizationImage,
      paragraph: [
        "How you execute the very first steps of turning a brilliant idea into reality will ultimately determine success or failure. In an age of bureaucracy and microservices, you just can’t accomplish this feat in a vacuum.",
        "<b>Terawe Innovation Center </b> provides the collaborative canvas to work face-to-face with our clients to fully conceptualize ideas, getting all implementation and deployment dependencies on the table to eliminate uncertainties down the road and put you on a direct trajectory to success."
      ],
      points: [
        "Proper outcome fulfillment",
        "Clear market differentiation",
        "Technology & platform mapping",
        "Operational best practices",
        "Experience delivery KPIs",
        "International regulatory compliance"
      ],
      hashLink: "/InnovationCenter#conceptualizationID"
    },
    userJourney: {
      title: "User Journey Mapping",
      image: UserJourneyMappingImage,
      paragraph: [
        "To win customers you must understand customers. There’s no better way to anticipate what your customers will expect than mapping out the intended journey in advance.",
        "Our entire philosophy is based on a ‘Customer First’ premise. The <b>Terawe Innovation Center </b> brings our end-to-end service delivery experiences and insights to bear upon your goals to ensure success with the intended experience delivery."
      ],
      points: [
        "Dependency awareness",
        "Behavioral trends",
        "Platform & device ubiquity",
        "Legacy vs. new customer dynamic",
        "Microservice integration",
        "Satisfying, connected experiences"
      ],
      hashLink: "/InnovationCenter#userJourneyID"
    },
    protoypesPOCs: {
      title: "Protoypes & POCs",
      image: PrototypesImage,
      paragraph: [
        "Prototypes and/or proofs of concept (“POCs”) are a necessity for many new products and services. But organizations need efficient, cost effective means of building them to stay within their timeline and budget constraints.",
        "We consider prototypes/POCs to be a vetting requirement for any successful solution. The <b>Terawe Innovation Center </b> provides the necessary frameworks, workspaces and licenses necessary for a productive prototyping effort that provides the desired insight and forethought."
      ],
      points: [
        "Digital twins",
        "Staging & testing environments",
        "Localization considerations",
        "Infrastructure scaling best practices",
        "Functionality gap identification",
        "Optimized corrective measures"
      ],
      hashLink: "/InnovationCenter#prototypesPOCsID"
    },
    GtmAcceleration: {
      title: "GTM Acceleration",
      image: GtmAccelerationImage,
      paragraph: [
        "Building the right solution is only half the battle. Getting your audience to experience the value you’ve created can often be the more difficult part of the ‘New Solution’ equation.",
        "Fortunately, we specialize in developing messaging frameworks to accelerate GTM strategies for emerging, transformational solutions. The <b>Terawe Innovation Center </b> helps you build the right narratives and commercial deployment strategy to give your solution the best, most immediate chance of success."
      ],
      points: [
        "Differentiated branding",
        "Messaging & positioning frameworks",
        "Legacy vs. new customer adoption",
        "Sales & distribution strategy",
        "Channel amplification"
      ],
      hashLink: "/InnovationCenter#GtmAccelerationID"
    },
    TcoModeling: {
      title: "TCO Modeling",
      image: TCOModelingImage,
      paragraph: [
        "Calculating a realistic and accurate cost structure of any new solution can be the trickiest part of the development process. There’s no historical data for greenfield services, nor any precedent to lend educated perspectives.",
        "Terawe offers an extensive knowledge base about how markets and technology platforms evolve in tandem, enabling our clients with a balanced, educated perspective on ‘best practices’ for long term service cost models. Let the <b>Terawe Innovation Center </b> help you build an accurate cost picture for your solution – applicable for both today and tomorrow."
      ],
      points: [
        "Cost ‘high impact’ points",
        "Optimized platform best practices",
        "Intelligent licensing strategy",
        "Discounted service offers",
        "Partner ecosystem"
      ],
      hashLink: "/InnovationCenter#TcoModelingID"
    }
  },
  OperationsCenter: {
    helmet: [
      {
        title: "Terawe DevOps | CI/CD, Infrastructure as Code, Operations",
        description:
          "Take advantage of Terawe's continuous integration, infrastructure test automation, and infrastructure-as-code DevOps services to increase your efficiencies.",
        keywords: "abc"
      }
    ],
    pageTitle: {
      title: "Operations Center | Terawe Corporation",
      heading: "Operations Center",
      paragraph:
        "Augmentation of critical systems and processes with the operational speed, efficiency, and accuracy needed to achieve your goals",
      link: "/Operations-Center"
    },
    CiCd: {
      title: "CI/CD",
      image: cicd,
      paragraph:
        "Our comprehensive CI/CD solutions empower organizations to streamline their development and deployment processes, enabling them to stay ahead in today's rapidly evolving digital landscape. Explore the key capabilities of our CI/CD offerings and learn how enterprise customers can unlock the full potential of CI/CD:",
      points: [
        `
        <span>
          <b>Seamless Integration & Collaboration:</b> Seamlessly integrate your code repositories, version control systems, and development tools for smooth collaboration across teams. Achieve real-time visibility into the entire software development lifecycle, promoting transparency and effective communication.
        </span>
        <br />
        <br />
        <span>
        <b>Automated Build & Testing:</b> Automate & integrate your build, test, and validation processes to ensure consistent and reliable software quality.
        </span>
        <br />
        <br />
        <span>
        <b>Deployment & Release Efficiency:</b> Streamline your deployment pipelines to enable faster and more frequent releases with minimal downtime. Implement controlled release strategies, such as feature toggles and canary deployments, to mitigate risks and ensure smooth rollouts.
        </span>
        <br />
        <br />
        <span>
        <b>Dynamic Infrastructure Scaling:</b> Optimize performance and resource utilization harnessing the power of containerization and orchestration technologies to deploy applications reliably and efficiently.",
        </span>
        <br />
        <br />
        <span>
        <b>Constant Monitoring & Feedback:</b> Establish a continuous monitoring & feedback loop with real-time monitoring, logging, and alerting mechanisms to proactively identify and resolve issues. Utilize analytics and performance metrics to gain insights into the health, stability, and usage patterns of your applications.
        </span>
        <br />
        <br />
        <span>
        <b>Enterprise-Grade Security & Compliance:</b> Leverage our robust security practices and standards to protect your software and data throughout the CI/CD pipeline.
        </span>
        <br />
        <br />
        Experience the transformative power of CI/CD and revolutionize your software development processes with our tailored solutions designed to meet the unique needs of your organization.`
      ],
      subTitle:
        "Embrace the future of software delivery, driving innovation, agility, and competitive advantage.",
      hashLink: "/Operations-Center#CiCdID"
    },
    agilePipelines: {
      title: "Agile Pipelines & Workflows",
      image: ProcessAutomation,
      subTitle: "Streamline your DevOps processes with effortless efficiency",
      paragraph: `Unleash the true power of continuous integration with our automated pipelines and workflows. Achieve seamless software delivery and optimizing development processes, enabling faster and more reliable deployment cycles. <br/> Explore the key capabilities of our agile pipelines and workflows: `,
      points: [
        `
        <span><b>Seamless Integration:</b> Effortlessly integrate your code repositories, testing frameworks, and deployment environments for smooth collaboration and consistent delivery. </span>
         <br />
        <br />
        <span><b>Agile Deployment:</b> Achieve rapid and iterative software deployment, ensuring that your applications are always up-to-date and readily available to users. </span>
         <br />
        <br />
        <span><b>Intelligent Orchestration:</b> Benefit from intelligent orchestration of tasks, ensuring efficient execution of your CI/CD processes while minimizing errors and manual intervention. </span>
         <br />
        <br />
        <span> <b>Automated Testing:</b> Automate your testing processes to enhance quality assurance, reduce time-to-market, and deliver robust software products with confidence. </span>
        <br />
        <br />
        <span><b>Scalable Infrastructure-as-Code (IaC):</b> Leverage our advanced cloud service management expertise to effortlessly scale your infrastructure as your business grows, ensuring optimal performance and resource utilization. Supercharge your development lifecycle and unlock new levels of efficiency with our cutting-edge IaC automation solutions.</span>`
      ],
      hashLink: "/Operations-Center#agilePipelinesID"
    },
    labOps: {
      title: "LabOps",
      image: LabOps,
      subTitle: "Revolutionize your data workflows",
      paragraph:
        "Unlock the true potential with <b>LabOps</b>, revolutionizing the way you manage, scale and optimize your workflows. With our state-of-the-art LabOps solutions, we offer a comprehensive suite of capabilities designed to enhance efficiency, productivity, and accuracy in your lab.",
      points: [
        `<span><b>Advanced Hardware Monitoring:</b> Gain real-time visibility into your lab infrastructure with advanced hardware monitoring capabilities. Monitor critical parameters such as temperature, humidity, power consumption, and equipment performance for proactive maintenance and optimized operations.</span>
<span>
<br />
<br /><b>Long-haul Stress Testing:</b> Perform rigorous testing to ensure durability and performance under demanding conditions. Identify potential weaknesses and bottlenecks, enabling proactive measures to minimize downtime and maximize productivity. Troubleshoot issues and root-cause to mitigate failures.</span>
<span>
<br />
<br /><b>Workflow Optimization:</b> Streamline and optimize your workflows for enhanced efficiency and throughput. Identify and eliminate process bottlenecks, automate repetitive tasks, and standardize protocols to accelerate research and development activities.</span>
<span>
<br />
<br /><b>Resource Allocation and Planning:</b> Perform Lab Capability planning with ease with modern intuitive dashboards. Optimize resource allocation and utilization within your laboratory environment. Gain insights into equipment availability, capacity planning, and scheduling to maximize operational efficiency and minimize downtime.</span>
<span>
<br />
<br /><b>Data Management, Compliance & and Integration:</b> Seamlessly manage and integrate your laboratory data, ensuring data integrity, traceability, and accessibility. Implement secure data storage, advanced search capabilities, and seamless data exchange with existing systems, facilitating collaboration and knowledge sharing. Implement robust data security measures, maintain documentation, and establish an audit trail for seamless regulatory compliance.</span>`
      ],
      hashLink: "/Operations-Center#labOpsID"
    },
    reliabilityEngineering: {
      title: "Site Reliability Engineering",
      image: SiteReliability,
      paragraph: [
        "Site reliability engineering (“SRE”) makes good the promise of delivering captivating, repeatable customer experiences that are uncompromised by updates to underlying infrastructure, new application versions, or other potentially-crippling functions.",
        "Our expectation is that operational processes should never interfere with user experiences. We use system redundancy, strategic failover, auto-scaling, and other tactics that enable you to provide the most competitive SLAs in the market with confidence and conviction."
      ],
      points: [
        "Seamless failover",
        "Backup & recovery",
        "Zero-latency fail safes",
        "Reduced waste"
      ],
      hashLink: "/Operations-Center#reliabilityEngineeringID"
    },
    cloudService: {
      title: "Cloud Service Management",
      image: CloudService,
      paragraph: [
        "Optimizing your use of cloud platforms to achieve maximum versatility for the lowest cost can be an extremely challenging endeavor. With multiple public clouds as well as internal private clouds, it can get even more complex.",
        "Terawe’s <b>Operations Center</b> helps interconnect multiple cloud service control endpoints into a seamless service dashboard that aggregates varying protocols and analytic data from multiple clouds into an unified, intuitive, and meaningful experience."
      ],
      points: [
        "Multi-cloud control plane",
        "Unified data estate",
        "Cost & operational controls",
        "Integrated security & compliance"
      ],
      hashLink: "/Operations-Center#cloudServiceID"
    }
  },
  DesignStudio: {
    helmet: [
      {
        title: "TUX Studio | Video, Graphic Design & Media Production Studio",
        description:
          "We deliver the experiences our clients want for their customers using character branding, conversational AI, design engineering, and simplified story telling.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Design Studio",
            "image": "",
            "description": "We deliver the experiences our clients want for their customers using character branding, conversational AI, design engineering, and simplified story telling.",
            "brand": "Terawe"
          }`
      }
    ],
    pageTitle: {
      heading: "Design<br/> Studio",
      paragraph:
        "Blending what inspires people with cutting-edge technologies to help convert our clients’ imagination into realistic, delightful experiences for their channels, events, and customers",
      link: "/DesignStudio"
    },
    experienceEngineering: {
      title: "Experience Engineering",
      image: ExperienceEngineeringImage,
      paragraph: [
        "People don’t buy products or services anymore…<b className='colorPrimary'>they buy experiences.</b>",
        "But, instead of producing content that is designed to generate an experience, we start with identifying and understanding the desired experience first…and then engineer things to hit that mark. The result is a more optimized, efficient process that meets…and often beats...client expectations of what is possible."
      ],
      points: [
        "360 degrees of immersion",
        "Multi-sensory stimuli",
        "Organic, natural feel",
        "Platform & device agnostic"
      ],
      hashLink: "/DesignStudio#experienceEngineeringID"
    },
    AICharacterCreation: {
      title: "AI Character Creation",
      image: AICharacterCreationImage,
      paragraph: [
        "AI has come a long way in recent years, now often powering avatars and agents that interact with humans for everything from sales ordering to support tickets. Terawe is uniquely positioned to help you build and deploy AI-powered characters that address your business objectives without ratcheting up your labor costs."
      ],
      points: [
        "Realistic, lifelike interactivity",
        "Higher accuracy & performance",
        "Increased efficiencies",
        "Greater customer satisfaction",
        "Better brand differentiation"
      ],
      hashLink: "/DesignStudio#AICharacterCreationID"
    },
    BrandedCustomVoices: {
      title: "Branded Custom Voices",
      image: BrandedCustomVoicesImage,
      paragraph: [
        "Ever thought of what exactly the ‘voice of your company’ should sound like? Thanks to powerful speech synthesis tools and AI platforms, you can now make that happen. Male or female, old or young, whatever language and/or dialect you want…Terawe provides both the design guidance and engineering skills to bring your ideal speaking representative to life."
      ],
      points: [
        "Realistic, lifelike sounding",
        "Supportive, logical intonations",
        "Natural interactivity",
        "Brand personalization",
        "Long-term customer loyalty"
      ],
      hashLink: "/DesignStudio#BrandedCustomVoicesID"
    },
    ProfessionalVideoProduction: {
      title: "Professional Video Production",
      image: ProfessionalVideoProductionImage,
      paragraph: [
        "Video has become the most common storytelling mechanism on earth. But getting a professional quality video that illustrates your story in a compelling, convincing manner can be challenging…and expensive. At Terawe, we have the skills and experience using the latest animation and video production tools to help your story come to life. Let us help you share your story in a way that people will remember."
      ],
      points: [
        "On-location capturing",
        "Augmentation with data and graphics",
        "Captioning/transcription/transcoding",
        "Multi-platform publishing"
      ],
      hashLink: "/DesignStudio#ProfessionalVideoProductionID"
    },
    SoundDesign: {
      title: "Sound Design",
      image: SoundDesignImage,
      paragraph: [
        "How something sounds can make or break a great experience. Unfortunately, sound often takes a backseat to sight. At Terawe, we understand the impact that sound has on a person’s experience, especially for branded exercises in marketing and promotion. As such, we leverage sound creatively to augment a project’s effectiveness, help land the intention with aplomb, and give your brand a unique distinction in the marketplace."
      ],
      points: [
        "Voiceover narration",
        "Needle drop & custom music",
        "Spatial effects for a natural feel",
        "Any format, any channel supported"
      ],
      hashLink: "/DesignStudio#SoundDesignID"
    }
  },
  DataLab: {
    helmet: [
      {
        title: "Terawe | Big Data Engineering, Governance, and Management",
        description:
          "Terawe's Data Lab offers comprehensive engineering and operational support services for your data pipelines, quality assurance, and architecture.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Data Lab",
            "image": "",
            "description": "Terawe's Data Lab offers comprehensive engineering and operational support services for your data pipelines, quality assurance, and architecture.",
            "brand": "Terawe"
          }`
      }
    ],
    pageTitle: {
      heading: "Data Lab",
      paragraph:
        "identify, prepare, cleanse, and streamline your data estate with you to ensure smooth functionality and deployment of new solutions or services.",
      link: "/DataLab"
    },
    dataEngineering: {
      title: "Data Engineering",
      image: DataScienceImage,
      subTitle: "Transform your data into a strategic asset",
      paragraph:
        "Data engineering is the backbone of successful data-driven organizations. Our <b>Data Lab</b> offers comprehensive solutions to supercharge your data engineering processes. Let us help you unlock the true value of your data, ensure its quality, reliability, and accessibility, and enable you with data-driven decision-making that can fuel innovation throughout your organization.",
      points: [
        `<span></span><b>Automated Data Quality Assessment:</b> Automate data quality assessment processes to ensure the accuracy, completeness, and consistency of your data. implement data profiling, validation, and anomaly detection techniques to identify and address data quality issues proactively.</span><br/> <br/>
        <span><b>Automated Data Cleansing & Labeling:</b> Leverage automated data cleansing techniques to handle missing values, duplicates, and inconsistencies in your datasets. Implement data normalization, deduplication, automated labeling, and error correction algorithms to ensure data cleanliness and integrity.</span><br/> <br/>
        <span><b>Monitoring for Data Drift:</b> Implement advanced monitoring mechanisms to detect and manage data drift in your datasets. Continuously compare incoming data against baseline models and statistical profiles to identify unexpected changes and take necessary actions.</span>`
      ],
      hashLink: "/DataLab#dataEngineeringID"
    },
    bigData: {
      title: "Big Data Pipelines",
      image: DataPipelineImage,
      subTitle: "Robust, adaptive data process management",
      paragraph:
        "Today’s data-driven organizations must have adaptability and agility to accommodate a variety of data sources, types, and sizes being generated. Our <b>Data Lab</b> helps establish an automated flow of your data storage, processing, and eventual insight extraction that enables you to transform your data into advanced, actionable analytics.",
      points: [
        `<span>Seamlessly integrate data from various sources, such as databases, APIs, and streaming platforms, for a unified and comprehensive view of your data across cloud boundaries.</span><br /> <br />
        <span>Design and implement efficient Big Data pipelines to ingest, transform, and integrate data from diverse sources.</span><br /> <br />
        <span>Streamline Big Data processing workflows, ensuring smooth data flow and minimizing latency for real-time and batch processing.</span><br /> <br />
        <span>Implement data integration techniques, including ETL (Extract, Transform, Load) and ELT (Extract, Load, Transform), to consolidate and harmonize data.</span><br /> <br />
        <span>Migrate and transform structured and unstructured data across on-premises, edge and cloud boundaries, establishing a unified data estate with versatile implementation options.</span>`
      ],
      hashLink: "/DataLab#bigDataID"
    },
    governanceCompliance: {
      title: "Governance & Compliance",
      image: DataGovernanceImage,
      subTitle: "Keep your data under your control.",
      paragraph:
        "Maintaining proper data governance controls is a critical, necessary component of broader operational security and continuity. Our <b>Data Lab</b> helps increase your organizational agility by identifying data lineage and generating audit reports that can meet your specific compliance requirements seamlessly.",
      points: [
        `<span></span><b>Metadata Management and Data Governance:</b> Leverage scalable data storage and processing technologies, such as distributed file systems and cloud-based infrastructure, to handle large volumes of data efficiently. Achieve optimal performance and resource utilization for data-intensive workloads. Establish robust data governance practices to ensure data privacy, compliance, and security.</span><br /><br />
        <span><b>Data Management, Compliance &amp; and Integration:</b> Seamlessly manage and integrate your laboratory data, ensuring data integrity, traceability, and accessibility. Implement secure data storage, advanced search capabilities, and seamless data exchange with existing systems, facilitating collaboration and knowledge sharing. Implement robust data security measures, maintain documentation, and establish an audit trail for seamless regulatory compliance.</span>`
      ],
      hashLink: "/DataLab#governanceComplianceID"
    },
    visualizationReporting: {
      title: "Visualization & Reporting",
      image: DataVisualizationImage,
      paragraph: [
        "Analytics and insights are only as good as they can be communicated clearly and effectively to the people in your organization who need them the most. Terawe’s Data Lab specializes in aggregating unique visibility perspectives across your data estate, regardless of location, scale, or type, that are custom-tailored to the specific business or market outcomes desired.",
        "<b>Data Visualization and Reporting:</b> Enable data visualization and reporting capabilities to derive meaningful insights and communicate data-driven findings effectively. Empower users to explore and analyze data through intuitive dashboards and interactive visualizations.",
        "<b>Customized, rich dashboards:</b> Make data-driven decisions with powerful visualizations on-the-fly. Provide complete visibility across your entire organization, with powerful remote visualizations for field and at-home workers and those on small devices. Enable instant responsiveness that provides workstation-class experiences on any device, anywhere in the world."
      ],
      hashLink: "/DataLab#visualizationReportingID"
    }
  },
  HPCStudio: {
    helmet: [
      {
        title: "Terawe Simplified HPC | Cloud Infrastructure at Scale",
        description:
          "Boost and accelerate your HPC application performance in our ManageX cloud workspace environments without changing processes or disrupting your workflow ",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "HPC Studio",
            "image": "",
            "description": "Boost and accelerate your HPC application performance in our ManageX cloud workspace environments without changing processes or disrupting your workflow ",
            "brand": "Terawe"
          }`
      }
    ],
    pageTitle: {
      heading: "HPC Studio",
      paragraph:
        "Providing both the people and the processes for a ‘full stack approach’ to enabling strategic combinations of parallelism and communications that address growing demands.",
      link: "/HPCStudio"
    },
    performanceBenchmarking: {
      title: "Performance Benchmarking",
      image: PerformanceBenchmarkingImage,
      paragraph: [
        "Understanding what the boundaries are helps organizations plan and execute their initiatives more effectively. Nowhere is this more true than with infrastructure utilization…if you know just how far you can push a system to function, you can better plan and optimize your processes accordingly to fit within those boundaries.",
        "Performance benchmarking is the main method by which organizations can determine what those IT boundaries are for the specific type of applications they are using. Terawe’s <b>HPC Studio</b> provides performance benchmark evaluation, testing and reporting for all common industry HPC codes such as STAR-CCM+, LAMPPS, FLUENT, CP2K, and many others."
      ],
      points: [
        "Common benchmark templates",
        "Comprehensive, aggregated reporting",
        "Existing cloud environments",
        "Tailored to your specific workflows"
      ],
      hashLink: "/HPCStudio#performanceBenchmarkingID"
    },
    PurposeBuiltCloudInfrastructure: {
      title: "Purpose-built cloud infrastructure",
      image: PurposeBuiltCloudInfrastructureImage,
      paragraph: [
        "Spanning down from scientists and engineers, through broad and deep toolchains and enabling middleware, HPC reaches to the depths of increasingly heterogeneous processors and the operating systems that allow them to be abstracted for use.",
        "Terawe is a leading HPC practice because we have direct, hands-on experience with how these heterogeneous systems are built and differentiate. Let our <b>HPC Studio</b> help accelerate your vision into reality in a fraction of time it would normally take."
      ],
      points: [
        "GPU/FPGA acceleration",
        "Parallelized application workflows",
        "Virtually limitless scale",
        "Up to petabyte-size data sets",
        "Cost controls"
      ],
      hashLink: "/HPCStudio#PurposeBuiltCloudInfrastructureID"
    },
    hyperscaleParallelApplications: {
      title: "Hyperscale parallel applications",
      image: HyperscaleParallelApplicationsImage,
      paragraph: [
        "Parallelization is at the heart of HPC. But developing a parallelized application or computing environment is hardly a simple endeavor. Scaling is something that can be addressed many ways, so it’s critical to understand which methodology is optimum for your needs.",
        "Terawe’s <b>HPC Studio</b> makes scaling simple. Whether it’s the application processes or the computing environment itself, Terawe brings decades of deep-level expertise in hyperscaling, parallelization, and accelerators to catapult your vision into reality."
      ],
      points: [
        "Avoid costly bottlenecks",
        "Scale productivity in minimal time",
        "Best practices for specific algorithms",
        "Optimized cloud utilization"
      ],
      hashLink: "/HPCStudio#hyperscaleParallelApplicationsID"
    },
    WorkloadManagementTemplates: {
      title: "Workload Management Templates",
      image: WorkloadManagementTemplatesImage,
      paragraph: [
        "Managing application workloads has always been a of delicate balance of time, effort, and budget. And while the cloud offers enormous scale and speed benefits, it may not come pre-configured or pre-built with your specific toolset.",
        "In Terawe’s <b>HPC Studio</b>, we dynamically create the cloud HPC infrastructure specific to the exact parameters and dependencies of your application or industrial use case. This enables you keep doing what you always have been doing with no code changes or shifting workflows…BUT with less hassle and for a lower cost."
      ],
      points: [
        "HPC infrastructure on demand",
        "Simple, intuitive templatized approach",
        "Scale up, out, or both",
        "Repeatable, reproducible workflows",
        "AI-mediated parameter studies"
      ],
      hashLink: "/HPCStudio#WorkloadManagementTemplatesID"
    },
    HPCModelingSimulation: {
      title: "HPC Modeling & Simulation",
      image: HPCModelingSimulationImage,
      paragraph: [
        "The extent of tangible benefits offered by modeling and simulation practices far outnumber the organizations familiar and/or experienced with modeling and simulation engineering.  This knowledge gap can be both limiting and alienating for those very same organizations that may have the greatest benefit potential from those processes.",
        "Terawe has significant experience not just in building models and conducting simulations, but understanding how those processes impact the larger application or initiative. Terawe’s <b>HPC Studio</b> brings this unique experience to bear upon our client’s projects, harnessing their best value without incurring massive exploratory cost or time."
      ],
      points: [
        "Streamline resources, reduce waste",
        "Optimize user experiences",
        "Faster GTM at a lower cost",
        "Gain & use deep insights"
      ],
      hashLink: "/HPCStudio#HPCModelingSimulationID"
    }
  },
  GreenRoom: {
    helmet: [
      {
        title: "Terawe Corporation | Achieving Your ESG Sustainability Goals",
        description:
          "Sustainability is the new normal for application development, engineering, and business practices.  Let us help you reach your sustainable aspirations.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Green Room",
            "image": "",
            "description": "Sustainability is the new normal for application development, engineering, and business practices.  Let us help you reach your sustainable aspirations.",
            "brand": "Terawe"
          }`
      }
    ],
    pageTitle: {
      heading: "Green Room",
      paragraph:
        "Sustainable engineering practices to help your organization develop the solution it needs while staying in alignment with your environmental impact objectives.",
      link: "/GreenRoom"
    },
    GreenTransformation: {
      title: "Green Transformation",
      image: GreenTransformationImage,
      paragraph: [
        "Sustainability is a greenfield initiative for all of us. The ability to create and execute any sustainability program or measure with confidence is still evolving.",
        "As we are both familiar with platform sustainability tools and experienced with engineering to sustainability KPIs, our <b>Green Room</b> is perfectly positioned and equipped to provide thought leadership and actionable development pathways to your sustainability aspirations."
      ],
      points: [
        "Comprehensive sustainability roadmaps",
        "Use case scenario mapping",
        "AI-powered insights",
        "Infrastructure utilization best practices",
        "Market leadership"
      ],
      hashLink: "/GreenRoom#GreenTransformationID"
    },
    AccountableESG: {
      title: "Accountable ESG Metrics & KPIs",
      image: AccountableESGImage,
      paragraph: [
        "ESG (environmental, social, and governance) is a set of criteria used to evaluate companies’ commitment to sustainable operations. In recent years, new legislation and increased interest in ESG has raised the stakes in reporting transparency.",
        "We help our clients get ahead – and stay ahead – of their ESG metrics by condition anticipation and intelligent prediction. Let Terawe’s <b>Green Room</b> provide you with data-based insights and predictive analytics to help you meet your sustainability KPIs."
      ],
      points: [
        "Function tracking / reporting",
        "ESG Climate Disclosures (SEC)",
        "Operational Carbon Footprint",
        "Sustainability in social channels",
        "Long term goal mapping & execution"
      ],
      hashLink: "/GreenRoom#AccountableESGID"
    },
    EnergyEfficientComputing: {
      title: "Energy-Efficient Computing",
      image: EnergyEfficientComputingImage,
      paragraph: [
        "The cloud offers a cleaner, more sustainable model for global computing. But with all the choices and options, how do you specifically know what to use to advance your organization’s sustainability goals? Terawe has significant working experience with cutting-edge, energy efficient cloud infrastructure solutions that leverage the fastest accelerators on the planet to tackle the world’s most complex HPC+AI application workloads.",
        "Terawe’s <b>Green Room</b> can help you get the most performance and power out of your solutions while consuming minimal power and cost for back-end infrastructure."
      ],
      points: [
        "GPU/FPGA acceleration",
        "Small footprint workspaces",
        "Strategic SKU selection",
        "Parallelized computing options",
        "Containers on the Edge"
      ],
      hashLink: "/GreenRoom#EnergyEfficientComputingID"
    },
    EnvironmentalImpactBlueprints: {
      title: "Environmental Impact Blueprints",
      image: EnvironmentalImpactBlueprintsImage,
      paragraph: [
        "Today’s governing bodies require detailed EIS/EIR documentation that aligns to stringent environmental policies and regulations. However, many organizations lack the expertise and/or resources to produce such documentation in house.",
        "Terawe‘s <b>Green Room</b> offers a vast set of blueprint architectures for decarbonization, improved water &amp; waste management, and optimized power consumption that enable organizations to properly document and communicate their broader environmental impact."
      ],
      points: [
        "Architectural blueprints",
        "AI-powered insights",
        "Operational & organizational templates",
        "Pathways for reduced carbon footprint"
      ],
      hashLink: "/GreenRoom#EnvironmentalImpactBlueprintsID"
    },
    SustainabilitySimulations: {
      title: "Sustainability Simulations",
      image: SustainabilitySimulationsImage,
      paragraph: [
        "Simulations are our greatest tools to predict and accommodate changing climate conditions. But climate modeling and WRF are sophisticated & complex workflows with specific dependencies unfamiliar to many businesses.",
        "By leveraging powerful AI platforms along with highly scalable, accelerated cloud infrastructure, Terawe’s <b>Green Room</b> can generate the data needed for such reporting, and within a much faster timeframe than could be achieved otherwise."
      ],
      points: [
        "WRF Simulation",
        "Climate modeling",
        "AI-powered insights",
        "Comprehensive dashboards",
        "EIS/EIR report templates"
      ],
      hashLink: "/GreenRoom#SustainabilitySimulationsID"
    }
  }
};

export default StudiosContentObject;
