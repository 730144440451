import React from "react";
import bannerImage from "../../assets/img/Retail/Retail_MainLanding.jpg";
import IndustriesObject from "./IndustriesObject";
import InnerBanner from "../UiComponents/InnerBanner";
import {
  AccordionPanel,
  ImageContent,
  MainTitle,
  MetaHelmet,
  PartnersBlock,
  ResourcesBlock,
  SolutionsBlock,
  TextCarouselSlick,
  VerticalCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function Retail() {
  const root = IndustriesObject.Retail;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <section className="sectionContainer">
        <div className="container">
          <MainTitle
            title={root.Intelligent.title}
            paragraph={root.Intelligent.paragraph}
          />
        </div>
        <div className="container">
          <ImageContent
            image={root.Intelligent.image}
            points={root.Intelligent.points}
          />
        </div>
      </section>

      <TextCarouselSlick
        bgBlack={true}
        slideScroll={2}
        title={root.Transforming.title}
        points={root.Transforming.points}
      />

      {/* <IconBlockVertical
        title={root.Transforming.title}
        points={root.Transforming.points}
      /> */}

      <AccordionPanel
        title={root.Retailers.title}
        paragraph={root.Retailers.paragraph}
        section={root.Retailers.section}
        offer={root.Retailers.offer}
        points={root.Retailers.points}
      />

      <VerticalCarouselSlick
        title={root.Solution.title}
        paragraph={root.Solution.paragraph}
        points={root.Solution.points}
      />

      <PartnersBlock title={root.Partner.title} points={root.Partner.points} />

      <SolutionsBlock
        title={root.Terawe.title}
        points={root.Terawe.points}
        customClass={"spaceAround"}
      />

      <ResourcesBlock
        black={true}
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
