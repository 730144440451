import React from "react";
import StudiosContentObject from "./StudiosContentObject";
import InnerBanner from "../UiComponents/InnerBanner";
import bannerImage from "../../assets/img/studio/Studios-HPCStudio-MainHeader.jpg";
import ValueProposition from "../UiComponents/ValueProposition";
import { MetaHelmet } from "../UiComponents/ContentBlocks";

export default function HpcStudio() {
  const root = StudiosContentObject.HPCStudio;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
      />
      <ValueProposition
        image={root.performanceBenchmarking.image}
        isOntop={true}
        title={root.performanceBenchmarking.title}
        paragraph={root.performanceBenchmarking.paragraph}
        list={root.performanceBenchmarking.points}
      />
      <ValueProposition
        image={root.PurposeBuiltCloudInfrastructure.image}
        isOntop={true}
        mainContainerClass="bgDark"
        customConiainer="flip"
        customtextcontainer="flipped-text-container"
        title={root.PurposeBuiltCloudInfrastructure.title}
        paragraph={root.PurposeBuiltCloudInfrastructure.paragraph}
        list={root.PurposeBuiltCloudInfrastructure.points}
      />
      <ValueProposition
        image={root.hyperscaleParallelApplications.image}
        // isOntop={true}
        title={root.hyperscaleParallelApplications.title}
        paragraph={root.hyperscaleParallelApplications.paragraph}
        list={root.hyperscaleParallelApplications.points}
      />
      <ValueProposition
        image={root.WorkloadManagementTemplates.image}
        isOntop={true}
        mainContainerClass="bgDark"
        customConiainer="flip"
        customtextcontainer="flipped-text-container"
        title={root.WorkloadManagementTemplates.title}
        paragraph={root.WorkloadManagementTemplates.paragraph}
        list={root.WorkloadManagementTemplates.points}
      />
      <ValueProposition
        image={root.HPCModelingSimulation.image}
        isOntop={true}
        title={root.HPCModelingSimulation.title}
        paragraph={root.HPCModelingSimulation.paragraph}
        list={root.HPCModelingSimulation.points}
      />

      {/* <ValueProposition
        image={root.bigData.image}
        isOntop={true}
        title={root.bigData.title}
         mainContainerClass="bgDark"
        paragraph={root.bigData.paragraph}
        list={root.bigData.points}
      /> */}
    </React.Fragment>
  );
}
