// CloudComputing
import cloudMigration from "../../assets/img/cloudComputing/CloudSolutionAreas_CloudMigration.jpg";
import cloudModernization from "../../assets/img/cloudComputing/CloudSolutionAreas_AppModernization.jpg";
import cloudOptimization from "../../assets/img/cloudComputing/CloudSolutionAreas_CostOptimization.jpg";
import zeroTouch from "../../assets/img/cloudComputing/CloudSolutionAreas_ZeroTouchProvisioning.jpg";
import SecurityAndPrivacy from "../../assets/img/cloudComputing/KeyChallenges_SecurityAndPrivacy.jpg";
import CostManagement from "../../assets/img/cloudComputing/KeyChallenges_CostManagement.jpg";
import SystemIntegration from "../../assets/img/cloudComputing/KeyChallenges_SystemIntegration.jpg";
import PerformanceReliability from "../../assets/img/cloudComputing/KeyChallenges_PerformanceReliability.jpg";
import VendorLockIn from "../../assets/img/cloudComputing/KeyChallenges_VendorLockIn.jpg";
import SkillsGap from "../../assets/img/cloudComputing/KeyChallenges_SkillsGap.jpg";
import GovernanceAndControl from "../../assets/img/cloudComputing/KeyChallenges_GovernanceandControl.jpg";
import ChangeManagement from "../../assets/img/cloudComputing/KeyChallenges_ChangeManagement.jpg";
// EdgeComputing
import WhatIsEdgeComputing from "../../assets/img/edgeComputing/EdgeComputing_WhatIsEdgeComputing.jpg";
import WhatIsEdgeComputingMobile from "../../assets/img/edgeComputing/EdgeComputing_WhatIsEdgeComputing_Mobile.jpg";
import Manufacturing from "../../assets/img/edgeComputing/EdgeComputing_ManufacturingDiagram.png";
import Healthcare from "../../assets/img/edgeComputing/EdgeComputing_HealthcareDiagram.png";
import Retail from "../../assets/img/edgeComputing/EdgeComputing_RetailDiagram.png";
import SmartCities from "../../assets/img/edgeComputing/EdgeComputing_SmartCitiesDiagram.png";
import Transportation from "../../assets/img/edgeComputing/EdgeComputing_TransportationDiagram.png";
import Energy from "../../assets/img/edgeComputing/EdgeComputing_EnergyDiagram.png";
import Telcos from "../../assets/img/edgeComputing/EdgeCompuiting_TelcosDiagram.png";
import Agriculture from "../../assets/img/edgeComputing/EdgeComputing_AgricultureDiagram.png";
import Finance from "../../assets/img/edgeComputing/EdgeComputing_FinanceDiagram.png";
import Semiconductor from "../../assets/img/edgeComputing/EdgeComputing_SemiconductorDiagram.png";
// import EdgeAI from "../../assets/img/edgeComputing/EdgeComputing_EdgeAIDiagram.png";
// Big Data
import AllAboutTheData from "../../assets/img/bigData/BigData_AllAboutTheData.jpg";
import WhatDifferentiatesUs from "../../assets/img/bigData/BigData_WhatDifferentiatesUs.jpg";
import OrganizationalAgility from "../../assets/img/bigData/EdgeComputing_Differentiation_OrganizationalAgility.jpg";
import Intelligence from "../../assets/img/bigData/EdgeComputing_Differentiation_Intelligence.jpg";
import OneLens from "../../assets/img/bigData/EdgeComputing_Differentiation_OneLens.jpg";
//AIandML
import AIforBusiness from "../../assets/img/AI_ML/AIandML_AIforBusiness.jpg";
import MachineLearning from "../../assets/img/AI_ML/AIandML_Portfolio_MachineLearning.jpg";
import GenerativeAI from "../../assets/img/AI_ML/AIandML_SolutionAreas_GenerativeAI.jpg";
// import SpeechAudioProcessing from "../../assets/img/AI_ML/AIandML_Portfolio_SpeechAudioProcessing.jpg";
// import ComputerVision from "../../assets/img/AI_ML/AIandML_Portfolio_ComputerVision.jpg";
import AIandML_SolutionAreas_AdvancedCognitiveSolutions from "../../assets/img/AI_ML/AIandML_SolutionAreas_AdvancedCognitiveSolutions.jpg";
import DeepLearning from "../../assets/img/AI_ML/AIandML_Portfolio_DeepLearning.jpg";
import ReadyInfrastructure from "../../assets/img/AI_ML/AIandML_Portfolio_ReadyInfrastructure.jpg";
import NLP from "../../assets/img/AI_ML/AIandML_Portfolio_NLP.jpg";
import DevPlatforms from "../../assets/img/AI_ML/AIandML_Portfolio_DevPlatforms.jpg";
import AppServices from "../../assets/img/AI_ML/AIandML_Portfolio_AppServices.jpg";
// IoT
import WhatAreDigitalTwins from "../../assets/img/IoT/IoT_WhatAreDigitalTwins.jpg";
import CloudtoEdgeInsights from "../../assets/img/IoT/IoTandDigitalTwins_SolutionAreas_CloudtoEdgeInsights.jpg";
import DTaaS from "../../assets/img/IoT/IoTandDigitalTwins_SolutionAreas_DTaaS.jpg";
import IoTGateway from "../../assets/img/IoT/IoTandDigitalTwins_SolutionAreas_IoTGatewayAppliance.jpg";
// Productivity
import WhyProductivityMatters from "../../assets/img/productivity/Productivity_WhyProductivityMatters.jpg";
import RemoteCollaboration from "../../assets/img/productivity/Productivity_SolutionAreas_RemoteCollaboration.jpg";
import DeviceManagement from "../../assets/img/productivity/Productivity_SolutionAreas_DeviceManagement.jpg";
import AnalyticsAndInsights from "../../assets/img/productivity/Productivity_SolutionAreas_AnalyticsandInsights.jpg";
import ComplianceAuditing from "../../assets/img/productivity/Productivity_SolutionAreas_ComplianceAuditing.jpg";
import ProductivityPartner from "../../assets/img/productivity/Productivity_ProductivityPartner.jpg";
// HPC
import GlobalScaleAtYourService from "../../assets/img/HPC/HPC_GlobalScaleAtYourService.jpg";
import HPC_Manufacturing from "../../assets/img/HPC/HPC_Examples_Manufacturing.jpg";
import Academia from "../../assets/img/HPC/HPC_Examples_Academia.jpg";
import Genomics from "../../assets/img/HPC/HPC_Examples_Genomics.jpg";
import HPC_Energy from "../../assets/img/HPC/HPC_Examples_Energy.jpg";
import HPC_Finance from "../../assets/img/HPC/HPC_Examples_Finance.jpg";
import ManageXAcademy from "../../assets/img/logos/ManageXAcademy.svg";
import ManageXHPC from "../../assets/img/logos/ManageXHPC.svg";
import ManageXResearch from "../../assets/img/logos/ManageXResearch.svg";

// logos
import manageX from "../../assets/img/logos/ManageXLogo.png";
import manageXLogo from "../../assets/img/cloudComputing/ManageXLogo.png";
import GaliEye from "../../assets/img/cloudComputing/GaliEye.png";
import Foursite from "../../assets/img/cloudComputing/4site.png";
import {
  AcceleratedDevelopment,
  CarbonFootprint,
  IncreasedProductivity,
  LowerCost,
  MarketAgility,
  Resilience,
  SecurityPrivacyIcon,
  ChangeManagementIcon,
  CostManagementIcon,
  SystemIntegrationIcon,
  PerformanceReliabilityIcon,
  VendorLockInIcon,
  SkillsGapIcon,
  GovernanceControlIcon
} from "../UiComponents/Icons";
import { isMobile } from "react-device-detect";
/*
helmet: [
  {
    title: "abc",
    description: "abc",
    keywords: "abc"
  }
],
*/
const SpecialitiesContentObject = {
  CloudComputing: {
    helmet: [
      {
        title: "Terawe Cloud | IaaS, PaaS, and SaaS Development & Management",
        description:
          "Harness cloud computing with hybrid models to optimize your IT investment and ensure seamless application functionalities across your entire organization",
        keywords: "abc",
        schema: `
        {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "Cloud Computing",
          "image": "",
          "description": "Harness cloud computing with hybrid models to optimize your IT investment and ensure seamless application functionalities across your entire organization",
          "brand": "Terawe"
        }`
      }
    ],
    heroBanner: {
      title: "Cloud<br/>Computing",
      paragraph:
        "We enable organizations to engineer, deploy, and scale new digital solutions with greater speed and agility using the right cloud platform services for their purposes."
    },
    canDo: {
      title: "What the Cloud Can Do For You",
      points: [
        {
          title: "Lower Costs",
          icon: <LowerCost />,
          paragraph:
            "Moving workloads to the cloud can reduce TCO associated with startup, setup, and maintenance costs <span>between 30-40%.</span>",
          link: "https://www.cloudzero.com/blog/cloud-computing-statistics/"
        },
        {
          title: "Increased Productivity",
          icon: <IncreasedProductivity />,
          paragraph:
            "<span>Nearly 80% of IT professionals</span> stated their productivity improved as a result of shifting workloads to the cloud.",
          link: "https://www.atlassian.com/blog/platform/how-to-improve-productivity-in-the-cloud"
        },
        {
          title: "Operational Resiliency",
          icon: <Resilience />,
          paragraph:
            "With redundant data centers and global access capabilities, public clouds boast service <span>availabilities of 99% or higher.</span>",
          link: "https://www.techtarget.com/searchcloudcomputing/tip/Top-public-cloud-providers-A-brief-comparison"
        },
        {
          title: "Greater Market Agility",
          icon: <MarketAgility />,
          paragraph:
            "<span>58% of organization</span> believe that clouds improve their business agility, which enables them to react faster to business & market shifts.",
          link: "https://www.statista.com/statistics/1389583/industry-cloud-agility-us-organizations/"
        },
        {
          title: "Reduced Carbon Footprint",
          icon: <CarbonFootprint />,
          paragraph:
            "When compared to on-premise facilities, the cloud offers a <span>50% reduction</span> in overall carbon output.",
          link: "https://www.nrdc.org/sites/default/files/cloud-computing-efficiency-IB.pdf"
        },
        {
          title: "Accelerated Development",
          icon: <AcceleratedDevelopment />,
          paragraph:
            "Cloud-based DevOps services provide an impressive <span>81% acceleration rate</span> for software development initiatives.",
          link: "https://www.idctechnologies.com/blog/why-cloud-and-devops-are-better-together"
        }
      ]
    },
    keyChallenges: {
      title: "Key Challenges",
      paragraph:
        "Cloud adoption poses fundamental challenges to an organization’s current IT footprint: ",
      points: [
        {
          image: SecurityAndPrivacy,
          title: "Security & Privacy",
          paragraph:
            "Ensuring protection from breaches while maintaining compliance",
          icon: <SecurityPrivacyIcon />
        },
        {
          image: CostManagement,
          title: "Cost Management",
          paragraph:
            "Accommodating unexpected cost shifts while optimizing ROI",
          icon: <CostManagementIcon />
        },
        {
          image: SystemIntegration,
          title: "System Integration",
          paragraph:
            "Operational functionality with legacy systems and data migration tactics",
          icon: <SystemIntegrationIcon />
        },
        {
          image: PerformanceReliability,
          title: "Performance & Reliability",
          paragraph:
            "Maintaining business continuity and service connectivity without disruption ",
          icon: <PerformanceReliabilityIcon />
        },
        {
          image: VendorLockIn,
          title: "Vendor Lock-In",
          paragraph:
            "Exiting long-term contractual commitments and becoming more portable ",
          icon: <VendorLockInIcon />
        },
        {
          image: SkillsGap,
          title: "Skills Gap",
          paragraph:
            "Familiarity of cloud-based systems and SOA applications are essential",
          icon: <SkillsGapIcon />
        },
        {
          image: GovernanceAndControl,
          title: "Governance & Control",
          paragraph:
            "Enforcing corporate governance policies while maintaining controlled access ",
          icon: <GovernanceControlIcon />
        },
        {
          image: ChangeManagement,
          title: "Change Management",
          paragraph:
            "Creating effective change management strategies with organizational buy-in",
          icon: <ChangeManagementIcon />
        }
      ]
    },
    doCloud: {
      title: "Terawe Cloud Principles",
      points: [
        {
          title: "Unity Across the Cloud-to-Edge Continuum",
          paragraph:
            "Our unified approach to cloud enables seamless connectivity and consistent performance for your applications and data, enhancing accessibility, flexibility, and scalability regardless of their deployment location."
        },
        {
          title: "App Modernization & Innovation",
          paragraph:
            "We transform legacy applications into ‘future ready’ cloud-native solutions by infusing AI capabilities to enhance performance, increase reliability, and establishing a model of continuous innovation. "
        },
        {
          title: "Data and AI",
          paragraph:
            "Our advanced intelligence platform with real-time inferencing solution accelerators leverage ML and AI to deliver actionable insights and analytics, empowering your organization with more informed decision-making."
        },
        {
          title: "Security",
          paragraph:
            "Our security commitment to your apps and data includes a multi-layered security measure of encryption, identity management, and continuous threat detection that ensures your assets are protected against evolving cyber threats."
        },
        {
          title: "Governance and Control",
          paragraph:
            "We ensure adherence to regulatory requirements and internal policies with our governance & compliance tools, empowering your organization to maintain control over your environments for peace of mind and operational integrity."
        },
        {
          title: "Operational Visibility",
          paragraph:
            "Our platform offers advanced monitoring and analytics tools that deliver real-time insights into system performance and user activities, ensuring proactive issue detection and swift resolution for greater overall operational efficiency."
        },
        {
          title: "Cost Management",
          paragraph:
            "Terawe offers flexible pricing models and comprehensive cost optimization tools that include detailed budgeting features to help you manage expenses effectively, preventing overspending and ensuring financial predictability."
        },
        {
          title: "Performance & Reliability",
          paragraph:
            "Our ‘white-glove’ model for managed services ensures high availability and robust performance across your entire digital estate, enabling you to seamlessly scale your assets to meet peak usage, ensure consistent operational performance, and provide memorable user experiences."
        }
      ]
    },
    solutions: {
      title: "Our Cloud Solution Areas",
      points: [
        {
          image: cloudMigration,
          title: "Cloud Migration Services",
          paragraph:
            "We facilitate the transitioning of both critical and non-critical applications, data sets, and management services to cloud platforms without disruption to your business or compromising your existing security and/or compliance protocols.",
          keywords: [
            "Assessment and Planning",
            "Data Migration",
            "Training & Change Management",
            "Testing & Validation",
            "Deployment",
            "Optimization",
            "Application Migration"
          ]
        },
        {
          image: cloudModernization,
          title: "Application Modernization",
          paragraph:
            "Update and enhance your legacy software applications to align them with current technology standards, utilizing cloud-native design models and microservice  APIs to improve functionality, boost scalability, and increase resilience.",
          keywords: [
            "Multi-tenancy",
            "Remote Accessibility",
            "Seamless Versioning",
            "Lift’n’Shift",
            "User-Centric Design",
            "Replatforming",
            "High Performance",
            "Interoperability"
          ]
        },
        {
          image: cloudOptimization,
          title: "Cost Optimization",
          paragraph:
            "Conduct thorough cost analyses of your entire cloud estate and review pre-curated options provided by our AI-powered cost optimizer to determine the most appropriate IT spending decisions for your organization.",
          keywords: [
            "Right-Sized Resources",
            "Shutdown Policies",
            "Discounted Instances",
            "Preemptible VMs",
            "Auto-Scaling & Elasticity",
            "Savings Plans",
            "Comprehensive Monitoring & Alerts"
          ]
        },
        {
          image: zeroTouch,
          title: "Zero-Touch Provisioning (ZTP)",
          paragraph:
            "Leverage our extensive library of pre-built cloud environment templates and workspace blueprints to automate & schedule provisioning of resources seamlessly and effectively – even across multiple cloud platforms.",
          keywords: [
            "Accelerated Onboarding",
            "Scalability",
            "Enhanced Security & Compliance",
            "Remote Deployments",
            "Cloud Integration",
            "Centralized Controls",
            "Reduced Troubleshooting"
          ]
        }
      ]
    },
    cloudSolutions: {
      title: "Our Cloud Offerings",
      points: [
        {
          image: manageXLogo,
          paragraph:
            "A simplified and intuitive web portal that connects busy professionals with global-scale cloud resources instantly",
          link: "/ManageX",
          customClass: "manageX"
        },
        {
          image: GaliEye,
          paragraph:
            "Empowering organizations to turn their data, knowledge, and experience into actionable, intelligent insights",
          link: "/GaliEye",
          customClass: "galiEye"
        },
        {
          image: Foursite,
          paragraph:
            "An ultimate catalog of AI models for unlocking the full potential of AI to revolutionize enterprise-level decision-making. ",
          link: "/4Site",
          customClass: "fourSite"
        }
      ]
    },
    // value: {
    //   title: "Our Unique Value",
    //   paragraph:
    //     "Why Terawe needs to be your go-to solution partner for all things cloud: ",
    //   points: [
    //     {
    //       title: "Cloud Agnostic",
    //       paragraph:
    //         "Our expertise and working knowledge of all global public cloud platforms enables our clients to avoid cloud vendor lock-in, taking advantage of the very best services at the most competitive prices."
    //     },
    //     {
    //       title: "Solution Accelerators",
    //       paragraph:
    //         "We offer and entire library of curated, pre-built solution components that accelerate your development process, from battle-tested industry ML models to computing workspace provisioning blueprints."
    //     },
    //     {
    //       title: "Our “Whole Stack” Approach",
    //       paragraph:
    //         "We rationalize every solution dependency across the Iaas, PaaS, ansd SaaS stack layers to ensure capabilities and resources are maximized while costs are minimized."
    //     },
    //     {
    //       title: "One Lens for All Your Data",
    //       paragraph:
    //         "Regardless of where or how you store your data, we establish a single point of visibility and access to all your data, accessible any time of day, anywhere it is required."
    //     },
    //     {
    //       title: "AI-Powered Insights",
    //       paragraph:
    //         "We offer our clients unique, AI-powered dashboards that aggregate and correlate your cloud usage analytics into insights that help you make better decisions for your organization."
    //     }
    //   ]
    // },
    resources: {
      title: "More Resources ",
      resourceTitle: [
        "Cloud Computing – Characteristics and Benefits?",
        "PCaas",
        "Innovation from Personal to Exascale Computing"
      ]
    }
  },
  EdgeComputing: {
    helmet: [
      {
        title: "Terawe Corp | Edge Computing Consulting & Development",
        description:
          "Come learn about what Terawe can do for your edge & IoT computing needs, including remote visualization, containerized AI, and real-time monitoring.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Edge Computing",
            "image": "",
            "description": "Come learn about what Terawe can do for your edge & IoT computing needs, including remote visualization, containerized AI, and real-time monitoring.",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Edge<br/>Computing",
      paragraph:
        "Unite and integrate multiple computing systems & devices located anywhere across your physical environments with your data & applications to produce deep insights in a timely manner. "
    },
    aboutComputing: {
      title: "What is <br/>Edge Computing? ",
      paragraph: [
        "Much of the critical data collected by modern applications is performed through devices and sensors that are physically located away from where data is typically processed. This presents a  challenge to organizations that seek to deliver responsive, zero-latency application experiences at the speed and accuracy necessary to support user expectations. ",
        "<span>Edge computing</span> brings the processing capabilities out to where the data is being generated, thereby eliminating latencies and increasing the responsiveness of associated apps and services.  In concert with cloud-based machine learning models, organizations can leverage edge computing to improve their processes and offerings over time while consistently maintaining usability and customer satisfaction."
      ],
      image: isMobile ? WhatIsEdgeComputingMobile : WhatIsEdgeComputing,
      imageDetails: "An edge weather monitoring device in a cornfield"
    },
    industries: {
      title: "Edge Computing in Industries",
      points: [
        {
          title: "Manufacturing",
          image: Manufacturing,
          paragraph:
            "Edge computing can optimize production floor processes by providing real-time data analytics for equipment monitoring, predictive maintenance, quality control, and supply chain management. ",
          link: "/Manufacturing",
          linkText: "Learn more about Terawe’s Manufacturing  Solutions"
        },
        {
          title: "Healthcare",
          image: Healthcare,
          paragraph:
            "In healthcare, edge computing can support remote patient monitoring, wearable devices, and real-time data analysis for diagnosis and treatment. It can also facilitate the operation of medical devices and equipment in hospitals and clinics. ",
          link: "/Healthcare",
          linkText: "Learn more about Terawe’s Healthcare Solutions"
        },
        {
          title: "Retail",
          image: Retail,
          paragraph:
            "Retailers can utilize edge computing for inventory management, personalized marketing, in-store customer analytics, and cashierless checkout systems. It enables faster processing of transactions and enhances the overall shopping experience. ",
          link: "/Retail",
          linkText: "Learn more about Terawe’s Retail Solutions"
        },
        {
          title: "Public Sector",
          image: SmartCities,
          paragraph:
            "Edge computing enables various public sector applications such as traffic management, public safety, environmental monitoring, and utility management. It allows for the integration of IoT devices and sensors to improve city services and infrastructure. ",
          link: "/PublicSector",
          linkText: "Learn more about Terawe’s Government Solutions"
        },
        {
          title: "Transportation",
          image: Transportation,
          paragraph:
            "Edge computing enhances fleet management systems, optimizing routes, monitoring vehicle performance, and ensuring timely delivery. It also supports autonomous vehicles by processing data from sensors and cameras in real-time. ",
          link: "/Automotive",
          linkText: "Learn more about Terawe’s Automotive Solutions "
        },
        {
          title: "Energy",
          image: Energy,
          paragraph:
            "In the energy sector, edge computing can optimize power generation and distribution, monitor equipment health, and enable predictive maintenance for renewable energy sources such as wind and solar farms. ",
          link: "/Energy",
          linkText: "Learn more about Terawe’s Energy Solutions "
        },
        {
          title: "Telecommunication",
          image: Telcos,
          paragraph:
            "Edge computing helps telecom operators deliver low-latency services such as video streaming, online gaming, and virtual reality applications. It also supports the deployment of 5G networks by distributing computing resources closer to users. ",
          link: "/Telecommunications",
          linkText: "Learn more about Terawe’s Communications Solutions "
        },
        {
          title: "Agriculture",
          image: Agriculture,
          paragraph:
            "Edge computing assists farmers in monitoring crops, managing irrigation systems, and optimizing resource usage based on real-time data collected from sensors and drones. It enables precision agriculture techniques to improve crop yields and reduce environmental impact. ",
          // link: "/EdgeComputings",
          linkText: "Learn more about Terawe’s Sustainability Solutions "
        },
        {
          title: "Finance",
          image: Finance,
          paragraph:
            "In the finance industry, edge computing enhances security systems, fraud detection, and high-frequency trading by processing transactions and analyzing data closer to the source. It also supports personalized banking services and customer interactions. ",
          link: "/Finance",
          linkText: "Learn more about Terawe’s Finance Solutions "
        },
        {
          title: "Semiconductor",
          image: Semiconductor,
          paragraph:
            "Edge computing dramatically augments the semiconductor sector by enabling real-time data processing and analysis at the source, thereby enhancing production efficiency, reducing latency, and improving the accuracy of design and quality control processes.",
          // link: "/AIandML",
          linkText: "Learn more about Terawe’s AI & ML Solutions "
        }
        // {
        //   title: "Edge AI",
        //   image: EdgeAI,
        //   paragraph:
        //     "Edge computing combined with artificial intelligence enables intelligent devices and applications such as smart cameras, voice assistants, and autonomous robots. These devices can perform real-time data analysis and make decisions locally without relying on cloud services. "
        // }
      ]
    },
    solutions: {
      title: "Our Edge Computing Solution Areas",
      // paragraph:
      //   "Our HPC+AI capabilities handle highly complex simulations to enhance performance of digital twins and transform how data is processed and utilized in real-time environments, ensuring higher precision, faster decision-making, and greater scalability.",
      points: [
        {
          title: "Fabric of Intelligence ",
          paragraph:
            "We execute parallel machine learning with containers on the Edge and in the Cloud to optimize insight extraction for both immediate and long term organizational value. "
        },
        {
          title: "Digital Twins",
          paragraph:
            "Our edge accelerators enable the creation of digital twins that process critical data locally through edge inferencing to provide real-time insights and continual monitoring, reducing the risk of complications and disruptions."
        },
        {
          title: "IoT Gateway Appliance ",
          paragraph:
            "We offer an “out of the box” IoT Gateway Appliance that interconnects devices, aggregates your data, and inferences AI models at the Edge to minimize latencies. "
        },
        {
          title: "Unified Data Estate ",
          paragraph:
            "We blur lines between data storage types and locations, eliminating data siloes and offering real-time contextual insights across your entire data estate. "
        },
        {
          title: "Advanced Simulation at the Edge",
          paragraph:
            "Our HPC+AI capabilities handle highly complex simulations to enhance performance of digital twins and transform how data is processed and utilized in real-time environments, ensuring higher precision, faster decision-making, and greater scalability."
        }
      ]
    },
    value: {
      title: "Our Differentiated Value for the Edge",
      paragraph:
        "Why Terawe is a logical choice as an Edge technology partner: ",
      points: [
        {
          image: OrganizationalAgility,
          title: "Organizational Agility ",
          paragraph:
            "Our Gateway Appliance enables you to integrate new IoT devices at the edge with ease, aggregating data and processes while maintaining workflow integrity as you scale. "
        },
        {
          image: Intelligence,
          title: "Intelligence at the Edge ",
          paragraph:
            "Our Edge Solutions help extend cloud out to the edge, enabling machine learning and containerized AI at the edge to deliver the most comprehensive insights and intel possible. "
        },
        {
          image: OneLens,
          title: "One Lens for All Your Data ",
          paragraph:
            "The Edge is an opportunity to centralize your data assets.  Our Unified Data Estate blurs the lines between file types and locations, giving you a single point of search, access, and control for all your data. "
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "Predictive Maintenance",
        "Virtual Mirror",
        "An Alternate Approach to Anomaly Detection"
      ]
    }
  },
  BigData: {
    helmet: [
      {
        title: "Terawe Corp | Big Data Analytics & Cloud Governance",
        description:
          "Guidance and consultation on extracting contextual insights across your big data estate for predictive analytics, data governance, and customer data solutions ",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Big Data & Analytics",
            "image": "",
            "description": "Guidance and consultation on extracting contextual insights across your big data estate for predictive analytics, data governance, and customer data solutions",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Big Data<br/> & Analytics ",
      paragraph:
        "We conceptualize, engineer, and support modernized data platforms out of your entire data estate, using powerful AI-driven analytics tools to extract and deliver game-changing insights."
    },
    about: {
      image: AllAboutTheData,
      title: "It’s All About Data ",
      paragraph: [
        "We specialize in helping organizations at any scale to bring together vast amounts of unstructured, siloed data sets into a unified, intelligent knowledge base that empowers such organizations with the insight and analytics necessary to make well-informed, educated decisions."
      ],
      points: [
        "Uncover trends, patterns and correlations across your raw data estate to help make data-informed decisions ",
        "Innovate anywhere with our unified data estate and unlock insights with ease ",
        "Eliminate barriers of search and discoverability between different databases, file types, and storage locations "
      ]
    },
    globalCommodity: {
      title: "Data is the New Global Commodity ",
      paragraph:
        "Organizations investing in understanding their own data better are reaping the rewards ",
      points: [
        {
          title: "$745B",
          paragraph:
            "Projected value of the global big data analytics market size by 2030 ",
          link: "https://www.globenewswire.com/en/news-release/2023/04/28/2657130/0/en/Big-Data-Analytics-Market-Size-to-Surpass-USD-745-15-billion-by-2030-at-a-CAGR-of-13-5.html"
        },
        {
          title: "91.9%",
          paragraph:
            "…of organizations achieved immeasurable value from investing in data analytics ",
          link: "https://explodingtopics.com/blog/data-analytics-stats"
        },
        {
          title: "82.6% ",
          paragraph:
            "…of organizations have appointed a Chief Data Officer driving data strategy ",
          link: "https://www.wavestone.com/en/about-us/offices/north-america/"
        },
        {
          title: "1.4M ",
          paragraph:
            "New jobs to be created in data science & analytics from 2023 to 2027 ",
          link: "https://www3.weforum.org/docs/WEF_Future_of_Jobs_2023.pdf"
        }
      ]
    },
    solution: {
      title: "Our Big Data Solution Areas ",
      points: [
        {
          icon: "azh",
          title: "Unified Data Estate ",
          paragraph:
            "Harness data insights from the cloud, to your premises, out to the edge with a cloud-agnostic unified data estate "
        },
        {
          icon: "azh",
          title: "Contextual Data Insights ",
          paragraph:
            "Use our AI-powered dashboards to deliver rich & contextual insights, integrating your data sources to optimize efficiencies "
        },
        {
          icon: "azh",
          title: "Next Gen Data Modernization",
          paragraph:
            "Transform your data landscape with our cutting-edge data modernization workflows, enabling seamless integration and enhanced data management."
        },
        {
          icon: "azh",
          title: "Data Resiliency ",
          paragraph:
            "Built-in DR enabled service to ensure data resiliency including modern data governance & audit tracking "
        }
      ]
    },
    differentiates: {
      title: "What differentiates us? ",
      paragraph:
        "Why Terawe needs to be your go-to solution partner for all things data: ",
      image: WhatDifferentiatesUs,
      points: [
        "We <span>eliminate access and discovery barriers to your data</span>, regardless of data type and/or storage location, delivering a single, unified data estate.",
        'We <span>extract and contextualize insights</span> that transcend Edge-On Premise-Cloud environment lines, enabling you with a "bigger picture" from a single point of view. ',
        "We <span>customize our service reporting structures and endpoints</span> to align with your existing service model(s) that optimize both the usefulness and relevance of insights. ",
        "We engineer solutions in <span>accordance with strict governance, security, and compliance requirements</span> supported by industry-leading SLAs. "
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: ["Gaming Data Analytics", "GaliEye", "Fraud Detection"]
    }
  },
  AIandML: {
    helmet: [
      {
        title: "Terawe AI | Machine Learning for Business Apps & Solutions",
        description:
          "Unlock your potential with our advanced AI and machine learning solutions in process automation, document intelligence, conversational AI, and Generative AI",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "AI & ML",
            "image": "",
            "description": "Unlock your potential with our advanced AI and machine learning solutions in process automation, document intelligence, conversational AI, and Generative AI",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "AI & ML",
      paragraph:
        "We believe that building responsible AI solutions not only empowers more effective, immersive products & services, but also promotes fairness, inclusion, and transparency for the greater overall good."
    },
    business: {
      title: "AI for Business ",
      image: AIforBusiness,
      paragraph: [
        "Whether you want to enhance your customer experiences, improve your productivity, optimize your processes, or accelerate your innovation, we offer the most comprehensive set of AI & ML solutions to meet the most demanding business needs. "
      ],
      points: [
        "More immersive customer experiences for both online and in-person retailers ",
        "Greater learning impact and student retention for schools & universities ",
        "More sustainable practices for energy and manufacturing sectors ",
        "Enhanced public sector services that increase citizen safety and security ",
        "Accelerated time-to-insight for more agile, insightful decision making. "
      ]
    },
    impact: {
      title: "AI’s Business Impact ",
      paragraph:
        "AI presents a compelling opportunity picture for businesses looking to the future ",
      points: [
        {
          secTitle: "Market Size ",
          title: "$1,180B",
          paragraph:
            "AI’s projected global market size by 2030, growing at a CAGR of 37.3% from 2023-2030. ",
          link: "https://www.grandviewresearch.com/press-release/global-artificial-intelligence-ai-market"
        },
        {
          secTitle: "Economic Impact ",
          title: "21%",
          paragraph:
            "AI’s projected net increase to the United States GDP by 2030 as businesses integrate AI solutions. ",
          link: "https://www.grandviewresearch.com/press-release/global-artificial-intelligence-ai-market"
        },
        {
          secTitle: "Productivity",
          title: "38%",
          paragraph:
            "Projected increase to labor productivity by 2035 resulting from leveraging AI across business operations ",
          link: "https://newsroom.accenture.com/news/2017/accenture-report-artificial-intelligence-has-potential-to-increase-corporate-profitability-in-16-industries-by-an-average-of-38-percent-by-2035"
        },
        {
          secTitle: "Cost Savings ",
          title: "$5.8T",
          paragraph:
            "Maximum projected annual cost savings across sectors by using AI for efficiency gains and process optimization ",
          link: "https://www.mckinsey.com/featured-insights/artificial-intelligence/notes-from-the-ai-frontier-applications-and-value-of-deep-learning"
        }
      ]
    },
    Solution: {
      title: "Our AI & ML Solution Areas",
      paragraph:
        "We offer a diverse landscape of AI-related services to fulfill your specific needs and aspirations. ",
      points: [
        {
          title: "Machine Learning ",
          image: MachineLearning,
          paragraph:
            "Our Machine Learning solutions transform raw data into actionable insights. Using predictive analytics, anomaly detection, and automated decision-making, we optimize operations, enhance customer experiences, and drive innovation. Our services span the ML lifecycle, including data preprocessing, feature engineering, model training, deployment, and continuous improvement. We ensure effective, ethical, sustainable ML initiatives with robust monitoring and maintenance to adapt to evolving data and business needs."
        },
        {
          title: "Generative AI",
          image: GenerativeAI,
          paragraph:
            "Innovate with our Generative AI solutions. Automate creative processes from content creation and design to synthetic data generation and simulations. Enhance your applications with AI-driven design tools, realistic virtual environments, and improved product development cycles. Our expertise ensures your generative AI projects deliver tangible results."
        },
        {
          title: "Deep Learning ",
          image: DeepLearning,
          paragraph:
            "Harness Deep Learning to solve complex problems and unlock opportunities. Our solutions process vast data with high accuracy for image and speech recognition, natural language understanding, and autonomous systems. We offer services from neural network design to deployment, keeping your organization at the forefront of technological advancements."
        },
        {
          title: "Natural Language Processing ",
          image: NLP,
          paragraph:
            "Our NLP services enhance communication and data comprehension. Enable sentiment analysis, chatbots, automated translation, and text summarization. Improve customer support, gain insights from textual data, and automate routine tasks. Transform your data into a valuable knowledge base for smarter decision-making."
        },
        // {
        //   title: "Computer Vision ",
        //   image: ComputerVision,
        //   paragraph:
        //     "Computer vision involves the development of algorithms and techniques to process, analyze, and make sense of images, documents, and videos. It has a wide range of applications, including medical imaging & disease diagnosis in healthcare, self-driving and driver assisted vehicles, object detection & inventory management in retail, video surveillance & analytics in law enforcement, and powering alternate realities for entertainment. "
        // },
        {
          title: "Advanced Cognitive Solutions",
          image: AIandML_SolutionAreas_AdvancedCognitiveSolutions,
          paragraph:
            "Enhance your business with our Advanced Cognitive Solutions, integrating Speech, Audio, and Computer Vision technologies. Convert speech to text, detect emotions, and analyze video in real-time. Streamline operations and gain insights with our intelligent solutions. Document intelligence organizes data, driving efficiency and informed decisions. Stay ahead in the digital age with our solutions."
        },
        {
          title: "AI-Ready Infrastructure ",
          image: ReadyInfrastructure,
          paragraph:
            "Leverage our custom-built infrastructure with the latest CPUs, GPUs, and FPGAs. Designed for complex AI workloads, it supports mixed-precision training and inferencing. Seamlessly integrate with AI frameworks like TensorFlow, PyTorch, and ONNX. Optimize for on-premises and cloud environments, accelerating time to market and saving compute costs."
        },
        {
          title: "AI Development Platforms ",
          image: DevPlatforms,
          paragraph:
            "Obtain comprehensive software environments that streamline AI model development, training, and deployment. They provide robust tools, support various AI frameworks, and offer integrated data preprocessing and model optimization. Modernize your apps with AI, transforming raw data into actionable insights. Accelerate time to market and reduce training time, saving on compute costs."
        },
        {
          title: "AI Application Services ",
          image: AppServices,
          paragraph:
            "Utilize our pre-built framework and API ‘building blocks’ to integrate AI capabilities easily. Access pre-trained models for image and speech recognition, NLP, and predictive analytics. Enhance applications with intelligent features, streamline operations, and create innovative solutions. Accelerate time to market and reduce development costs with our scalable AI services."
        }
      ]
    },
    whyUs: {
      title: "Why Work With Us for AI? ",
      points: [
        {
          title: "Pre-built ML Models ",
          paragraph:
            "Reduce long engineering and training times with Terawe’s pre-built models that accelerate the delivery of intelligent insights. "
        },
        {
          title: "Customization",
          paragraph:
            "Terawe solutions are highly customized to align with specific data management, compliance, and security requirements of each and every client. "
        },
        {
          title: "Responsible AI ",
          paragraph:
            "Terawe designs, develops, and deploys AI with good intentions to empower our clients’ employees and businesses with trust and confidence for long-term success. "
        },
        {
          title: "Outcomes, Not Services ",
          paragraph:
            "Terawe deeply understands and focuses on the specific outcomes our clients expect, thereby engineering solutions that are guaranteed to deliver those outcomes. "
        },
        {
          title: "Unique, Applied Experience ",
          paragraph:
            "From our collective backgrounds working at global tech providers to the strategic partnerships we’ve formed, Terawe is your end-to-end global ambassador for technology strategy and execution. "
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "Fraud Detection",
        "Gaming Data Analytics",
        "Predictive Maintenance"
      ]
    }
  },
  IoT: {
    helmet: [
      {
        title: "Terawe IoT | Digital Twin & Edge Computing Solutions",
        description:
          "Edge computing unlocks a world of potential for gathering intelligence in real time, non-destructive engineering with digital twins, and supporting IoT devices.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "IoT and Digital Twins",
            "image": "",
            "description": "Edge computing unlocks a world of potential for gathering intelligence in real time, non-destructive engineering with digital twins, and supporting IoT devices.",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "IoT and<br/> Digital Twins",
      paragraph:
        "Bridging digital and physical worlds to drive efficiency, innovation, and agility across various industries, enabling organizations to thrive in an increasingly competitive landscape. "
    },
    valueProps: {
      image: WhatAreDigitalTwins,
      imageDetails: "Digital twin of new jet engine design",
      paragraph: [
        "<span>Digital twins</span> are virtual representations of physical objects or systems that enable organizations to analyze, monitor, and simulate their behaviors in advance of committing to a specific development pathway or choosing a specific design model.  In this way, digital twins help organizations identify and sidestep potential design & development issues before they impact existing budgets and schedules.",
        "The <span>Internet of Things (IoT)</span> is critical here because it enables digital twins to reflect the current state of the physical object or system, allowing for more accurate analysis, prediction, and decision-making. Without IoT, digital twins would lack the real-time data necessary to accurately represent their physical counterparts.  Digital twins are particularly valuable in industries such as manufacturing, healthcare, transportation, and energy, where real-time insights into equipment performance, operational efficiency, and predictive maintenance can lead to significant cost savings and improvements in productivity.          "
      ]
    },
    DigitalTwins: {
      title: "Why Are Digital Twins Important? ",
      points: [
        {
          title: "Real-time Monitoring ",
          paragraph:
            "Allows stakeholders to monitor and visualize the physical world remotely and in real-time, providing insights around interoperability, meeting performance criteria, and integrating with other systems. "
        },
        {
          title: "Optimized Operations ",
          paragraph:
            "Digital twins enable 'what-if' scenarios, allowing organizations to test different strategies and optimize operations before implementing changes in the physical world. This can lead to improved efficiency, resource utilization, and overall performance. "
        },
        {
          title: "Predictive Maintenance ",
          paragraph:
            "By monitoring assets through IoT sensors and analyzing the data with digital twins, organizations can be more proactive in maintenance scheduling, minimizing downtime, and optimizing asset lifespan. "
        },
        {
          title: "Improved Decision Making ",
          paragraph:
            "By analyzing historical and real-time data, stakeholders can identify trends, patterns, and anomalies, enabling better-informed decisions across various domains, from supply chain management to urban planning. "
        },
        {
          title: "Safety & Security ",
          paragraph:
            "IoT sensors and digital twins can help identify potential safety hazards and security threats in real-time, enabling organizations to detect and respond to risks more effectively, and enhance workplace safety while protecting their  assets and infrastructure at the same time. "
        },
        {
          title: "Enhanced Product Development ",
          paragraph:
            "Engineers can simulate performance, conduct virtual tests, and identify potential issues early in the development process, thereby accelerating  time-to-market while improving product quality. "
        },
        {
          title: "Personalized Experiences ",
          paragraph:
            "IoT data and digital twins enable organizations to to personalize products, services, and experiences for customers, leading to increased customer satisfaction and loyalty. "
        }
      ]
    },
    Terawe: {
      title: "Why Partner With Us For Digital Twins",
      paragraph:
        "Terawe is uniquely positioned to be your go-to-partner for IoT & Digital Twins. ",
      points: [
        {
          title: "Tried & Tested ",
          paragraph:
            "With our vast library of pre-built ML models combined with our Galieye Insight Platform, we already have functional, tested components in place and ready to help accelerate the pace of your digital twin strategy. "
        },
        {
          title: "Accelerated Time-to-Insight ",
          paragraph:
            "We offer an “out of the box” IoT Gateway Appliance that interconnects devices, aggregates your data, and inferences the models trained by your digital twins to minimize latencies and maintain performance KPIs. "
        },
        {
          title: "One Lens for your Data ",
          paragraph:
            "We ensure your digital twins benefit from all your relevant data by interconnecting environments and generating real-time contextual insights representing the breadth of your entire organization. "
        },
        {
          title: "The Trees AND the Forest ",
          paragraph:
            "While we are extremely specific and detail oriented in our engagements, we always gauge success based on achieving the right outcome for the customer.  At the end of the day, that’s what matters the most. "
        }
      ]
    },
    solutions: {
      title: "Our Digital Twin Solution Areas ",
      paragraph:
        "Services adaptive to any environment with versatile implementation options ",
      points: [
        {
          image: DTaaS,
          title: "Digital Twin-as-a-Service (DTaaS) ",
          paragraph:
            "Using containers to operate & manage machine learning models for IoT devices, customers can employ a DTaaS model that enables digital twins with AI in real time…but with the agility of an SOA. "
        },
        {
          image: IoTGateway,
          title: "IoT Gateway Appliance ",
          paragraph:
            "We offer an “out of the box” IoT Gateway Appliance that can be deployed on demand to interconnect devices, aggregate your data, and inference AI models at the Edge to minimize latencies. "
        },
        {
          image: CloudtoEdgeInsights,
          title: "Cloud-to-Edge Insights ",
          paragraph:
            "Take advantage of powerful, AI-driven dashboards that aggregate real-time data to anoury device you might have, anywhere you might be, so you are always connected to the health of your operation. "
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "Predictive Maintenance",
        "Reactive to Proactive bed management with Data & AI",
        "GaliEye"
      ]
    }
  },
  Productivity: {
    helmet: [
      {
        title: "Terawe Productivity | Business Collaboration Tools ",
        description:
          "Let us help you identify and implement the tools & services to increase your business productivities, optimize management workflows, and improve communications.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Productivity",
            "image": "",
            "description": "Let us help you identify and implement the tools & services to increase your business productivities, optimize management workflows, and improve communications.",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Productivity",
      paragraph:
        "Our unique solutions, combining the power of Microsoft Teams and Office365, empower your employees with modern communication and collaboration tools, augmented by rich analytics to boost productivities. "
    },
    valueProps: {
      image: WhyProductivityMatters,
      title: "Why Productivity Matters ",
      paragraph: [
        "Businesses constantly look for ways to increase their productivity so they may stay competitive, reduce cost, meet market demands, and adapt to evolving work trends like work-from-home and remote collaboration. This challenges organizations with maintaining secure access, data privacy, and regulatory compliance all while providing powerful workstation-class application experiences on laptops, devices, and tablets at the Edge. ",
        "<span>Terawe’s Productivity Solutions</span> utilize powerful AI services integrated with global cloud platforms that enable employees to work & collaborate from anywhere, on any computer or device, in a secure environment, with built-in controls for threat protection and compliance. "
      ]
    },
    AIBuilds: {
      title: "AI Builds Productivity",
      paragraph:
        "Examples of how we leverage AI to bolster your organizational productivities ",
      points: [
        {
          title: "Process Automation ",
          paragraph:
            "Off-loading repetitive and time-consuming tasks such as data entry, document processing, transcription, and language translation to allow employees to focus on more strategic and creative work. "
        },
        {
          title: "Data Mining ",
          paragraph:
            "Establishing a unified data estate that encompasses all your structured and unstructured data - of any type and stored in any location – and gives you a single point of search, access, and continuity. "
        },
        {
          title: "Document Intelligence ",
          paragraph:
            "Accelerating & streamlining standardized documentation processes associated with applications, contracts and forms that improve work efficiencies while extracting valuable insights. "
        },
        {
          title: "Remote Visualization ",
          paragraph:
            "Taking advantage of the most powerful GPU processors running in remote regions of global cloud providers that deliver workstation-class experiences and responsiveness to remote staff. "
        }
      ]
    },
    solutions: {
      title: "Our Productivity Solution Areas ",
      paragraph:
        "How we leverage AI to bolster your organizational productivities ",
      points: [
        {
          image: RemoteCollaboration,
          title: "Remote Collaboration ",
          paragraph:
            "A customized plug-in for Microsoft Teams that automates common workflows and boosts your teams’ productivities - wherever they may be located. "
        },
        {
          image: DeviceManagement,
          title: "Device Management ",
          paragraph:
            "Deploy, scale, and manage all your mobile devices with our custom MDM suite that aligns the capabilities of Microsoft365 and Intune with your existing corporate policies. "
        },
        {
          image: AnalyticsAndInsights,
          title: "Analytics & Insights ",
          paragraph:
            "Use our immersive, AI-powered dashboards to deliver targeted productivity insights from tight integration with Office365 analytics & Microsoft Teams. "
        },
        {
          image: ComplianceAuditing,
          title: "Compliance Auditing ",
          paragraph:
            "Combine the power of eDiscovery insights & data loss prevention policies to enable governance & audit capabilities. "
        }
      ]
    },
    Partner: {
      title: "We Are Your Productivity Partner ",
      paragraph:
        "Terawe brings together all the pieces you need to increase productivity across the board ",
      image: ProductivityPartner,
      points: [
        "<span>Unified Identity Access</span> Seamless integration with Azure Active Directory with secure one-time authenticated login (SSO) for multiple applications.",
        "<span>SharePoint Integration</span> Service deployment complete with integration of key internal resources and assets already in place. ",
        "<span>Workplace Analytics</span> Services that not only add value, but measure their own performance and impact on your organization. ",
        "<span>Equalizing Mobility</span> Prioritizing mobile access and experience with seamless information protection, policy alignment, and selective wipe. "
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "Predictive Maintenance",
        "Fraud Detection",
        "Student Retention"
      ]
    }
  },
  HPC: {
    helmet: [
      {
        title: "Terawe HPC | Cloud Built For High Performance Computing",
        description:
          "Never run out of computing power with Terawe's ManageX cloud platform that puts cloud infrastructure at your fingertips without altering existing processes",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "High Performance Computing",
            "image": "",
            "description": "Never run out of computing power with Terawe's ManageX cloud platform that puts cloud infrastructure at your fingertips without altering existing processes",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "High Performance<br/>Computing",
      paragraph:
        "Terawe enables organizations with high-performance computing (HPC) adoption strategies and solutions that modernize business operations, drive innovation, and enables greater market differentiation. "
    },
    valueProps: {
      image: GlobalScaleAtYourService,
      title: "Global Scale At Your Service ",
      paragraph: [
        "Parallelized computing workflows common in HPC models are essential for pushing the boundaries of AI research and applications.  And when operating in an elastic cloud environment, the combination of HPC+AI+Cloud enables breakthroughs in various domains such as healthcare, finance, manufacturing, and scientific discovery. However, parallelized applications are difficult to design algorithmically, implement and maintain programmatically, and deploy at scale sustainably. ",
        "Instead of running HPC in the cloud, <span>Terawe’s HPC Solutions</span> act more like a cloud that is built for HPC.  Our offerings are designed to pursue your science and engineering goals, but without needing any previous working knowledge or experience of HPC, or even using the cloud in general.  In this manner, Terawe delivers the benefits of HPC into your workflow as easy as making the decision and completing the transaction…with causing a disruption. "
      ]
    },
    Convergence: {
      title: "The Convergence of HPC+AI ",
      paragraph:
        "HPC plays a vital role in enabling transformational AI use cases: ",
      points: [
        {
          title: "Accelerated Training ",
          paragraph:
            "HPC systems accelerate the training of AI deep learning models by leveraging parallel processing capabilities to distribute the computational workload across multiple processors or nodes. "
        },
        {
          title: "Scalability ",
          paragraph:
            "As volumes and complexities of data increases over time, HPC allows AI researchers and practitioners to scale their computational resources accordingly, ensuring efficient processing and analysis. "
        },
        {
          title: "Optimized Experimentation ",
          paragraph:
            "HPC enables the exploration of a wide range of model configurations and training parameters in parallel, thereby accelerating the process of finding optimal settings, leading to better-performing AI models. "
        },
        {
          title: "Complex Simulation ",
          paragraph:
            "AI techniques often involve running complex simulations to train agents, and HPC provides the necessary computational power to facilitate development of more advanced AI systems. "
        },
        {
          title: "Real-Time Inferencing ",
          paragraph:
            "HPC processes large volumes of concurrent computations efficiently, ensuring instant responses to applications requiring zero-latency responses such as autonomous vehicles or financial trading systems. "
        },
        {
          title: "Advanced Research ",
          paragraph:
            "As researchers tackle more ambitious and computationally intensive projects, HPC+AI systems ensure the backend infrastructure nrever becomes the gating agent of progress and innovation. "
        }
      ]
    },
    Modern: {
      title: "Examples of Modern HPC+AI",
      paragraph:
        "HPC plays a vital role in enabling transformational AI use cases:",
      points: [
        {
          image: HPC_Manufacturing,
          title: "Manufacturing",
          paragraph:
            "Manufacturing is the most versatile implementation model for HPC+AI because it can apply across multiple areas of relevance (supply optimization, product design, 3D visualization, digital twins, quality control, process automation, etc) and enable manufacturers to achieve the promise of Industry 4.0 without crippling downtimes or service disruptions."
        },
        {
          image: Academia,
          title: "Academia",
          paragraph:
            "There’s a wide variety of research exercises being conducted within academia today, everything from weather & climate modeling to biomedial and health sciences.  These exploratory workflows are computationally intensive, researchers can maximize their limited grant dollars by using HPC+AI services for process acceleration and faster results."
        },
        {
          image: Genomics,
          title: "Genomics",
          paragraph:
            "Genomics research within the Life Sciences sector requires the use of processing-intensive applications with enormous data sets.  HPC+AI services not only empower researchers with faster time-to-results for their critical missions, they also provide deep insights that can make those processes more useful and impactful to the ultimate outcome."
        },
        {
          image: HPC_Energy,
          title: "Energy",
          paragraph:
            "The energy sector presents one of the best examples where HPC can be most effective, whether it is accelerating upstream seismic processing or reservoir modeling applications for faster time-to-results, or downstream processing data at the edge for real-time well monitoring and predictive maintenance alerts."
        },
        {
          image: HPC_Finance,
          title: "Finance",
          paragraph:
            "HPC+AI platforms and services offer the greatest amount of processing speed and accuracy when it comes to computationally-intensive financial service applications.  We can help accelerate and improve your risk assessment, HFTs, fraud prevention, and portfolio optimization efforts without disrupting the workflows and practices you already have in place."
        }
      ]
    },
    Producing: {
      title: "Producing Outcomes You Expect",
      paragraph:
        "The specific outcomes you can expect when working with us for HPC",
      points: [
        {
          icon: <LowerCost />,
          title: "Shorter Lead Times",
          paragraph:
            "Shave days-to-weeks off procurement and deployment times, fast tracking directly to testing and validation."
        },
        {
          icon: <IncreasedProductivity />,
          title: "Sustainability",
          paragraph:
            "Choose sustainable computing with cloud platform tracking aggressively towards net-zero carbon emissions."
        },
        {
          icon: <Resilience />,
          title: "Responsiveness",
          paragraph:
            "Shave hours-to-days by bypassing waiting queues such that your quality time equates your app run time."
        },
        {
          icon: <MarketAgility />,
          title: "Evergreen Hardware",
          paragraph:
            "Exploit frequent refresh cycles without long-term obligations, as opposed to locking into three-to-five-year commitments."
        },
        {
          icon: <CarbonFootprint />,
          title: "Limitless Scale",
          paragraph:
            "Run your applications at scale, even if that means utilizing multiple Top500-ranking supercomputers in parallel ."
        },
        {
          icon: <AcceleratedDevelopment />,
          title: "Endless Innovation",
          paragraph:
            "Rapidly prototype by tapping a broad and deep HPC ecosystem that includes powerful AI services."
        },
        {
          icon: <LowerCost />,
          title: "Versatile Cost Models",
          paragraph:
            "Enlist resources as pay-per-use OPEX expenditures and/or fixed-term CAPEX assets, whatever is best for you."
        },
        {
          icon: <MarketAgility />,
          title: "Hybrid Implementation",
          paragraph:
            "Create more elastic, flexible HPC infrastructure by using cloud for additional capacity only when you need it"
        }
      ]
    },
    Solution: {
      title: "Our HPC Solution Areas",
      paragraph:
        "Let us help you take full advantage of all that modern HPC systems have to offer:",
      points: [
        {
          image: ManageXAcademy,
          title: "Learning HPC",
          paragraph:
            "The idea of using HPC can be very alienating to those outside the world of HPC. <a href='https://managex.academy/' target='_blank'>ManageX Academy</a> , our cloud-savvy platform for active learning about resourcing and using HPC, will help improve your HPC knowledge & skill base at your own pace and aligned to your use case.",
          btnImage: manageX,
          btnText: "ACADEMY",
          link: "https://managex.academy/"
        },
        {
          image: ManageXResearch,
          title: "Using HPC",
          paragraph:
            "Bringing HPC into an existing workflow comes with technical distractions and hurdles.  Ensure your focus is your science or engineering by letting our <a href='https://managex.terawe.com/research' target='_blank'>ManageX Research</a> take the housekeeping out of your way, delivering HPC as a cloud-based managed service tailored to your specific use case.",
          btnImage: manageX,
          btnText: "RESEARCH",
          link: "https://managex.terawe.com/research"
        },
        {
          image: ManageXHPC,
          title: "Integrating HPC",
          paragraph:
            "The benefits of HPC utilization can impact your entire organization...as long as it aligns to your corporate policies.  Lean on <a href='https://managex.terawe.com/hpc/i' target='_blank'>ManageX HPC</a> to help you establish the necessary administration and access controls to leverage the value of HPC to your products, services, and solutions.",
          btnImage: manageX,
          btnText: "HPC",
          link: "https://managex.terawe.com/hpc/i"
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "HPC as a managed service built on oneAPI in the cloud",
        "Right-Focusing Your Research with HPC as a Managed Service",
        "The Ubiquity of Scale-Up and Scale-Out Infrastructures for HPC and AI"
      ]
    }
  }
};
/*
title: 'aaa',
paragraph: 'zzz',
image: 'azh'
resources: {
  title: 'More Resources',
  resourceTitle: ["aaa", "aaa", "aaa"],
}
*/
export default SpecialitiesContentObject;
