import React, { useEffect, useState } from "react";
import { CareerObject } from "./CareerObject";
import InnerBanner from "../UiComponents/InnerBanner";
import banner from "../../assets/img/careers/Careers_MainBanner.jpg";
import { MainTitle, MetaHelmet } from "../UiComponents/ContentBlocks";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import axios from "axios";
import { API_KIND, BASE_URL, orgId } from "../../utils/config";
import Modal from "@mui/material/Modal";
import Applyjobform from "./Applyjobform";
import { Close, TaskAlt } from "@mui/icons-material";
import { Box, IconButton, Snackbar } from "@mui/material";

export default function Careers() {
  const [expanded, setExpanded] = useState(false);
  const [currentOpenings, setOpenings] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openSnack, setSnackOpen] = React.useState(false);

  const handleSnackOpen = () => {
    setSnackOpen(true);
  };

  const handleBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handleOpen = (item) => {
    setSelectedItem({ ...item });
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedItem(null);
    setOpen(false);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    axios
      .post(BASE_URL, {
        kind: API_KIND.getJobList,
        data: {
          orgId: orgId
        }
      })
      .then((res) => setOpenings({ ...res.data }))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (open) {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "auto";
    }

    // Cleanup when component unmounts
    return () => {
      htmlElement.style.overflow = "auto";
    };
  }, [open]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleBarClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const root = CareerObject;
  return (
    <React.Fragment>
      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleBarClose}
        message={
          <Box
            display={"flex"}
            gap="10px"
            alignItems={"center"}
            fontWeight={"700"}
          >
            {" "}
            <TaskAlt color={"success"} /> Submitted successfully
          </Box>
        }
        action={action}
      />
      <Modal open={open} onClose={handleClose}>
        <Box className="modalContainer">
          <Close onClick={handleClose} />
          <Applyjobform
            handleClose={handleClose}
            handleSnackOpen={handleSnackOpen}
            selectedItem={selectedItem}
          />
        </Box>
      </Modal>

      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        bannerLight={true}
        backgroundImage={banner}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle title={root.WorkInTerawe.pageTitle} />
        </div>
        <div className="container">
          <div className="ltw-container">
            {root.WorkInTerawe.points.map((items, index) => {
              return (
                <div className="ltw-wrapper" key={index.toString()}>
                  <p className="ltw-quote">{items.paragraph}</p>
                  <div className="ltw-speakerContainer">
                    <img
                      className="ltw-speakerImage location"
                      src={items.location}
                      alt={items.title}
                    />
                    <div className="ltw-speakerWrapper">
                      <p
                        className="ltw-speakerName"
                        dangerouslySetInnerHTML={{ __html: items.name }}
                      ></p>
                      <p className="ltw-speakersub">{items.subtitle}</p>
                    </div>
                    <img
                      className="ltw-speakerImage"
                      src={items.image}
                      alt={items.title}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {currentOpenings?.data?.content?.result && (
        <section className="sectionContainer">
          <div className="container">
            <MainTitle title={root.currentOpenings.title} />
          </div>
          <div className="container">
            <Accordion className="accordianCarrier titledAccordion">
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRoundedIcon className="jd-summary-container-icon" />
                }
              >
                <div className="jd-summary-container">
                  <div className="jd-summary-job">Position Title</div>
                  <div className="jd-summary-text ">Position location</div>
                  <div className="jd-summary-text"> Exp. Required</div>
                </div>
              </AccordionSummary>
            </Accordion>
            {currentOpenings?.data?.content?.result.map((item, index) => {
              return (
                <Accordion
                  key={index.toString()}
                  className="accordianCarrier"
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownRoundedIcon className="jd-summary-container-icon" />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="jd-summary-container">
                      <div className="jd-summary-job">{item?.role}</div>
                      <div className="jd-summary-text ">
                        {
                          currentOpenings?.data?.content?.locationDetails?.find(
                            (location) => location.id === item.locationId
                          ).city
                        }
                        ,{" "}
                        {
                          currentOpenings?.data?.content?.locationDetails?.find(
                            (location) => location.id === item.locationId
                          ).countryCode
                        }
                      </div>
                      <div className="jd-summary-text"> {item.experience}</div>
                      <button
                        className="accept-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpen(item);
                        }}
                      >
                        Apply Now
                      </button>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="jd-details-body">
                    <div className="jd-details-container">
                      <div className="jd-summary-section">
                        <p className="jd-summary-sectionTitle">
                          Education Qualification
                        </p>
                        <p className="jd-summary-details">{item.education}</p>
                      </div>
                      <div className="jd-summary-section">
                        <p className="jd-summary-sectionTitle">
                          Required Skills
                        </p>
                        <ul className="jd-summary-list">
                          {item.skills.map((value, idx) => {
                            return (
                              <li key={idx.toString()}>
                                <p className="jd-summary-details">{value}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      {item.optionalSkills.length > 0 && (
                        <div className="jd-summary-section">
                          <p className="jd-summary-sectionTitle">
                            Nice to have
                          </p>
                          <ul className="jd-summary-list">
                            {item.optionalSkills.map((value, idx) => {
                              return (
                                <li key={idx.toString()}>
                                  <p className="jd-summary-details">{value}</p>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                      <div className="jd-summary-section">
                        <p className="jd-summary-sectionTitle">
                          Roles and Responsibilities
                        </p>
                        <ul className="jd-summary-list">
                          {item.roles.map((value, idx) => {
                            return (
                              <li key={idx.toString()}>
                                <p className="jd-summary-details">{value}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </section>
      )}
    </React.Fragment>
  );
}
