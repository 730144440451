import React from "react";
import bannerImage from "../../assets/img/HPC/Hpc_Banner.jpg";
import SpecialitiesContentObject from "./SpecialitiesContentObject";
import InnerBanner from "../UiComponents/InnerBanner";
import ValueProposition from "../UiComponents/ValueProposition";
import {
  IconBlockVertical,
  MetaHelmet,
  ResourcesBlock,
  TextButton,
  TextCarouselSlick,
  TitledCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function Hpc() {
  const root = SpecialitiesContentObject.HPC;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />

      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <ValueProposition
        image={root.valueProps.image}
        title={root.valueProps.title}
        paragraph={root.valueProps.paragraph}
      />

      <TextCarouselSlick
        bgBlack={true}
        slideScroll={2}
        title={root.Convergence.title}
        paragraph={root.Convergence.paragraph}
        points={root.Convergence.points}
      />

      <TitledCarouselSlick
        title={root.Modern.title}
        points={root.Modern.points}
      />

      <IconBlockVertical
        title={root.Producing.title}
        points={root.Producing.points}
      />

      <TextButton
        customClass="bgBlack"
        title={root.Solution.title}
        paragraph={root.Solution.paragraph}
        points={root.Solution.points}
      />

      <ResourcesBlock
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
