import React from "react";
import bannerImage from "../../assets/img/Offerings/consultingHeader.jpg";
import InnerBanner from "../UiComponents/InnerBanner";
import OfferingsObject from "./OfferingsObject";
import {
  BlockTextCentered,
  IconBlockVertical,
  ImageContent,
  MetaHelmet
} from "../UiComponents/ContentBlocks";

export default function ConsultingServices() {
  const root = OfferingsObject.ConsultingServices;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <ImageContent
            title={root.GuideByYourSide.title}
            titleInner={root.GuideByYourSide.title}
            image={root.GuideByYourSide.image}
            points={root.GuideByYourSide.points}
          />
        </div>
      </section>

      <BlockTextCentered
        title={root.ValueProposition.title}
        paragraph={root.ValueProposition.paragraph}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <ImageContent
            rowReverse={true}
            title={root.YourFuture.title}
            titleInner={root.YourFuture.title}
            image={root.YourFuture.image}
            points={root.YourFuture.points}
          />
        </div>
      </section>

      <IconBlockVertical
        title={root.OurServices.title}
        points={root.OurServices.points}
      />

      {/* <section className="sectionContainer">
        <div className="container">
          <ImageContent
            title={root.ManagedService.title}
            titleInner={root.ManagedService.title}
            image={root.ManagedService.image}
            points={root.ManagedService.points}
          />
        </div>
      </section>

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle title={"Multi-tiered Support"} />
          <img src={TieredSupport} alt="Multi-tiered Support" />
        </div>
      </section> */}
    </React.Fragment>
  );
}
