import trustedPartner from "../../assets/img/Offerings/ServiceProvider-TrustedPartner.jpg";
import managedServices from "../../assets/img/Offerings/ServiceProvider-ManagedServices.png";
import SharingTheLoad from "../../assets/img/Offerings/managedServicesSharingTheLoad.jpg";
import ourServices from "../../assets/img/Offerings/managedServicesOurServices.jpg";
import guideByYourSide from "../../assets/img/Offerings/guideByYourSide.jpg";
import yourFuture from "../../assets/img/Offerings/consultingServicesYourFutureToday.jpg";
import {
  AiIcon,
  BigDataIcon,
  GreenTransformationIcon,
  LowerCost,
  ManageServiceIcon,
  Resilience
} from "../UiComponents/Icons";

const OfferingsObject = {
  ServiceProvider: {
    helmet: [
      {
        title: "Terawe Corp | Managed IT & Cloud Services Provider",
        description:
          "We offer comprehensive, scale-able service packages, including managed IT services, IT support, cloud services, and managing service providers.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Service Provider",
            "image": "",
            "description": "We offer comprehensive, scale-able service packages, including managed IT services, IT support, cloud services, and managing service providers.",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Service<br/> Provider",
      link: "/service-provider"
    },
    TrustedPartner: {
      title: "Trusted partner",
      image: trustedPartner,
      points: [
        "In the era while organizations are continuously innovating and improving their software products and offerings, or startups are trying to launch their first service, the demand for top talent and services has reached new heights. It is easy to fall prey to paying high premium in today’s climate. In this environment, relying on trusted partners and suppliers who have the DNA of creating and successfully delivering reliable, scalable, secure, functional and performant software and products is key."
      ],
      hashLink: "/service-provider#TrustedPartnerID"
    },
    ValueProposition: {
      title: "Our Value Proposition",
      paragraph: [
        "Trusted by top Fortune 500 companies and startups, we provide 360-degree software services to achieve product development goals through all stages of product lifecycle. Outsource work to our delivery team or extend your team to work with our top talent resources. Work with highly qualified leadership team and delivery team members who have significant experience in delivering high quality services for your products resulting in high ROI. With transparent pricing and low administrative overheads, achieve high value business outcomes."
      ],
      hashLink: "/service-provider#ValuePropositionID"
    },
    OurServices: {
      title: "Our Services",
      points: [
        {
          title: "Product Management:",
          paragraph:
            "Conduct market research. Define product strategy. Create product roadmap. Define user stories, personas, and acceptance criteria. Manage and refine product stories. Engage customers and incorporate feedback."
        },
        {
          title: "Project Management:",
          paragraph:
            "Managing various aspects of projects that include Agile, Scrum and Waterwall methodologies. Act as project and delivery managers to plan and manage tasks and resources. Can perform duties of budget planning, quality control and change management across the product lifecycle."
        },
        {
          title: "Program Management:",
          paragraph:
            "Technical program management, define product requirements and functionality, technical management of product development and engineering."
        },
        {
          title: "Architecture:",
          paragraph:
            "Our experienced software architects assist in product-level and component-level design and architecture spanning cloud, edge and on-premises boundaries."
        },
        {
          title: "Software Development:",
          paragraph:
            "Spanning breadth of technologies and areas, ranging from front end development to backend development, including expertise in AI, Blockchain, IoT, Mixed Reality, our qualified engineers help accelerate product engineering efforts."
        },
        {
          title: "DevOps:",
          paragraph:
            "Creating, managing and monitoring pipelines through Continuous Integration and Deployment services. Monitoring and troubleshooting code and infrastructure to ensure SLAs are met for your product. With DevSecOps, we ensure reliable security product lifecycle, increase safety and reduce costs."
        },
        {
          title: "Product Design:",
          paragraph:
            "User research, creation of customer journey maps, UX design for applications across web, mobile and other form factors."
        },
        {
          title: "Quality Assurance:",
          paragraph:
            "With our testing, validation, and automation services, increase your guarantee to ship quality products, and reduce downtime and costs while improving your customer experience."
        },
        {
          title: "Content Creation:",
          paragraph:
            "User guides, IT pro documentation and technical writing services for product content, blogs and whitepapers."
        },
        {
          title: "Operations Management:",
          paragraph:
            "Management of IT infrastructure, security and compliance management for your peace of mind."
        },
        {
          title: "Marketing Services:",
          paragraph:
            "Ranging from targeted story telling for web content, to customer ready decks to video production - we create story arcs, scripts, collateral and assets with relevant design and messaging for targeted audience."
        },
        {
          title: "Media Creation:",
          paragraph:
            "End-to-end video production covering script, to narration to production of animated videos and product demo videos for business and technical audience."
        }
      ],
      hashLink: "/service-provider#OurServicesID"
    },
    ManagedServices: {
      title: "Our managed services include:",
      image: managedServices,
      points: [
        "<b>Basic Managed Services</b><br/> Tier2 / Tier 3 Reactive Support in Business Hours or 24X7",
        "<b>Advanced Managed Services</b><br/> Operations Management, Cloud Enablement, Customer Engagement, IT Task Automation, SLA-driven Proactive Monitoring, Workload Migration, Pre-Sales technical and business guidance, Business and Technology Assessments, Strategic Transformation roadmapping"
      ],
      learnMore: [
        {
          link: "/Managed-Services",
          text: "Learn More"
        }
      ],
      hashLink: "/service-provider#ManagedServicesID"
    },
    CustomersQuotes: {
      title: "Quotes from our Customers",
      points: [
        "'<b>Extremely professional, responsive and knowledgeable, </b>which makes them easy to work with and a strong contributor to projects’ success.'",
        "'Terawe is <b>a collaborative and responsive partner with deep expertise </b>around Cloud and Edge technologies.'",
        "'<b>Five Stars</b> Partner interaction: <b>Five Stars</b> Partner feedback: <b>Five Stars</b> on Data platform consulting work.'",
        "'Terawe provided a <b>highly skilled and professional</b> team of consultants, writers and designers that helped us deliver to the high bar we expected.'",
        "'Terawe has been managing the Hadoop clusters across both Windows and Linux for 2+ years and they have <b>consistently done a good job</b>.'",
        "'Their ability to adapt to new information, research and turnaround with high quality work has been impressive. <b>I would highly recommend their services.</b>'",
        "'Terawe is <b>our go-to partner</b> for industry vertical and technical content creation'"
      ],
      hashLink: "/service-provider#CustomersQuotesID"
    }
  },
  ManagedServices: {
    helmet: [
      {
        title: "Terawe | Managed IT, Security & Cloud Services & Solutions",
        description:
          "Our managed services include cloud/hybrid/edge computing, IT operations, data & app security, regulatory compliance, DevOps, and customer support.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Managed Services",
            "image": "",
            "description": "Our managed services include cloud/hybrid/edge computing, IT operations, data & app security, regulatory compliance, DevOps, and customer support.",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Managed<br/> Services",
      link: "/Managed-Services"
    },
    SharingTheLoad: {
      title: "Sharing the Load",
      image: SharingTheLoad,
      points: [
        "Organizations can experience operational strains and inefficiencies when embracing new technologies at a rapid pace. Manual tasks, escalating Tier1/Tier2/Tier 3 support costs and the challenge of managing a multitude of Open Source and proprietary technologies and vendors can distract from vital Strategic IT Assessments, Digital Transformation, Road-mapping and Technical Pre-Sales Support."
      ],
      hashLink: "/Managed-Services#SharingTheLoadID"
    },
    ValueProposition: {
      title: "Our Value Proposition",
      paragraph: [
        "Terawe provide a full spectrum of Managed Services to supplement your in-house expertise and resources. Whether your needs are short, medium or long-term, we will work with you to ensure you have the support you need to achieve your business objectives. We’re here, 24/7, ready to provide you with the assistance you need. With guaranteed SLAs round the clock and modern practices we deploy, achieve peace of mind while reducing your total cost. Our services span across cloud, hybrid and on-premises PaaS, SaaS and Product offerings you have."
      ],
      hashLink: "/Managed-Services#ValuePropositionID"
    },
    OurServices: {
      title: "Our Services",
      image: ourServices,
      points: [
        "We actively monitor service level indicators in pursuit of our service level objectives in order to fulfill our service level agreements."
      ],
      list: [
        "Support Services",
        "Operation Services",
        "Site Reliability Engineering Services",
        "DevOps Engineering Services",
        "Security and Compliance Services"
      ],
      hashLink: "/Managed-Services#OurServicesID"
    },
    ManagedService: {
      title: "Our managed services include:",
      image: managedServices,
      points: [
        "<b>Basic Managed Services</b><br/> Tier2 / Tier 3 Reactive Support in Business Hours or 24X7",
        "<b>Advanced Managed Services</b><br/> Operations Management, Cloud Enablement, Customer Engagement, IT Task Automation, SLA-driven Proactive Monitoring, Workload Migration, Pre-Sales technical and business guidance, Business and Technology Assessments, Strategic Transformation roadmapping"
      ],
      hashLink: "/Consulting-Services#ManagedServiceID"
    }
  },
  ConsultingServices: {
    helmet: [
      {
        title: "Terawe Corp | IT & Cloud Transformation Consulting Services",
        description:
          "Explore what business transformation means to you by engaging our team of professional subject matter experts to help you achieve your desired outcomes",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Consulting Services",
            "image": "",
            "description": "Explore what business transformation means to you by engaging our team of professional subject matter experts to help you achieve your desired outcomes",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Consulting<br/>Services",
      link: "/Consulting-Services"
    },
    GuideByYourSide: {
      title: "A Guide By Your Side",
      image: guideByYourSide,
      points: [
        "Digital Transformation is taking place at an ever-accelerating pace. Revolutionary cloud and analytics technologies are enabling organizations to develop and implement cutting-edge new solutions. Our consulting and professional services allow you to benefit from our expertise and experience as we partner with you to accelerate progress and address the key challenges and opportunities in your business."
      ],
      hashLink: "/Consulting-Services#GuideByYourSideID"
    },
    ValueProposition: {
      title: "Our Value Proposition",
      paragraph: [
        "Terawe services are here to help you, from original ideation, to staffing up to meet the demand you have generated in the marketplace. Our experts will work alongside you to identify market opportunities and to develop solutions which will excite your customers and grow your business."
      ],
      hashLink: "/Consulting-Services#ValuePropositionID"
    },
    YourFuture: {
      title: "Your future, today",
      image: yourFuture,
      points: [
        "Organizations can experience operational strains and inefficiencies when embracing new technologies at a rapid pace. Manual tasks, escalating Tier1/Tier2/Tier 3 support costs and the challenge of managing a multitude of Open Source and proprietary technologies and vendors can distract from vital Strategic IT Assessments, Digital Transformation, Road-mapping and Technical Pre-Sales Support."
      ],
      hashLink: "/Consulting-Services#YourFutureID"
    },
    OurServices: {
      title: "Our Services",
      points: [
        {
          title: "Cloud Transformation Assessment",
          paragraph:
            "Helping organizations to spot industry trends and new opportunities, and to target specific customer segments and industry verticals with value-added solutions and services.",
          icon: <AiIcon />
        },
        {
          title: "Full end-to-end Go-To-Market",
          paragraph:
            "Offer formulation, Value-prop creation, Business Planning, Messaging and Positioning, Offer Pricing and Pre-Sales assistance",
          icon: <BigDataIcon />
        },
        {
          title: "Solution Acceleration Services",
          paragraph:
            "Enhance your capabilities by leveraging our solution Acceleration Services to deliver end-to-end solutions efficiently.",
          icon: <GreenTransformationIcon />
        },
        {
          title: "Managed Services Practice Development",
          paragraph:
            "Focused workshops that help you understand the full spectrum of managed services and to determine what is right for you",
          icon: <ManageServiceIcon />
        },
        {
          title: "Custom Application Development",
          paragraph:
            "Portals, Billing integration and Full-Stack Applications on Microsoft and Open-Source technologies.",
          icon: <LowerCost />
        },
        {
          title: "Staff Augmentation",
          paragraph:
            "Enhance your in-house capabilities by leveraging Terawe personnel experienced in delivering systems and software on cutting-edge technologies.",
          icon: <Resilience />
        }
      ],
      hashLink: "/Consulting-Services#OurServicesID"
    }
  }
};
/*
title: 'aaa',
paragraph: 'zzz',
image: 'azh'
resources: {
  title: 'More Resources',
  resourceTitle: ["aaa", "aaa", "aaa"],
}
*/
export default OfferingsObject;
