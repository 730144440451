import React from "react";
import bannerImage from "../../assets/img/Finance/FinanceHeader.jpg";
import InnerBanner from "../UiComponents/InnerBanner";
import IndustriesObject from "./IndustriesObject";
import {
  HoverSlideUp,
  MainTitle,
  MetaHelmet,
  PartnersBlock,
  ResourcesBlock,
  SolutionsBlock,
  TitleParagraphBlock,
  VerticalCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function Ai() {
  const root = IndustriesObject.Finance;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        noGradient={true}
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <HoverSlideUp
        bgBlack
        title={root.Opportunities.title}
        customcontainer={"bgDark"}
        points={root.Opportunities.points}
        customClassContainer={"whoWeAreSlide"}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle
            title={root.Challenges.title}
            paragraph={root.Challenges.paragraph}
          />
        </div>
        <div className="container">
          <TitleParagraphBlock marker={true} points={root.Challenges.points} />
        </div>
      </section>

      <VerticalCarouselSlick
        title={root.Solution.title}
        paragraph={root.Solution.paragraph}
        points={root.Solution.points}
      />

      <PartnersBlock title={root.Partner.title} points={root.Partner.points} />

      <SolutionsBlock
        title={root.Financial.title}
        points={root.Financial.points}
        customClass={"spaceAround"}
      />

      <ResourcesBlock
        black={true}
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
