import { Fragment } from "react";
import { RightDropArrow } from "./Icons";

const InnerBanner = (props) => {
  return (
    <Fragment>
      <section
        className={`project-hero view-detect ${
          props.bannerLight ? "heroLight" : ""
        } ${props.logo ? "imagedTitle" : ""} ${
          props.patch ? "heroPatch" : ""
        } ${props.insightType ? "insightBanner" : ""}`}
      >
        <div className="inner relative">
          <div className="media">
            <div
              className="image paroller"
              style={{
                backgroundImage: `url(${
                  props?.backgroundImage ? props?.backgroundImage : "none"
                })`
              }}
            >
              {props?.backgroundVideo && (
                <video
                  className="video-block video"
                  preload="auto"
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  poster={""}
                  playsInline={""}
                >
                  <source src={props.backgroundVideo} type="video/mp4" />
                </video>
              )}
            </div>
          </div>

          <div
            className={`${
              props.customContaienr ? props.customContaienr : ""
            }  container relative`}
          >
            <div className="project-info">
              <div className="text-wrap">
                {props.logo && (
                  <img
                    className={`bannerLogo`}
                    src={props.logo}
                    alt={props.bannerTitle}
                  />
                )}
                {props.insightType && <p>{props.insightType + ":"}</p>}
                <h1
                  className="project-title"
                  dangerouslySetInnerHTML={{ __html: props?.bannerTitle }}
                />
                {props?.bannerParagraph &&
                  props.bannerParagraph.map((item, i) => (
                    <h3 key={i} className="project-desc">
                      {item}
                    </h3>
                  ))}
                {props.isInsightnav && (
                  <div className="insight-navigation">
                    <a href="/Insights">Insight</a>
                    <RightDropArrow />
                    <a href={window.location}>
                      {props?.bannerTitle || props.featureParagraph}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {props?.featureParagraph && (
          <div className="project-features z-index--2">
            <p
              className={
                "feature-value featureValue " + props.featureParagraphClass
              }
              dangerouslySetInnerHTML={{ __html: props.featureParagraph }}
            ></p>
          </div>
        )}

        {props.featurePoints && (
          <div className="project-features z-index--2">
            {props.featurePoints.map((item, key) => (
              <div className="feature" key={key}>
                <h6 className="feature-title">{item.title}</h6>
                <p className="feature-value">{item.paragraph}</p>
              </div>
            ))}
          </div>
        )}
      </section>
    </Fragment>
  );
};

export default InnerBanner;
