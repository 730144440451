import CareerImage from "../../assets/img/aboutUs/Career.webp";
import CocoZeng from "../../assets/img/aboutUs/CultureDiversity-CocoZeng.webp";
import Alexandre from "../../assets/img/aboutUs/CultureDiversity-Alexandre.webp";
import Sheena from "../../assets/img/aboutUs/CultureDiversity-Sheena.webp";
import CocoLocation from "../../assets/img/aboutUs/CocoLocation.png";
import SheenaLocation from "../../assets/img/aboutUs/SheenaLocation.png";
import AlexLocation from "../../assets/img/aboutUs/AlexLocation.png";

export const CareerObject = {
  helmet: [
    {
      title: "Build Your Career with Terawe | AI, Data, Cloud & Edge ",
      description:
        "Explore our current job openings and catapult your career in the fields of AI consulting, cloud computing, Edge IoT solutions, and other emerging technologies",
      keywords: "abc",
      schema: `
        {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "Careers",
          "image": "",
          "description": "Explore our current job openings and catapult your career in the fields of AI consulting, cloud computing, Edge IoT solutions, and other emerging technologies",
          "brand": "Terawe"
        }`
    }
  ],
  pageTitle: {
    heading: "Careers",
    title: " Careers | Terawe Corporation",
    link: "/Careers",
    paragraph:
      "We're always looking for dedicated, capable individuals who are passionate about technology and want to make a difference in people's lives.  Apply now to join our diverse team and advance your own career."
  },
  weLookFor: {
    image: CareerImage,
    paragraph: [
      "We are looking for dedicated, creative individuals who want to explore their passions and inspire others through the power of technology. With a diverse mix of people and skills, Terawe is a great place to work - whether you are starting out, or a proven leader looking for a new challenge!"
    ]
  },
  WorkInTerawe: {
    pageTitle: "What’s it like to work at Terawe?",
    points: [
      {
        paragraph:
          "  “Terawe provides an environment open and receptive to my input, which builds my confidence as someone making a positive and lasting contribution.”​",
        image: CocoZeng,
        name: "Coco Z.",
        subtitle: "UX Designer",
        location: CocoLocation
      },
      {
        paragraph:
          "“Working at Terawe is about excelling in a fast-paced, collaborative environment where you enjoy witnessing the positive impact that technology has on people’s lives.”​​",
        image: Alexandre,
        name: "Alexandre C.",
        subtitle: "Sales Manager",
        location: AlexLocation
      },
      {
        paragraph:
          "“I feel empowered when I come to work every day because Terawe has put me in a position where I can make a real difference with clients and co-workers.”​",
        image: Sheena,
        name: "Sheena D.",
        subtitle: "Operations Manager",
        location: SheenaLocation
      }
    ]
  },
  currentOpenings: {
    title: "Current Openings"
  }
};

export const currentOpenings = {
  code: 0,
  data: {
    message: "Career List",
    content: {
      locationDetails: [
        {
          id: "35a024b1-c2c1-4bf6-ab94-c21de8725dc5",
          name: "USA - Washington",
          orgId: null,
          streetAddress: "12503 Bel-Red Rd, ST 201",
          city: "Bellevue",
          state: "WA",
          country: "",
          postalCode: "98005",
          countryCode: "USA",
          phone: null,
          additionalAttributes: null
        },
        {
          id: "4e259d64-4b47-403b-b9d9-8c11a6cbd65f",
          name: "Australia",
          orgId: null,
          streetAddress: "3 Laycock Road",
          city: "Penshurst",
          state: "New South Wales",
          country: "",
          postalCode: "2222",
          countryCode: "AUS",
          phone: null,
          additionalAttributes: null
        },
        {
          id: "28b36059-a379-443d-b039-ae4fde90872c",
          name: "India",
          orgId: null,
          streetAddress:
            "4th Floor, IndiQube Crystal, Municipal 73, Church St, near MG Road",
          city: "Bengaluru",
          state: "Karnataka",
          country: "",
          postalCode: "560001",
          countryCode: "IND",
          phone: null,
          additionalAttributes: null
        },
        {
          id: "1d33be7f-d8f4-4dfc-89a0-be81f500b168",
          name: "USA - California",
          orgId: null,
          streetAddress: "1267 Willis St",
          city: "STE 200",
          state: "Redding",
          country: "",
          postalCode: "CA - 96001",
          countryCode: "USA",
          phone: null,
          additionalAttributes: null
        },
        {
          id: "8afb8ac4-3475-4a6d-b5a6-d5dd7be45001",
          name: "Canada",
          orgId: null,
          streetAddress: "Suite 1500, 13450 - 102nd Avenue",
          city: "Surrey BC V3T 5X3",
          state: "",
          country: "Canada",
          postalCode: "",
          countryCode: "CAN",
          phone: null,
          additionalAttributes: null
        },
        {
          id: "937ecf37-f2f8-4466-b393-248b8a2ee85f",
          name: "Ireland",
          orgId: null,
          streetAddress: "Penrose House, FIRST, 1 Penrose Dock",
          city: "Cork",
          state: "",
          country: "Ireland",
          postalCode: "T23 KW81",
          countryCode: "IRL",
          phone: null,
          additionalAttributes: null
        }
      ],
      result: [
        {
          id: "1",
          role: "Software Engineer - Backend Developer",
          orgId: "3cd5af9c-787a-4081-96ca-18b311c10c1a",
          description: "--",
          skills: [
            "Expertise in server-side languages such as Python, Java, .Net, NodeJS, C#.",
            "Proficiency with database technology such as MySQL, Oracle and MongoDB.",
            "Experience in integrating User Interface frameworks such as Angular JS, React JS (Preferred) and Amber.",
            "Experience in product development in one of the cloud platforms (Azure, AWS, GCP (Preferred)).",
            "Experience in working with Docker & Kubernetes.",
            "Hands on experience in writing and reviewing requirements, test plans and design documents.",
            "Exposure to Agile development Methodologies.",
            "Good understanding of OOPs Design principles."
          ],
          optionalSkills: [
            "Exposure to microservices application development.",
            "Good problem-solving & analytical skills.",
            "Good team player.",
            "Good Communication skills, verbal, written and listening skills.",
            "Good understanding of DevOps Technologies.",
            "Exposure to Test Driven Development.",
            "Exposure to Blockchain will be an added advantage."
          ],
          roles: [
            "Design, develop, and implement software solutions.",
            "Support the entire application lifecycle (concept, design, test, release and support).",
            "Gather specific requirements and suggest solutions.",
            "Lead quality assurance and testing.",
            "Collaborate with Product development team to plan new features.",
            "Analyse code and communicate detailed reviews to development teams to ensure a marked improvement in applications and the timely completion of projects.",
            "Implement integrations requested by customers.",
            "Deploy updates and fixes.",
            "Collaborate with team members to improve the company’s engineering tools, systems and procedures.",
            "Handle Management and end user communication."
          ],
          experience: "3 - 4 Years",
          education:
            "Bachelor of Technology in Computer Science or related field",
          linkedInUrl: null,
          locationId: "28b36059-a379-443d-b039-ae4fde90872c",
          additionalAttributes: null
        }
      ]
    }
  }
};
