import React from "react";
import InnerBanner from "../UiComponents/InnerBanner";
import bannerImage from "../../assets/img/edgeComputing/EdgeComputing_Banner.jpg";
import SpecialitiesContentObject from "./SpecialitiesContentObject";
import ValueProposition from "../UiComponents/ValueProposition";
import {
  ImageCollage,
  MetaHelmet,
  ResourcesBlock,
  TextCarouselSlick,
  VerticalCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function EdgeComputing() {
  const root = SpecialitiesContentObject.EdgeComputing;

  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <ValueProposition
        image={root.aboutComputing.image}
        title={root.aboutComputing.title}
        paragraph={root.aboutComputing.paragraph}
        imageDetails={root.aboutComputing.imageDetails}
      />

      <TextCarouselSlick
        bgBlack
        title={root.solutions.title}
        points={root.solutions.points}
      />

      <ImageCollage title={root.value.title} points={root.value.points} />

      <VerticalCarouselSlick
        title={root.industries.title}
        points={root.industries.points}
      />

      <ResourcesBlock
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
