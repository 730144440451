import React from "react";
import bannerImage from "../../assets/img/Telecommunications/Comm_MainLanding.jpg";
import IndustriesObject from "./IndustriesObject";
import InnerBanner from "../UiComponents/InnerBanner";
import {
  IconBlockVertical,
  ImageContent,
  MainTitle,
  MetaHelmet,
  ResourcesBlock,
  SolutionsBlock,
  TitleParagraphBlock,
  VerticalCarouselSlick
} from "../UiComponents/ContentBlocks";
import { Zoom } from "../UiComponents/Icons";

export default function Telecommunications() {
  const root = IndustriesObject.Communications;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <IconBlockVertical
        title={root.Impact.title}
        points={root.Impact.points}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle title={root.Communications.title} />
        </div>
        <div className="container">
          <TitleParagraphBlock
            marker={true}
            points={root.Communications.points}
          />
        </div>
      </section>

      <section className="sectionContainer">
        <div className="container">
          <MainTitle title={root.Approach.title} />
        </div>
        <div className="container">
          <ImageContent
            isModalImage={true}
            image={root.Approach.image}
            points={root.Approach.paragraph}
            iconModal={<Zoom />}
          />
        </div>
      </section>

      <VerticalCarouselSlick
        title={root.Solution.title}
        paragraph={root.Solution.paragraph}
        points={root.Solution.points}
      />

      <SolutionsBlock
        bgBlack
        title={root.Terawe.title}
        points={root.Terawe.points}
      />

      <section className="sectionContainer">
        <div className="container">
          <MainTitle title={root.Telcos.title} />
        </div>
        <div className="container">
          <ImageContent
            image={root.Telcos.image}
            points={root.Telcos.paragraph}
          />
        </div>
      </section>

      <ResourcesBlock
        black={true}
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
