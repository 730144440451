import React from "react";
import StudiosContentObject from "./StudiosContentObject";
import InnerBanner from "../UiComponents/InnerBanner";
import bannerImage from "../../assets/img/studio/Studios-DesignStudio-MainHeader.jpg";

import ValueProposition from "../UiComponents/ValueProposition";
import { MetaHelmet } from "../UiComponents/ContentBlocks";

export default function DesignStudio() {
  const root = StudiosContentObject.DesignStudio;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
      />
      <ValueProposition
        image={root.experienceEngineering.image}
        // isOntop={true}
        title={root.experienceEngineering.title}
        paragraph={root.experienceEngineering.paragraph}
        list={root.experienceEngineering.points}
      />
      <ValueProposition
        image={root.AICharacterCreation.image}
        // isOntop={true}
        title={root.AICharacterCreation.title}
        mainContainerClass="bgDark"
        customConiainer="flip"
        customtextcontainer="flipped-text-container"
        paragraph={root.AICharacterCreation.paragraph}
        list={root.AICharacterCreation.points}
      />
      <ValueProposition
        image={root.BrandedCustomVoices.image}
        // isOntop={true}
        title={root.BrandedCustomVoices.title}
        paragraph={root.BrandedCustomVoices.paragraph}
        list={root.BrandedCustomVoices.points}
      />
      <ValueProposition
        image={root.ProfessionalVideoProduction.image}
        // isOntop={true}
        title={root.ProfessionalVideoProduction.title}
        mainContainerClass="bgDark"
        customConiainer="flip"
        customtextcontainer="flipped-text-container"
        paragraph={root.ProfessionalVideoProduction.paragraph}
        list={root.ProfessionalVideoProduction.points}
      />
      <ValueProposition
        image={root.SoundDesign.image}
        // isOntop={true}
        title={root.SoundDesign.title}
        paragraph={root.SoundDesign.paragraph}
        list={root.SoundDesign.points}
      />
    </React.Fragment>
  );
}
