import { createTheme } from "@mui/material";

const colorPallets = {
  primary: {
    main: "#f05c24",
  },
  secondary: {
    main: "#f1b71c",
  },
  gray: {
    main: "#272727",
  },
  black: {
    main: "#000000",
    100: "#17181d",
  },
  white: {
    main: "#ffffff",
  },
};

export const themeTerawe2024 = createTheme({
  palette: { ...colorPallets },
  body: {
    "*::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },

    "*::-webkit-scrollbar-track": {
      WebkitBorderRadius: 10,
      borderRadius: 10,
      transition: "background-color 0.75s ease",
      "&:hover": {
        backgroundColor: "rgba(240, 240, 240, 0.2)",
      },
    },
    "*::-webkit-scrollbar-thumb": {
      WebkitBorderRadius: 10,
      borderRadius: 10,
      backgroundColor: "transparent",
      transition: "background-color 0.5s ease",
      "&:hover": {
        backgroundColor: "rgb(149,149,149)",
      },
      "&:active": {
        backgroundColor: "rgb(192, 192, 192)",
      },
    },
    "*::-webkit-scrollbar-thumb:vertical": {
      transition: "background-color 0.5s ease",
    },
    "&:hover::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(192, 192, 192)",
    },
    fontSize: 14,
    "@media (min-width: 768px)": {
      fontSize: 16,
    },
    fontFamily: [
      // 'poppins',
      //'sans-serif'
    ].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "inherit",
    textmd: {
      fontSize: "0.875rem",
      lineHeight: "normal",
    },
    titlesm: {
      fontSize: "1rem",
      lineHeight: "normal",
    },
    titlelsm: {
      fontSize: "1.1rem",
      lineHeight: "normal",
    },
    titlemd: {
      fontSize: "1.5rem",
      lineHeight: "normal",
    },
    
    titlelmd : {
      fontSize : "1.8rem",
      lineHeight : "normal"
    },
    titlelg: {
      fontSize: "2.5rem",
      lineHeight: "normal",
    },
    titlexl: {
      fontSize: "3rem",
      lineHeight: "normal",
    },
    error : {
      fontSize: "1rem",
      lineHeight: "normal",
      color : "red"
    }
  },
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
        backdrop: {
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colorPallets.secondary.main,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderBottom: "2px solid",
            borderBottomColor: "#626262 !important",
            borderRadius: 0,
          },
          ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "white",
            },
          ".MuiInputLabel-root": {
            color: "white",
          },
          ".MuiInputLabel-root.Mui-focused": {
            paddingBottom: "20px",
            color: "white",
          },
          ".MuiInputBase-root": {
            borderBottom: "2px solid white",
          },
          ".MuiInputBase-input": {
            color: "white",
          },
        },
      },
    },
  },
  overrides: {
    MuiTypography: {
      h4: {
        fontSize: "30px",
        fontWeight: "500",
      },
    },

    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "#424e58",
          // borderRadius: "30px 0px 0px 30px",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#424e58",
            // borderRadius: "30px 0px 0px 30px",
          },
        },
      },
      button: {
        borderRadius: "5px 5px 5px 5px",
        "&:hover": {
          backgroundColor: "#424e58",
          color: "#fff",
        },
      },
    },
  },
});
