import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { API_KIND, BASE_URL, formKeys, orgId } from "../../utils/config";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

import {
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useController, useForm } from "react-hook-form";


export const Fields = ({
  type,
  width,
  setError,
  isContactForm = false,
  defaultValue = "",
  disable = false,
  ...props
}) => {
  const rules = {
    required: {
      value: props.required,
      message: `${props?.name} is a required field`,
    },
  };

  if (props?.validation) {
    rules["pattern"] = {
      value: new RegExp(props?.validation?.regex),
      message: props?.validation?.message,
    };
  }

  const validateFile = (value) => {
    const file = value[0];
    if (!file) return true;
    const validFormats = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const isValidFormat = validFormats.includes(file.type);
    const isValidSize = file.size <= 5 * 1024 * 1024; // 5 MB
    return isValidFormat && isValidSize;
  };

  let validationsValue = {
    ...props,
    rules,
  };

  const { field } = useController({ ...validationsValue });

  switch (type) {
    case "TEXT":
      return (
        <TextField
          {...field}
          variant="standard"
          className="custom-textfield"
          label={props.cstmlabel}
        />
      );
    case "DISABLED":
      return (
        <TextField
          value={defaultValue}
          variant="standard"
          className="custom-textfield"
          label={props.cstmlabel}
        />
      );

    case "EMAIL":
      return (
        <TextField
          {...field}
          variant="standard"
          className="custom-textfield"
          label={props.cstmlabel}
        />
      );
    case "NUMBER":
      return (
        <TextField
          {...field}
          variant="standard"
          type="number"
          onWheel={(e) => e.target.blur()}
          className="custom-textfield"
          label={props.cstmlabel}
        />
      );
    case "FILE":
      return (
        <TextField
          variant="standard"
          type="file"
          onWheel={(e) => e.target.blur()}
          className="custom-textfield inputFile"
          onChange={(e) => {
            const file = e.target.files;
            if(validateFile(file)){
              field.onChange(file[0]);
            }
            else {
              setError(props.name, {
                type: "manual",
                message: "Invalid file format or size (max 5MB, allowed: PDF, DOC, DOCX)",
              });
            }
          }}
        />
      );

    case "TEXT_AREA":
      return (
        <TextField
          className="text-area-outline"
          multiline
          {...field}
          rows={4}
          variant="standard"
          label={props.cstmlabel}
        />
      );
    default:
      return <></>;
  }
};

const Contactus = ({ handleSnackOpen, handleClose }) => {
  const [formObjct, setFormObject] = useState(null);
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);

  const refreshReCaptcha = false;
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {},
    mode: "onChange"
  });

  const onSubmit = (data) => {
    // CAPTHAToken  {
    //   required : false
    // }
    
    setIsLoadingSubmission(true);
    try {
      axios
        .post(BASE_URL, {
          kind: API_KIND.submitForm,
          data: {
            orgId: orgId,
            formKey: formKeys.contactForm,
            formData: {
              ...data,
              captchaToken: token,
            },
          },
        })
        .then((res) => {
          handleSnackOpen();
          setIsLoadingSubmission(false);
          handleClose();
        })
        .catch((err) => {
          setIsLoadingSubmission(false);
          console.error(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(BASE_URL, {
        kind: API_KIND.webForm,
        data: {
          formKey: formKeys.contactForm,
          orgId: orgId,
        },
      })
      .then((res) => {
        setFormObject(res.data);
        var formFields = res.data?.data?.content?.fields;
        reset(formFields.filter(ele => ele.type !== "CAPTCHA" || ele.hidden !== true).reduce((acc, ele) => {
          acc[ele.key] = ele.type !== "FILE" ? "" : null;
          return acc;
        }, {}));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }, [reset]);

  return (
    <Fragment>
      <h5 className="parent-modal-title">Send us a Mail</h5>
      <GoogleReCaptchaProvider
        reCaptchaKey={"6LfOrvUpAAAAAJS1_A4Vs6dSjk6HTF0xZJZBAH0l"}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
          {isLoading ? (
            <div className="align-block-middle">
              <CircularProgress />
            </div>
          ) : (
            formObjct?.data &&
            Object.values(formObjct?.data?.content?.fields).map((item, index) => {
              return (
                <div className="field-container" key={index.toString()}>
                  <Fields
                    control={control}
                    name={item.key}
                    isContactForm={true}
                    required={item.required}
                    cstmlabel={item.label}
                    validation={item.validation}
                    type={item.type}
                  />
                  {errors[item.key] && (
                    <Typography variant="error">
                      {errors[item.key].message}
                    </Typography>
                  )}
                </div>
              );
            })
          )}
          {!isLoading && (
            <div className="form-submit-btn">
              <button className="button-bordered-white" type="submit">
                <span className="inner z-index--2 relative">
                  {isLoadingSubmission ? (
                    <CircularProgress size={25} />
                  ) : (
                    "Submit"
                  )}
                </span>
                <span className="hover"></span>
              </button>

              <GoogleReCaptcha
                className="google-recaptcha-custom-class"
                onVerify={(token) => {
                  setToken(token);
                }}
                refreshReCaptcha={refreshReCaptcha}
              />
            </div>
          )}
        </form>
      </GoogleReCaptchaProvider>
    </Fragment>
  );
};

export default Contactus;
