import ourApproach360 from "../../assets/img/aboutUs/ourApproach360.webp";

import customerExperience from "../../assets/img/aboutUs/whoweare/customerexperience.png";
import Brainstorm from "../../assets/img/aboutUs/whoweare/customersolution.png";
import growArrow from "../../assets/img/aboutUs/whoweare/productivityimprovement.png";
import companiesbg from "../../assets/img/aboutUs/whoweare/CompanIES.png";
import customerbg from "../../assets/img/aboutUs/whoweare/CUSTOMERS.png";
import CultureDiversityYouthProgramSupport from "../../assets/img/aboutUs/CommunityYouthPrograms.png";

import CocoZeng from "../../assets/img/aboutUs/CultureDiversity-CocoZeng.webp";
import Alexandre from "../../assets/img/aboutUs/CultureDiversity-Alexandre.webp";
import Sheena from "../../assets/img/aboutUs/CultureDiversity-Sheena.webp";

import CorporateCultureIcon from "../../assets/img/aboutUs/CorporateCultureIcon.webp";
import SocialMissionIcon from "../../assets/img/aboutUs/SocialMissionIcon.webp";
import CultureDiversityCommunityHealthWellness from "../../assets/img/aboutUs/CultureDiversity-CommunityHealthWellness.webp";

import outstorymain from "../../assets/img/aboutUs/whoweare/AboutUs_WhoWeAre_OurStory.png";
import ManageXCustomerStoriesIrelandDepartmentofEducation from "../../assets/img/aboutUs/ManageXCustomerStoriesIrelandDepartmentofEducation.webp";
import officeimage from "../../assets/img/aboutUs/officeimage.jpg";
import sectionbg from "../../assets/img/aboutUs/CultureDiversity_DEI.jpg";
import sectionbg2 from "../../assets/img/aboutUs/CultureDiversity_CorporateCulture.png";
import sectionbg3 from "../../assets/img/aboutUs/CultureDiversity_SocialMission.jpg";

import DifferencesMakeUsStronger from "../../assets/img/aboutUs/DiversityEquityAndInclusionIcon.webp";
import Mission from "../../assets/img/Digital4Security/D4U-Mission.jpg";
import Purpose from "../../assets/img/Digital4Security/Purpose.jpg";
import Secure from "../../assets/img/Digital4Security/Secure.png";
import logo1 from "../../assets/img/logos/4Site-Hospital.png";
import logo2 from "../../assets/img/logos/4Site-StudentWellbeing.png";
import WorkingAtTerawe from "../../assets/img/aboutUs/CultureDiversity-WorkingAtTerawe-CanDo.png";
import OurDEICommitment from "../../assets/img/aboutUs/CultureDiversity-OurDEICommitment.jpg";
import Achievement from "../../assets/img/aboutUs/CultureDiversity-AchievementThruEnrichment.jpg";
import GlobalLiteracyInitiative from "../../assets/img/aboutUs/CultureDiversity-GlobalLiteracyInitiative.jpg";
import GlobalLiteracyInitiative01 from "../../assets/img/aboutUs/CultureDiversity-GlobalLiteracyInitiative-Inset01.jpg";
import GlobalLiteracyInitiative02 from "../../assets/img/aboutUs/CultureDiversity-GlobalLiteracyInitiative-Inset02.jpg";
import UWMedicineSeal from "../../assets/img/logos/UWMedicineSeal.png";
import FeedingAmericaSeal from "../../assets/img/logos/FeedingAmericaSeal.png";
import LakeWashingtonSchools from "../../assets/img/logos/LakeWashingtonSchools.png";
// leadership
import Anil from "../../assets/img/aboutUs/Anil.jpg";
import Ram from "../../assets/img/aboutUs/Ram.jpg";
import Steven from "../../assets/img/aboutUs/Steven.jpg";
import Mathew from "../../assets/img/aboutUs/Mathew.png";
import Bradley from "../../assets/img/aboutUs/Brad.jpg";
// maps

import {
  D4BAI,
  D4BARVR,
  D4BCloud,
  D4BData,
  D4BDevOps,
  D4BHPC,
  D4BHybrid,
  D4BIoT,
  Toourcommunity,
  Toourcustomer,
  Toouremployees,
  Toourenvironment
} from "../UiComponents/Icons";

export const AboutUsObject = {
  whoWeAre: {
    helmet: [
      {
        title: "Terawe Corp | Managed IT Services for Cloud, Edge and IoT ",
        description:
          "Terawe is a leading cloud platform service provider of AI, Edge & IoT for managed IT business solutions.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "We Are Terawe",
            "image": "",
            "description": "Terawe is a leading cloud platform service provider of AI, Edge & IoT for managed IT business solutions.",
            "brand": "Terawe"
          }`
      }
    ],
    pageTitle: {
      heading: "We Are Terawe",
      Title: " We Are Terawe | Terawe Corporation",
      paragraph:
        "We bring our customers’ vision to life using innovative technology solutions, solving their business challenges and driving efficiencies.",
      link: "/about-us"
    },
    outStory: {
      title: "Our Story",
      paragraph: [
        "Terawe was born with the mission to help organizations achieve their desired outcomes through strategic technology innovation and efficient implementation.",
        'Growing organically since 2013, Terawe (pronounced "TERRA-WAY“) got its start by building and leveraging subject matter expertise for emerging and disruptive technology platforms, such as data analytics, artificial intelligence, cloud computing, and alternate realities. Applying that expertise to pioneer some of technology’s most innovative programs and process models, Terawe quickly emerged as a trusted, certified technology partner for today’s most complex impact areas of digital transformation.',
        "Innovation is in our DNA.  Rather than follow trails set by others, we go where there is no trail and leave a path behind.  By establishing early, up-front agreement on dependencies of the desired outcome(s) and the goalposts of engagement, we are the ‘guide by your side’ along your own journey of innovation and achievement."
      ],
      image: outstorymain
    },
    ourUnique: {
      title: "Our Unique Value",
      paragraph: [
        "We build immersive customer experiences – mobile and social – bridging Cloud, IoT, Analytics and Intelligence. We work with some of the largest companies in the world, who leverage our experience and expertise to create Services and Solutions across a wide range of industries. We pride ourselves on being at the very forefront of what is Next and on the quality of our customer relationships."
      ]
    },
    whatWeDo: {
      title: "What We Do",
      points: [
        {
          image: customerExperience,
          title: "Customer Experiences",
          paragraph:
            "We bring our customers' visions to life by solving business objectives with cutting edge technologies, innovations and modern efficiencies."
        },
        {
          image: Brainstorm,
          title: "Customized Solutions",
          paragraph:
            "We help Enterprises embrace modern digital transformation with solutions tailored to their needs, able to scale as their business requires."
        },
        {
          image: growArrow,
          title: "Productivity Improvements",
          paragraph:
            "We empower users to become ‘great’ through modern collaboration practices and tools, which optimize workflows while reducing associated time and cost."
        }
      ]
    },

    ourCommitmentPage: {
      title: "Our Commitment Pledges",
      points: [
        {
          title: "To Our Customers",
          icon: <Toourcustomer />,
          paragraph:
            "We are committed to understanding the aspirations of our clients and delivering the solutions that enable them to achieve the outcomes they seek."
        },
        {
          title: "To Our Employees",
          icon: <Toouremployees />,
          paragraph:
            "We commit ourselves to supporting a diverse, inclusive environment that empowers employees to leverage their differences into a stronger cohesive whole."
        },
        {
          title: "To Our Community",
          icon: <Toourcommunity />,
          paragraph:
            "We are committed to supporting our local communities through youth program sponsorships, hosting educational internships, and local outreach."
        },
        {
          title: "To The Environment",
          icon: <Toourenvironment />,
          paragraph:
            "We are committed to guiding our clients towards a more sustainable future through sensible computing practices that reduce carbon footprint and minimize waste."
        }
      ]
    },
    buisnessSnapShot: {
      title: "Business Snapshots",
      sections: [
        {
          title: "Clientele",
          image: customerbg,
          points: [
            {
              title: "End Users",
              value: "8M+"
            },
            {
              title: "Industries",
              value: "12+"
            },
            {
              title: "Nations",
              value: "30+"
            }
          ]
        },
        {
          title: "Customer Engagements",
          image: companiesbg,
          points: [
            {
              title: "Successfully Competed Projects",
              value: "1000+"
            },
            {
              title: "Business Clients Globally",
              value: "150+​"
            },
            {
              title: "Indirect and direct cloud revenue (USD) generated",
              value: "100M+"
            }
          ]
        }
      ]
    },
    ourApproach: {
      title: "Our Approach: 360 degree holistic engagement",
      image: ourApproach360,
      paragraph: [
        "Business Assessment and Technical Solution Alignment",
        "Opportunity and ROI modelling",
        "Implement solutions tailored to customer needs",
        "Assist with production enablement + DevOps",
        "Augment with long-term managed services"
      ]
    },
    OurLocations: {
      title: "Our Locations"
    },
    leadership: [
      {
        name: "Anil Balakrishnan",
        designation: "Founder & CEO",
        image: Anil,
        link: "https://www.linkedin.com/in/anilbalakrishnan/"
      },
      {
        name: "Ram Peruvemba",
        designation: "CTO",
        image: Ram,
        link: "https://www.linkedin.com/in/ram-peruvemba-4218a019/"
      },
      {},
      {
        name: "Steven Duggan",
        designation: "VP INTERNATIONAL",
        image: Steven,
        link: "https://www.linkedin.com/in/duggansteven/"
      },
      {
        name: "Mathew Abraham",
        designation: "VP INDIA",
        image: Mathew,
        link: "https://www.linkedin.com/in/mathewabm/"
      },
      {
        name: "Bradley Tipp",
        designation: "CGO",
        image: Bradley,
        link: "https://www.linkedin.com/in/bradtipp/"
      }
    ]
  },
  cultureAndDiversity: {
    helmet: [
      {
        title: "Terawe Corp | Managed IT Services for Cloud, Edge and IoT ",
        description:
          "Terawe is a leading cloud platform service provider of AI, Edge & IoT for managed IT business solutions.",
        schema: `
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "Culture & Diversity",
              "image": "",
              "description": "Terawe is a leading cloud platform service provider of AI, Edge & IoT for managed IT business solutions.",
              "brand": "Terawe"
            }`
      }
    ],
    pageTitle: {
      heading: "Culture &<br/> Diversity",
      link: "/Culture-and-Diversity",
      paragraph:
        '<span class=" titleParagraph "> MISSION: </span> <span> Unleashing innovation with unique perspectives.</span>'
    },
    teraweIsProudToFacilitate: {
      title: "",
      paragraph: [
        "Terawe is proud to facilitate a diverse, supportive, and inclusive workplace culture on a mission of accomplishment through empowerment."
      ],
      points: [
        {
          image: CorporateCultureIcon,
          title: "Diversity, Equity, & Inclusion",
          back: sectionbg,
          paragraph:
            "Our DEI philosophy starts with acknowledging that uniting individuals with different backgrounds and ethnicities makes us stronger and more capable as an organization.",
          link: "https://terawe.com/Culture-and-Diversity#cultureAndDiversity"
        },
        {
          title: "Corporate Culture",
          image: SocialMissionIcon,
          back: sectionbg2,
          paragraph:
            "We believe when employees feel their best, they perform their best. Hence we facilitate an engaging corporate culture that makes your job feel more like a rewarding contributory experience.",
          link: "https://terawe.com/Culture-and-Diversity#corporateCulture"
        },
        {
          title: "Social Mission",
          image: DifferencesMakeUsStronger,
          back: sectionbg3,
          paragraph:
            "Our social mission is a commitment to lifting up the less fortunate and disadvantaged by harnessing the power of technology to facilitate healthy, vibrant communities locally and around the world.​",
          link: "https://terawe.com/Culture-and-Diversity#socialMission"
        }
      ]
    },
    makesusStronger: {
      title: "Differences only make us stronger",
      paragraph: [
        "Everyone has a unique value they bring to the table. At Terawe, we believe that uniting people from different backgrounds, cultures, and ethnicities together towards a common purpose creates much more favorable, impactful outcomes. As digital lifestyles continue to blur cultural lines around the globe, Terawe has been – and continues to be - deeply committed to building the most diverse, equitable, and inclusive workforce as possible.",
        "As an equal opportunity employer, Terawe supports diverse hiring practices by ensuring fair, inclusive recruiting and strategies to promote a diverse employee pipeline. Employees also have various education & training initiatives available to them that help promote greater cultural awareness and understanding throughout the organization."
      ],
      image: officeimage,
      Campaign: {
        image: WorkingAtTerawe,
        paragraph:
          'Terawe is a proud supporter of the <a href="https://www.whatcanyoudocampaign.org/" target="_blank" rel="noopener noreferrer" class="anchorGreen">Campaign for Disability Employment (“CDE”),</a> a highly collaborative effort among several disability and business organizations that showcases supportive, inclusive workplaces for all workers, funded by the U.S. Department of Labor.'
      }
    },
    commitment: {
      title: "Our DEI commitment",
      image: OurDEICommitment,
      paragraph: ["<b>Terawe is deeply committed to the following:</b>"],
      list: [
        "Taking a non-discriminatory approach to establish a diverse, inclusive workplace culture that provides an equivalent degree of career growth opportunities for all employees.",
        "Upholding fair and equitable compensation practices aimed to ensure pay equity among employees regardless of their gender, race, or other differentiating characteristics.",
        "Promoting greater diversity awareness and inclusion understanding within the organization through dedicated training and education programs.",
        "Supporting initiatives from external organizations and/or local community groups focused on diversity and inclusion, such as NFP collaborations, local athletic teams, and philanthropic programs.",
        "Conducting regular evaluations of corporate progress towards greater diversity & inclusion that holds our leadership accountable for driving positive change."
      ]
    },
    Achievement: {
      title: "A Culture of Achievement through Enrichment",
      paragraph: [
        "As far back as history itself, humans have always demonstrated a strong desire to belong to something that is bigger and greater than themselves. We firmly believe that our employees are our greatest assets, and instilling this sense of ‘belonging’ provides the foundation necessary to achieve the optimum balance of work vs. personal lifestyles.",
        "Terawe supports an <b>Achievement through Enrichment</b> corporate culture that empowers employees to learn and grow into their careers while enjoying each and every moment to the fullest. As we enable our employees to build their own career opportunities through personal and technology training programs, we balance it with fun, social engagements such as cultural celebrations, morale events, annual company outings, and holiday parties.",
        "We provide an environment in which every day gives each employee something new and exciting to which they can aspire and eventually accomplish."
      ],
      image: Achievement
    },
    Literacy: {
      title: "Global Literacy Initiative",
      paragraph: [
        "We believe that literacy is the foundation for all learning. To best ensure that all children and adults have equal access to opportunity in the twenty-first century, basic literacies must include reading, writing, numeracy, and (most recently) digital literacy skills.",
        "Our global literacy initiative, launched in 2017 in partnership with Microsoft Corporation and World Vision, intends to build sustainable solutions that directly address illiteracy amongst both adults and children which are relevant, appropriate to local language and culture, which can scale to meet the size of the challenge."
      ],
      image: GlobalLiteracyInitiative,
      images: [GlobalLiteracyInitiative01, GlobalLiteracyInitiative02]
    },
    liketoWorkinTerawe: {
      pageTitle: "What’s it like to work at Terawe?",
      points: [
        {
          paragraph:
            "  “I feel empowered when I come to work every day because Terawe has put me in a position where I can make a real difference with clients and co-workers.”​",
          image: CocoZeng,
          name: "Coco Z.",
          subtitle: "UX Designer"
        },
        {
          paragraph:
            "“Working at Terawe is about excelling in a fast-paced, collaborative environment where you enjoy witnessing the positive impact that technology has on people’s lives.”​​",
          image: Alexandre,
          name: "Alexandre C.",
          subtitle: "Sales Manager"
        },
        {
          paragraph:
            "“I feel empowered when I come to work every day because Terawe has put me in a position where I can make a real difference with clients and co-workers.”​",
          image: Sheena,
          name: "Sheena D.",
          subtitle: "Operations Manager"
        }
      ]
    },
    globalliteracy: {
      title: "Global Literacy Initiative",
      paragraph: [
        "We believe that literacy is the foundation for all learning. To best ensure that all children and adults have equal access to opportunity in the twenty-first century, basic literacies must include reading, writing, numeracy, and (most recently) digital literacy skills.",
        "Our global literacy initiative, launched in 2017 in partnership with Microsoft Corporation and World Vision, intends to build sustainable solutions that directly address illiteracy amongst both adults and children which are relevant, appropriate to local language and culture, which can scale to meet the size of the challenge."
      ]
    },
    creatingoppertunityeducation: {
      title: "Creating Opportunity Through Education",
      paragraph: [
        'When education is conducted through immersive experiences, information becomes more sticky and memorable for young people, which in turn generates greater retention of information. So when the Microsoft Dream Space team approached Terawe to help get 100k Minecraft licenses distributed and registered to over 3,200 schools across Ireland for the <a href=https://education.minecraft.net/en-us/blog/irelands-future-is-mine target="_blank" rel="noopener noreferrer">Ireland: Future IS MINE Campaign</a>, we jumped at the chance',
        "<b>Terawe’s ManageX Platform</b> was leveraged to create an intuitive, simple automated method for schools to redeem authorization codes, collaborate with other schools, get direct technical support, and access community resources such as FAQs. With Terawe handling all operations and implementation logistics, program participation outperformed expectations and became an e-sports tournament broadcast on RTE, Ireland’s National Public Service Media."
      ],
      image: ManageXCustomerStoriesIrelandDepartmentofEducation
    },
    communityhealth: {
      title: "Community Health & Wellness",
      paragraph: [
        "When communities prioritize health, better outcomes tend to follow. But with our increasingly busy lifestyles between growing our careers and raising our families, prioritizing what is healthy versus what is convenient becomes a constant struggle. ​",
        "Terawe believes in promoting healthy living habits through strategic investments that promote greater quality of life through our local communities. With dedicated contributions to both the <b>Feeding America Mission</b> for combating world hunger and the <b>UW School of Medicine</b> for expanding COVID-19 treatments, Terawe was both honored and proud to actively contribute towards local community health and wellness."
      ],
      image: CultureDiversityCommunityHealthWellness,
      images: [UWMedicineSeal, FeedingAmericaSeal]
    },
    youthProgramSupport: {
      title: "Youth Program Support",
      image: CultureDiversityYouthProgramSupport,
      paragraph: [
        "Young people are our future. Whether athletic or academic, organized or casual, the programs that grow the minds and enrich the spirit of young people have nothing but upside and positive outcomes – for all of us. These are the programs that can often impact a young person’s sense of worth and confidence more than anything else.",
        "Terawe is committed to supporting the growth and education of our local youth through strategic sponsorships and investments targeting athletic leagues, educational programs, and business internships that can help young people turn their dreams and aspirations into viable career pathways."
      ],
      images: [LakeWashingtonSchools]
    }
  },
  Digital4Security: {
    heroBanner: {
      title: "Digital4Security",
      paragraph:
        "We are proud to be a contributing technology partner of the EU Digital4Security consortium, empowering businesses all over Europe with a new wave of digital resilience.",
      link: "/Digital4Security"
    },
    Mission: {
      title: "D4S’s Mission",
      paragraph: [
        "<b>To cultivate the next generation of cybersecurity leaders, empowering businesses all over Europe with a new wave of digital resilience.</b>",
        "This €20m initiative funded by Digital Europe Programme will pave the way for graduates to enter high-demand roles, driving Europe’s digital defence forward and cementing its competitive edge in a rapidly evolving global landscape."
      ],
      image: Mission,
      link: {
        text: "Learn more about Digitial4Security",
        url: "https://www.digital4security.eu/",
        target: "_blank"
      }
    },
    Purpose: {
      title: "Purpose & Scope",
      paragraph: [
        "Digital4Security’s curriculum is built on a rigorous needs analysis involving all consortium partners, ensuring that the training provided meets the highest standards of both academic rigour and industry relevance. The program combines theoretical knowledge with practical, job-ready skills that are essential for immediate and effective application in the workforce.",
        "Each module of the program will offer micro-credentials, providing participants with tangible proof of their competencies, along with industry certifications through partnerships with leading cybersecurity firms. This approach not only enhances the employability of graduates but also ensures that they are prepared to meet the specific needs of the industry head-on."
      ],
      image: Purpose
    },
    Contribution: {
      title: "Terawe’s Contribution",
      paragraph: [
        "As a pioneer in delivering transformational business outcomes leveraging cutting-edge technology platforms, we are tremendously honored to be a <b>technology partner</b> for the D4S consortium.",
        "As Digital4Security is focused on practical outcomes, our contribution will be conceiving, engineering, and supporting the technology foundation that enables all program parameters to function properly, scale to local regions, and managed through a single pane of glass. This includes using cloud, hybrid, and edge computing platforms to leverage various intelligence services, manage big data sets, and optimize application user experiences on any device, anywhere in the world."
      ],
      list: [
        {
          icon: <D4BCloud />,
          title: "Cloud"
        },
        {
          icon: <D4BAI />,
          title: "AI"
        },
        {
          icon: <D4BData />,
          title: "Data"
        },
        {
          icon: <D4BARVR />,
          title: "AR / VR"
        },
        {
          icon: <D4BDevOps />,
          title: "DevOps"
        },
        {
          icon: <D4BHPC />,
          title: "HPC"
        },
        {
          icon: <D4BIoT />,
          title: "IoT / Edge"
        },
        {
          icon: <D4BHybrid />,
          title: "Hybrid"
        }
      ]
    },
    Secure: {
      title: "Help Us Secure EU’s Digital Future",
      paragraph: [
        "To learn more about Digital4Security and how you can be part of this pioneering project, visit the project website <a href='https://www.digital4security.eu/' target='_blank'>digital4security.eu.</a>",
        "Together, we can build a cyber-secure Europe that is ready to face the challenges of tomorrow."
      ],
      image: Secure,
      list: [
        `<img src=${logo1} alt='logo1' />`,
        `<img src=${logo2} alt='logo1' />`
      ]
    }
  }
};
