import React from "react";
import InnerBanner from "../UiComponents/InnerBanner";
import banner from "../../assets/img/cloudComputing/CloudComputing_Banner.jpg";
import bannerMobile from "../../assets/img/cloudComputing/CloudComputing_Banner_Mobile.jpg";
import SpecialitiesContentObject from "./SpecialitiesContentObject";
import {
  FlipBlock,
  IconBlockVertical,
  MainTitle,
  MetaHelmet,
  ResourcesBlock,
  SolutionsBlock,
  TextCarouselSlick,
  TitledCarouselSlick
} from "../UiComponents/ContentBlocks";
import ScrollAnimation from "react-animate-on-scroll";
import { isMobile } from "react-device-detect";

export default function CloudComputing() {
  const root = SpecialitiesContentObject.CloudComputing;

  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        backgroundImage={isMobile ? bannerMobile : banner}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />
      <ScrollAnimation
        animateIn="bounceInRight"
        duration={1}
        animateOnce={true}
      >
        <IconBlockVertical
          title={root.canDo.title}
          points={root.canDo.points}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="bounceInLeft" duration={1} animateOnce={true}>
        <section className="sectionContainer bgBlack">
          <div className="container">
            <MainTitle
              title={root.keyChallenges.title}
              paragraph={root.keyChallenges.paragraph}
            />
          </div>
          <div className="container">
            {root.keyChallenges.points.map((item, index) => (
              <FlipBlock
                image={item.image}
                title={item.title}
                contentIcon={item.icon}
                key={index.toString()}
                paragraph={item.paragraph}
              />
            ))}
          </div>
        </section>
      </ScrollAnimation>
      <TitledCarouselSlick
        title={root.solutions.title}
        points={root.solutions.points}
      />
      <ScrollAnimation animateIn="fadeIn" duration={1} animateOnce={true}>
        <SolutionsBlock
          title={root.cloudSolutions.title}
          points={root.cloudSolutions.points}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="pulse" duration={1} animateOnce={true}>
        <TextCarouselSlick
          bgBlack
          slideScroll={2}
          title={root.doCloud.title}
          points={root.doCloud.points}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="headShake" duration={1} animateOnce={true}>
        <ResourcesBlock
          title={root.resources.title}
          resourceTitle={root.resources.resourceTitle}
        />
      </ScrollAnimation>
    </React.Fragment>
  );
}
