import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import teraweLogo from "../assets/img/terawelogo.png";
import { menus } from "../utils/config";
export default function InsideHeader() {
  const [focusedIndex, setFocusedIndex] = useState(0);
  const navigate = useNavigate();
  const itemRefs = useRef([]);
  const location = useLocation();
  useEffect(() => {
    // Set focus to the first item when component mounts
    itemRefs.current[0]?.focus();
  }, []);
  const handleKeyDown = (event, index) => {
    switch (event.key) {
      case "ArrowDown":
        setFocusedIndex((prevIndex) => (prevIndex + 1) % menus.length);
        break;
      case "ArrowUp":
        setFocusedIndex(
          (prevIndex) => (prevIndex - 1 + menus.length) % menus.length
        );
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    itemRefs.current[focusedIndex]?.focus();
  }, [focusedIndex]);
  return (
    <React.Fragment>
      <header className="primary view-detect viewed in-view">
        <nav className="primary">
          <div className="left-col">
            <div className="hamburger-menu cursor--reg">
              <div className="inner">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="logo">
              <button
                onClick={() => {
                  if (location.pathname === "/") {
                    window.location.reload();
                  } else {
                    navigate("/");
                  }
                }}
                id="main-logo"
                title="Terawe "
                tabIndex={0}
                ref={(el) => (itemRefs.current[0] = el)}
              >
                <img src={teraweLogo} width="180px" alt="terawe" />
              </button>
            </div>
          </div>
          <div className="right-col" id="nav-right-col">
            <div className="menu-header-menu-container">
              <ul id="menu-header-menu" className="menu">
                {menus.map((val, index) =>
                  val?.menu ? (
                    <li
                      key={val.menu + index}
                      className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-553 cursor--reg ${
                        val.customClass ? val.customClass : ""
                      }`}
                      tabIndex={0}
                      ref={(el) => (itemRefs.current[index] = el)}
                      onKeyDown={(event) => handleKeyDown(event, index)}
                    >
                      {val?.menu}
                      <ul className="sub-menu">
                        {val?.subMenu?.map((subMenu) => (
                          <li
                            key={subMenu.name}
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-567 cursor--reg"
                          >
                            <button
                              onClick={() => {
                                navigate(`/${subMenu.link}`);
                              }}
                            >
                              {subMenu.name.toUpperCase()}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    <li
                      onClick={() => {
                        navigate(`/${val.link}`);
                      }}
                      key={val.name + index}
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-553 cursor--reg"
                      tabIndex={0}
                    >
                      {val.name.toUpperCase()}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </nav>

        <div id="full-screen-menu" className="full-screen-menu view-detect">
          <div className="inner">
            <div className="container">
              <div className="col left">
                <ul className="menu primary-menu">
                  {menus.map((val, index) =>
                    val?.menu ? (
                      <li
                        key={val.menu + index}
                        className="menu-item "
                        tabIndex={0}
                        ref={(el) => (itemRefs.current[index] = el)}
                        onKeyDown={(event) => handleKeyDown(event, index)}
                      >
                        <div className="number">{"0" + Number(1 + index)}.</div>
                        <span className="cursor--menu">{val?.menu}</span>
                        <ul className="sectors-items submenu">
                          {val?.subMenu?.map((subMenu) => (
                            <li
                              key={subMenu.name}
                              id="menu-item-567"
                              className="sector-item cursor--menu"
                            >
                              <button
                                onClick={() => {
                                  navigate(`/${subMenu.link}`);
                                }}
                              >
                                {subMenu.name}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ) : (
                      <li
                        onClick={() => {
                          navigate(`/${val.link}`);
                        }}
                        key={val.name + index}
                        className="menu-item noDropdown"
                        tabIndex={0}
                      >
                        <div className="number">{"0" + Number(1 + index)}.</div>
                        <span className="cursor--menu">{val.name}</span>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}
