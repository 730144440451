import React from "react";
import SolutionsObject from "./SolutionsObject";
import GaliEyeMainBanner from "../../assets/img/solutions/GaliEyeMainBanner.jpg";
import gelieyeLogo from "../../assets/img/solutions/GaliEye-Logo.png";
import {
  MainTitle,
  MetaHelmet,
  TextCarouselSlick,
  TitledCarouselSlick,
  TitleParagraphBlock
} from "../UiComponents/ContentBlocks";
import ValueProposition from "../UiComponents/ValueProposition";
import ScrollAnimation from "react-animate-on-scroll";
import InnerBanner from "../UiComponents/InnerBanner";
import { Zoom } from "../UiComponents/Icons";

export default function DesignStudio(props) {
  const root = SolutionsObject.GaliEye;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <div className="solution-main-container">
        <InnerBanner
          patch
          backgroundImage={GaliEyeMainBanner}
          bannerTitle={root.pageTitle.heading}
          featureParagraph={root.pageTitle.paragraph}
          logo={gelieyeLogo}
        />
        <ValueProposition
          title={root.Tomorrow.title}
          image={root.Tomorrow.image}
          paragraph={root.Tomorrow.paragraph}
        />
        <ValueProposition
          title={root.innovations.title}
          isOntop={true}
          isModalImage={true}
          mainContainerClass={"main-container"}
          customConiainer={"flex-reverse "}
          customtextcontainer={`solution-gelieye ${"ctsomvalue-container"}`}
          image={root.innovations.image}
          paragraph={root.innovations.paragraph}
          iconModal={<Zoom />}
        />

        <ScrollAnimation animateIn="pulse" duration={1} animateOnce={true}>
          <TextCarouselSlick
            slideScroll={2}
            title={root.gelieyeCapabilities.title}
            points={root.gelieyeCapabilities.points}
          />
        </ScrollAnimation>
        <TitledCarouselSlick
          title={root.whatif.title}
          points={root.whatif.points}
        />
        <section className="sectionContainer">
          <div className="container">
            <MainTitle title={root.businessImpact.title} />
          </div>
          <div className="container">
            <TitleParagraphBlock points={root.businessImpact.points} />
          </div>
        </section>
        {/* 
        <section className="sectionContainer container ImageContent-wrapper">
          <ImageCustomContent
            imagecontentcstm={"imagecontentcstm"}
            customImageClass={"ImageContent-body-image"}
            title={root.businessImpact.title}
            points={root.businessImpact.points}
            image={root.businessImpact.image}
          />
        </section> */}
      </div>
    </React.Fragment>
  );
}
