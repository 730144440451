import React, { useLayoutEffect, Suspense } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./styles.scss";
import { history } from "./history";
import Layout from "./Layout";
import teraweLogo from "./assets/img/terawelogo.png";
import indexRoutes from "./routes/indexRoutes";
import CookieConsent from "react-cookie-consent";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { themeTerawe2024 } from "./utils/theme";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./Errorboundey";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};


export default function App(props) {
  return (
    <ThemeProvider theme={themeTerawe2024}>
      <BrowserRouter>
        <Wrapper>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Routes history={history}>
              <Route path="/" element={<Layout {...props} history={history} />}>
                {indexRoutes.map(({ Component, path, name }) => (
                  <Route
                    index={path === "/"}
                    path={path}
                    key={name}
                    element={
                      <Suspense
                        fallback={
                          <div className="page-transition">
                            <div className="text">
                              <img
                                src={teraweLogo}
                                width="175px"
                                alt="terawe"
                              />
                            </div>
                            <div id="main-loading__progress">
                              <CircularProgress />
                              <div id="main-loading__progress__txt">
                                Please wait while we load.
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <Component />
                      </Suspense>
                    }
                  />
                ))}
              </Route>
            </Routes>
          </ErrorBoundary>
          <CookieConsent
            location="bottom"
            // enableDeclineButton
            buttonText="Accept"
            containerClasses="CookieConsent"
            cookieName="myAwesomeCookieName2"
            buttonClasses="accept-btn"
            style={{ background: "#000" }}
            contentClasses="content-class"
            expires={150}
          >
            This site uses cookies{" "}
            <a href="/cookie-policy" target="__blank">
              Learn More
            </a>
            .
          </CookieConsent>
        </Wrapper>
      </BrowserRouter>
    </ThemeProvider>
  );
}
