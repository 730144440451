import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Contactus from "./Contactus";
import { API_KIND, BASE_URL, orgId } from "../utils/config";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Close, Facebook, LinkedIn, TaskAlt, X } from "@mui/icons-material";
import { ScrollUp } from "../Pages/UiComponents/Icons";
import PopUp from "../Pages/UiComponents/PopUp";
import { IconButton, Snackbar } from "@mui/material";
// import { PartnersLogoSlick } from "../Pages/UiComponents/ContentBlocks";
// import { FooterObject } from "./footerObject";

export default function InsideFooter() {
  const [company_data, setCompanyData] = useState(null);
  const [location, setLocation] = useState([]);
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSnack, setSnackOpen] = React.useState(false);

  const handleSnackOpen = () => {
    setSnackOpen(true);
  };

  const handleBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: makes the scroll smooth
    });
  };

  useEffect(() => {
    axios
      .post(
        BASE_URL,
        {
          kind: API_KIND.webSettings,
          data: {
            orgId: orgId
          }
        },
        {}
      )
      .then((res) => {
        setCompanyData(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        BASE_URL,
        {
          kind: API_KIND.locationList,
          data: {
            orgId: orgId
          }
        },
        {}
      )
      .then((res) => {
        setLocation(res.data.data.content);
      })
      .catch((err) => console.log(err));
  }, []);
  // scroll visibility
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  // const root = FooterObject;

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleBarClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleBarClose}
        message={
          <Box
            display={"flex"}
            gap="10px"
            alignItems={"center"}
            fontWeight={"700"}
          >
            {" "}
            <TaskAlt color={"success"} /> Submitted successfully
          </Box>
        }
        action={action}
      />
      <Modal
        open={open}
        onClose={handleClose}
        classes={{
          backdrop: "contact-us-model-backdrop"
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalContact modalContainer">
          <Close onClick={handleClose} />
          <Contactus
            handleSnackOpen={handleSnackOpen}
            handleClose={handleClose}
          />
        </Box>
      </Modal>

      {/* <PartnersLogoSlick
        title={root.partners.title}
        images={root.partners.images}
      /> */}

      <footer className="footer-primary primary dark-bg view-detect">
        <div className="container footerbody">
          <div className="footer-cta">
            <hgroup className="view-detect">
              <h2 className="title line-split">
                Ready to discuss your next project?
              </h2>
            </hgroup>

            <div
              id="contact-us-btn"
              onClick={handleOpen}
              className="button-bordered-white"
            >
              <span className="inner z-index--2 relative">Contact Us</span>
              <span className="hover"></span>
            </div>
          </div>
          <div className="footer-main">
            {/* <div className="col">
              <h6 className="footer-col-title">CALL US</h6>
              <Link
                className="phone cursor--reg"
                to={`${company_data?.content?.phone[0]}`}
              >
                {company_data?.content?.phone[0]}
              </Link>
            </div> */}
            <div className="col">
              <h6 className="footer-col-title">Email</h6>
              <Link
                className="email cursor--primary-btn"
                to={`mailto:${company_data?.content?.email[0]}`}
              >
                {company_data?.content?.email[0]}
              </Link>
            </div>
            <div className="col">
              <h6 className="footer-col-title">CONNECT WITH US</h6>
              <ul className="social-links ">
                <li className="magnet" data-dist="1.5" data-scale="true">
                  <Link
                    className="cursor--reg"
                    to={
                      company_data?.content?.socialPlatforms.find(
                        (ele) => ele.platform === "instagram"
                      )?.url
                    }
                    target="_blank"
                    title="Instagram"
                  >
                    <InstagramIcon />
                  </Link>
                </li>
                <li className="magnet" data-dist="1.5" data-scale="true">
                  <Link
                    className="cursor--reg"
                    to={
                      company_data?.content?.socialPlatforms.find(
                        (ele) => ele.platform === "facebook"
                      )?.url
                    }
                    target="_blank"
                    title="Facebook"
                  >
                    <Facebook />
                  </Link>
                </li>
                <li className="magnet" data-dist="1.5" data-scale="true">
                  <Link
                    className="cursor--reg"
                    to={
                      company_data?.content?.socialPlatforms.find(
                        (ele) => ele.platform === "x"
                      )?.url
                    }
                    target="_blank"
                    title="Twitter"
                  >
                    <X />
                  </Link>
                </li>
                <li className="magnet" data-dist="1.5" data-scale="true">
                  <Link
                    className="cursor--reg"
                    to={
                      company_data?.content?.socialPlatforms.find(
                        (ele) => ele.platform === "linkedin"
                      )?.url
                    }
                    target="_blank"
                    title="Linkedin"
                  >
                    <LinkedIn />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col">
              {/* <h6 className="footer-col-title mb-10">Location</h6> */}
              <div className="location-container">
                {location?.map((items, index) => {
                  return (
                    <div className="location-wrapper" key={index.toString()}>
                      <div className="footer-col-name">
                        <PopUp
                          text={items.name}
                          modalText={
                            items.streetAddress +
                            " " +
                            items.city +
                            " " +
                            items.state +
                            " " +
                            items.postalCode
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <div className="footer-ms-img-container">
                <img src={microsoftfooter1} alt="microsoftfooter1" />
                <img src={microsoftfooter2} alt="microsoftfooter2" />
                <img src={microsoftfooter3} alt="microsoftfooter3" />
              </div> */}
              <div className="footer-bottom">
                <ul id="menu-footer-menu-1" className="menu">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-613 cursor--reg">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1054 cursor--reg">
                    <Link to="/Terms-And-Conditions">Terms of Use</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container footer-copyright-container ">
          <div className="copyright">
            © 2024 Terawe Corp. All Rights Reserved.
            {/* | <a href="mailto:info@terawe.com">info@terawe.com</a> */}
          </div>
        </div>
      </footer>
      <div
        className="scrollUp"
        onClick={scrollToTop}
        style={{
          opacity: isVisible ? 1 : 0,
          visibility: isVisible ? "visible" : "hidden"
        }}
      >
        <ScrollUp />
      </div>
    </React.Fragment>
  );
}
