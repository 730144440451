import React from "react";
import StudiosContentObject from "./StudiosContentObject";
import InnerBanner from "../UiComponents/InnerBanner";
import bannerImage from "../../assets/img/studio/Studios-InnovationCenterMainHeader.jpg";
import ValueProposition from "../UiComponents/ValueProposition";
import { MetaHelmet } from "../UiComponents/ContentBlocks";

export default function InnovationCenter() {
  const root = StudiosContentObject.InnovationCenter;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        bannerLight={true}
        backgroundImage={bannerImage}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
      />
      <ValueProposition
        image={root.conceptualization.image}
        isOntop={true}
        title={root.conceptualization.title}
        paragraph={root.conceptualization.paragraph}
        list={root.conceptualization.points}
      />
      <ValueProposition
        image={root.userJourney.image}
        isOntop={true}
        customConiainer="flip"
        customtextcontainer="flipped-text-container"
        mainContainerClass="bgDark"
        title={root.userJourney.title}
        paragraph={root.userJourney.paragraph}
        list={root.userJourney.points}
      />
      <ValueProposition
        image={root.protoypesPOCs.image}
        isOntop={true}
        title={root.protoypesPOCs.title}
        paragraph={root.protoypesPOCs.paragraph}
        list={root.protoypesPOCs.points}
      />
      <ValueProposition
        image={root.GtmAcceleration.image}
        title={root.GtmAcceleration.title}
        customConiainer="flip"
        isOntop={true}
        customtextcontainer="flipped-text-container"
        mainContainerClass="bgDark"
        paragraph={root.GtmAcceleration.paragraph}
        list={root.GtmAcceleration.points}
      />
      <ValueProposition
        image={root.TcoModeling.image}
        isOntop={true}
        title={root.TcoModeling.title}
        paragraph={root.TcoModeling.paragraph}
        list={root.TcoModeling.points}
      />
    </React.Fragment>
  );
}
