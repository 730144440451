import React, { useEffect, useState } from "react";
import banner from "../../assets/img/Insights/Insights_MainBanner.jpg";
import { InsightsBlocks } from "../UiComponents/ContentBlocks";
import InsightContentObject, {
  InsightCategories
} from "./InsightContentObject";
import { useSearchParams } from "react-router-dom";
import InnerBanner from "../UiComponents/InnerBanner";
import { Helmet } from "react-helmet";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function Insights() {
  const root = InsightContentObject.Insight;
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");

  const handleTextInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: window.innerHeight - 250,
      behavior: "smooth" // Optional: makes the scroll smooth
    });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchValue) {
      scrollToTop();
    }
  };

  useEffect(() => {
    const currentParam = searchParams.get("category");
    if (currentParam) {
      setSearchParams({ category: currentParam });
    } else {
      setSearchParams({ category: "All" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{root.pageTitle.title}</title>
        <link rel="canonical" href={root.pageTitle.link} />
      </Helmet>
      <InnerBanner
        patch
        backgroundImage={banner}
        bannerTitle={root.pageTitle.paragraph}
      />
      <section className="sectionContainer pt-60 bgBlack">
        <div className="container">
          <div className="insight-titleTab">
            {Object.values(InsightCategories).map((item) => (
              <p
                key={item.title}
                className={`${
                  searchParams.get("category")?.toLowerCase() ===
                  item.title?.toLowerCase()
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setSearchParams({ category: item.title });
                  scrollToTop();
                }}
              >
                {item.title}
              </p>
            ))}
          </div>
          <div className="insightSearch">
            <TextField
              id="outlined-basic"
              label="Search Insights"
              variant="outlined"
              onChange={handleTextInputChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={
                      searchValue
                        ? scrollToTop
                        : (e) => {
                            e.preventDefault();
                          }
                    }
                  >
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>

        <div className="container insightsContainer">
          <InsightsBlocks
            searchInsight={searchValue}
            currentCategory={searchParams.get("category")}
            Insights={root.points}
          />
        </div>
      </section>
    </React.Fragment>
  );
}
