import React from "react";
import SolutionsObject from "./SolutionsObject";
import foursitelogo from "../../assets/img/solutions/4site.png";
import siteMainBanner from "../../assets/img/solutions/4SiteMainBanner.jpg";
import {
  ImageContent,
  MainTitle,
  MetaHelmet,
  TextCarouselSlick,
  TitledCarouselSlick
} from "../UiComponents/ContentBlocks";
import ScrollAnimation from "react-animate-on-scroll";
import InnerBanner from "../UiComponents/InnerBanner";

const Foursite = () => {
  const root = SolutionsObject.FourSite;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={siteMainBanner}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
        logo={foursitelogo}
      />
      {/* <LogoBanner
        customImageClass = {"foursitecustomLogoImage"}
        logo={foursitelogo}
        backgroundImage={siteMainBanner}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
      /> */}

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle title={root.ai4u.title} />
        </div>
        <div className="container">
          <ImageContent
            title={root.ai4u.title}
            image={root.ai4u.image}
            points={root.ai4u.paragraph}
          />
        </div>
      </section>

      <ScrollAnimation animateIn="pulse" duration={1} animateOnce={true}>
        <section className="solution-quotesCustomers">
          <TextCarouselSlick
            title={root.ethicalAI.title}
            paragraph={root.ethicalAI.paragraph}
            points={root.ethicalAI.points}
          />
        </section>
      </ScrollAnimation>

      <TitledCarouselSlick
        title={root.benefits4Site.title}
        points={root.benefits4Site.points}
      />

      {/* <section className="sectionContainer foursite-ImageContent-wrapper ImageContent-wrapper">
        <ImageCustomContent
          imagecontentcstm={"imagecontentcstm"}
          customImageClass={"ImageContent-body-image"}
          
          isBelowTextrender={true}
          image={root.benefits4Site.image}
          BelowTextrender={({ item }) => {
            return (
              <p className="usecase-container">
                Use case example:{" "}
                <a href={item.example.link}> {item.example.paragraph}</a>
              </p>
            );
          }}
        />
      </section> */}
    </React.Fragment>
  );
};

export default Foursite;
