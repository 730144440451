import Teachers from "../../assets/img/education/Education_AIImpactsEducation_Teachers.jpg";
import Students from "../../assets/img/education/Education_AIImpactsEducation_Students.jpg";
import StudentPerformanceManagement from "../../assets/img/education/Education_SolutionAreas_StudentPerformanceManagement.jpg";
import TeacherAssessment from "../../assets/img/education/Education_SolutionAreas_TeacherAssessment.jpg";
import StudentHealthWellness from "../../assets/img/education/Education_SolutionAreas_StudentHealthWellness.jpg";
import ResearchAsAService from "../../assets/img/education/Education_SolutionAreas_ResearchAsAService.jpg";
import WorkloadAcceleration from "../../assets/img/education/Education_SolutionAreas_AcademicWorkloadAcceleration.jpg";
import manageX from "../../assets/img/logos/ManageXLogo.png";
import Digital4Security from "../../assets/img/education/Digital4Security_Logo.png";
import OnlineLearning from "../../assets/img/education/Education_SolutionAreas_OnlineLearning.jpg";
import CloudWorkspaces from "../../assets/img/education/Education_SolutionAreas_CloudWorkspaces.jpg";
// logos
import GaliEye from "../../assets/img/logos/GaliEye.png";
import D4B from "../../assets/img/logos/Education_TeraweDNA_logos_D4B.png";
import Life from "../../assets/img/logos/Education_TeraweDNA_logos_Life.png";
import London from "../../assets/img/logos/Education_TeraweDNA_logos_London.png";
import feature from "../../assets/img/logos/Education_TeraweDNA_logos_feature.png";
import LogoAnomalyDetection from "../../assets/img/logos/4Site-AnomalyDetection.png";
import Churn from "../../assets/img/logos/4Site-Churn.png";
import Hospital from "../../assets/img/logos/4Site-Hospital.png";
import Inventory from "../../assets/img/logos/4Site-Inventory.png";
import Predictive from "../../assets/img/logos/4Site-Predictive.png";
import StudentWellbeing from "../../assets/img/logos/4Site-StudentWellbeing.png";
import UrbanInsight from "../../assets/img/logos/4Site-UrbanInsight.png";
import DocumentIntelligence from "../../assets/img/logos/4Site-DocumentIntelligence.png";
// Finance
import EnhancedDecisionMaking from "../../assets/img/Finance/Finance_SolutionAreas_EnhancedDecisionMaking.jpg";
import ImprovedCustomerExperiences from "../../assets/img/Finance/Finance_SolutionAreas_ImprovedCustomerExperiences.jpg";
import FraudDetection from "../../assets/img/Finance/Finance_SolutionAreas_FraudDetection.jpg";
import ProcessAutomation from "../../assets/img/Finance/Finance_SolutionAreas_ProcessAutomation.jpg";
import RiskManagement from "../../assets/img/Finance/Finance_SolutionAreas_RiskManagement.jpg";
import PersonalizedFinancialAdvice from "../../assets/img/Finance/Finance_SolutionAreas_PersonalizedFinancialAdvice.jpg";
import RegulatoryCompliance from "../../assets/img/Finance/Finance_SolutionAreas_RegulatoryCompliance.jpg";
import ProductInnovation from "../../assets/img/Finance/Finance_SolutionAreas_ProductInnovation.jpg";
import Banking from "../../assets/img/Finance/Finance_Banking.jpg";
import CapitalMarkets from "../../assets/img/Finance/Finance_CapitalMarkets.jpg";
import Insurance from "../../assets/img/Finance/Finance_Insurance.jpg";
// Vehicle Engineering
import VehicleDifferentiation from "../../assets/img/transportation/Vehicle_Differentiation.jpg";
import SmartCharging from "../../assets/img/transportation/Vehicle_SolutionAreas_SmartCharging.jpg";
import IntelligentFleet from "../../assets/img/transportation/Vehicle_SolutionAreas_IntelligentFleet.jpg";
import BatteryOptimization from "../../assets/img/transportation/Vehicle_SolutionAreas_BatteryOptimization.jpg";
import MobilityAsAService from "../../assets/img/transportation/Vehicle_SolutionAreas_MobilityAsAService.jpg";
import DesignVisualization from "../../assets/img/transportation/Vehicle_SolutionAreas_3DDesignVisualization.jpg";
import DigitalTwins from "../../assets/img/transportation/Vehicle_SolutionAreas_DigitalTwins.jpg";
import CrashTestSimulation from "../../assets/img/transportation/Vehicle_SolutionAreas_CrashTestSimulation.jpg";
import VirtualDrivingAssistant from "../../assets/img/transportation/Vehicle_SolutionAreas_VirtualDrivingAssistant.jpg";
import ModernizedAppsWithAI from "../../assets/img/transportation/Transportation_SolutionAreas_ModernizedAppsWithAI.jpg";
import SupplyChainTransformation from "../../assets/img/transportation/Transportation_SolutionAreas_SupplyChainTransformation.jpg";
// Healthcare
import PatientParadigm from "../../assets/img/Healthcare/Healthcare_PatientParadigm.jpg";
import PersonalizedCare from "../../assets/img/Healthcare/Healthcare_SolutionAreas_PersonalizedCare.jpg";
import PrecisionCare from "../../assets/img/Healthcare/Healthcare_SolutionAreas_PrecisionCare.jpg";
import HealthTeamInsights from "../../assets/img/Healthcare/Healthcare_SolutionAreas_HealthTeamInsights.jpg";
import RemoteWorkspaces from "../../assets/img/Healthcare/Healthcare_SolutionAreas_RemoteWorkspaces.jpg";
import AcceleratedGenomics from "../../assets/img/Healthcare/Healthcare_SolutionAreas_AcceleratedGenomics.jpg";
import CancerScreening from "../../assets/img/Healthcare/Healthcare_SolutionAreas_CancerScreening.jpg";
import MedicalSkilling from "../../assets/img/Healthcare/Healthcare_SolutionAreas_MedicalSkilling.jpg";
import HealthcareDigitalTwins from "../../assets/img/Healthcare/Healthcare_SolutionAreas_DigitalTwins.jpg";
import CapacityPlanning from "../../assets/img/Healthcare/Healthcare_SolutionAreas_CapacityPlanning.jpg";
// Energy
import AcceleratedResearch from "../../assets/img/Energy/Energy_SolutionAreas_AcceleratedResearch.jpg";
import PredictiveMaintenance from "../../assets/img/Energy/Energy_SolutionAreas_PredictiveMaintenance.jpg";
import RealTimeSafetyMonitoring from "../../assets/img/Energy/Energy_SolutionAreas_RealTimeSafetyMonitoring.jpg";
import EnergySmartCharging from "../../assets/img/Energy/Energy_SolutionAreas_SmartCharging.jpg";
import SmartGrids from "../../assets/img/Energy/Energy_SolutionAreas_SmartGrids.jpg";
import AppScaling from "../../assets/img/Energy/Energy_SustainableFuture_AppScaling.jpg";
import CarbonCaptureStorage from "../../assets/img/Energy/Energy_SustainableFuture_CarbonCaptureStorage.jpg";
import EnergyAnomalyDetection from "../../assets/img/Energy/Energy_SolutionAreas_AnomalyDetection.jpg";
import WhyPartnerWithUs from "../../assets/img/Energy/Energy_WhyPartnerWithUs.jpg";
// Manufacturing
import UnlockInnovation from "../../assets/img/Manufacturing/Manufacturing_Industry4.0_UnlockInnovation.jpg";
import AgileFactories from "../../assets/img/Manufacturing/Manufacturing_Industry4.0_AgileFactories.jpg";
import SupplyChain from "../../assets/img/Manufacturing/Manufacturing_Industry4.0_SupplyChain.jpg";
import Img01 from "../../assets/img/Manufacturing/Manufacturing_Challenges_Img01.jpg";
import Img02 from "../../assets/img/Manufacturing/Manufacturing_Challenges_Img02.jpg";
import Img03 from "../../assets/img/Manufacturing/Manufacturing_Challenges_Img03.jpg";
import Img04 from "../../assets/img/Manufacturing/Manufacturing_Challenges_Img04.jpg";
import DesignAcceleration3D from "../../assets/img/Manufacturing/Manufacturing_SolutionAreas_3DDesignAcceleration.jpg";
import DataProcessingEdge from "../../assets/img/Manufacturing/Manufacturing_SolutionAreas_DataProcessingEdge.jpg";
import IntelligentAssetTracking from "../../assets/img/Manufacturing/Manufacturing_SolutionAreas_IntelligentAssetTracking.jpg";
import IotDigitalTwins from "../../assets/img/Manufacturing/Manufacturing_SolutionAreas_IotDigitalTwins.jpg";
import ManufacturingPredictiveMaintenance from "../../assets/img/Manufacturing/Manufacturing_SolutionAreas_PredictiveMaintenance.jpg";
import ManufacturingProcessAutomation from "../../assets/img/Manufacturing/Manufacturing_SolutionAreas_ProcessAutomation.jpg";
import ManufacturingRealTimeSafetyMonitoring from "../../assets/img/Manufacturing/Manufacturing_SolutionAreas_RealTimeSafetyMonitoring.jpg";
// Retail
import IntelligentRetail from "../../assets/img/Retail/Retail_IntelligentRetail.jpg";
import Customer360 from "../../assets/img/Retail/Retail_SolutionAreas_Customer360.jpg";
import FrictionlessCheckout from "../../assets/img/Retail/Retail_SolutionAreas_FrictionlessCheckout.jpg";
import RetailIntelligentAssetTracking from "../../assets/img/Retail/Retail_SolutionAreas_IntelligentAssetTracking.jpg";
import RetailRealTimeSafetyMonitoring from "../../assets/img/Retail/Retail_SolutionAreas_RealTimeSafetyMonitoring.jpg";
import SmartShelves from "../../assets/img/Retail/Retail_SolutionAreas_SmartShelves.jpg";
import VirtualMirror from "../../assets/img/Retail/Retail_SolutionAreas_VirtualMirror.jpg";
// Government
import AutomatedCitizenServices from "../../assets/img/Government/Govt_Opportunities_AutomatedCitizenServices.jpg";
import CrossAgencyCollaboration from "../../assets/img/Government/Govt_Opportunities_CrossAgencyCollaboration.jpg";
import LimitlessAnalytics from "../../assets/img/Government/Govt_Opportunities_LimitlessAnalytics.jpg";
import RemoteWork from "../../assets/img/Government/Govt_Opportunities_RemoteWork.jpg";
import ApplicationModernization from "../../assets/img/Government/Govt_SolutionAreas_ApplicationModernization.jpg";
import DocumentDigitization from "../../assets/img/Government/Govt_SolutionAreas_DocumentDigitization.jpg";
import Govt_ProcessAutomation from "../../assets/img/Government/Govt_SolutionAreas_ProcessAutomation.jpg";
import PublicSafety from "../../assets/img/Government/Govt_SolutionAreas_PublicSafety.jpg";
import SearchandDiscovery from "../../assets/img/Government/Govt_SolutionAreas_SearchandDiscovery.jpg";
import ChangeImage from "../../assets/img/Government/Govt_SolutionAreas_ApplicationModernization.jpg";
import ChangeManagementIcon from "../../assets/img/icons/Government/ChangeManagement.png";
import CitizenEngagement from "../../assets/img/icons/Government/CitizenEngagement.png";
import DataSecurity from "../../assets/img/icons/Government/DataSecurity.png";
import DigitalInclusion from "../../assets/img/icons/Government/DigitalInclusion.png";
import EthicalConsiderations from "../../assets/img/icons/Government/EthicalConsiderations.png";
import Infrastructure from "../../assets/img/icons/Government/Infrastructure.png";
import Interoperability from "../../assets/img/icons/Government/Interoperability.png";
import LimitedBudget from "../../assets/img/icons/Government/LimitedBudget.png";
import Performance from "../../assets/img/icons/Government/Performance.png";
import RegulatoryComplianceIcon from "../../assets/img/icons/Government/RegulatoryCompliance.png";
import Scalability from "../../assets/img/icons/Government/Scalability.png";
import TalentExpertise from "../../assets/img/icons/Government/Talent&Expertise.png";
// PublicSector
import Estonia from "../../assets/img/PublicSector/Govt_TransformedGovt_Estonia.jpg";
import NewYork from "../../assets/img/PublicSector/Govt_TransformedGovt_NewYork.jpg";
import Singapore from "../../assets/img/PublicSector/Govt_TransformedGovt_Singapore.jpg";
import CitizenSafety from "../../assets/img/PublicSector/Govt_Opportunities_CitizenSafety.jpg";
import EconomicDevelopment from "../../assets/img/PublicSector/Govt_Opportunities_EconomicDevelopment.jpg";
import GovtAdministration from "../../assets/img/PublicSector/Govt_Opportunities_GovtAdministration.jpg";
import LegalRegulatoryServices from "../../assets/img/PublicSector/Govt_Opportunities_LegalRegulatoryServices.jpg";
import PublicEducation from "../../assets/img/PublicSector/Govt_Opportunities_PublicEducation.jpg";
import PublicTransport from "../../assets/img/PublicSector/Govt_Opportunities_PublicTransport.jpg";
import SustainableCities from "../../assets/img/PublicSector/Govt_Opportunities_SustainableCities.jpg";
import UrbanDevelopment from "../../assets/img/PublicSector/Govt_Opportunities_UrbanDevelopment.jpg";
import CitizenSecurity from "../../assets/img/PublicSector/Govt_SolutionAreas_CitizenSecurity.jpg";
import GovtIntelligentAssetTracking from "../../assets/img/PublicSector/Govt_SolutionAreas_IntelligentAssetTracking.jpg";
import IntelligentTransport from "../../assets/img/PublicSector/Govt_SolutionAreas_IntelligentTransport.jpg";
import SmartCities from "../../assets/img/PublicSector/Govt_SolutionAreas_SmartCities.jpg";
import SmartFleet from "../../assets/img/PublicSector/Govt_SolutionAreas_SmartFleet.jpg";
import SustainableServices from "../../assets/img/PublicSector/Govt_SolutionAreas_SustainableServices.jpg";
// Communications
import OurApproach from "../../assets/img/Telecommunications/Comm_OurApproach.jpg";
import ChurnPrediction from "../../assets/img/Telecommunications/Comm_SolutionAreas_ChurnPrediction.jpg";
import EnhancedSecurity from "../../assets/img/Telecommunications/Comm_SolutionAreas_EnhancedSecurity.jpg";
import CommFraudDetection from "../../assets/img/Telecommunications/Comm_SolutionAreas_FraudDetection.jpg";
import IoTEdgeManagement from "../../assets/img/Telecommunications/Comm_SolutionAreas_IoTEdgeManagement.jpg";
import NetworkOptimization from "../../assets/img/Telecommunications/Comm_SolutionAreas_NetworkOptimization.jpg";
import PersonalizedExperiences from "../../assets/img/Telecommunications/Comm_SolutionAreas_PersonalizedExperiences.jpg";
import CommPredictiveMaintenance from "../../assets/img/Telecommunications/Comm_SolutionAreas_PredictiveMaintenance.jpg";
import SmartNetwork from "../../assets/img/Telecommunications/Comm_SolutionAreas_SmartNetwork.jpg";
import PartnerWithUs from "../../assets/img/Telecommunications/Comm_PartnerWithUs.jpg";
import CloudServiceProvider from "../../assets/img/Telecommunications/Telecommunications_SolutionAreas_CloudServiceProvider.jpg";
// Icons
import {
  CommunicationIcon1,
  CommunicationIcon2,
  CommunicationIcon3,
  CommunicationIcon4,
  CommunicationIcon5,
  CommunicationIcon6,
  CommunicationIcon7,
  GovtChellangeData,
  GovtChellangeFinancial,
  GovtChellangeOrganizational,
  GovtChellangePublictrust,
  GovtChellangeRegulatory,
  GovtChellangeTechnical,
  ManageServiceIcon,
  TransformingRetail1,
  TransformingRetail2,
  TransformingRetail3,
  TransformingRetail4,
  TransformingRetail5,
  TransformingRetail6,
  TransformingRetail7
} from "../UiComponents/Icons";

const IndustriesObject = {
  Education: {
    helmet: [
      {
        title: "Terawe EDU | Reinventing Education with AI Technologies",
        description:
          "We leverage powerful AI technology platforms to help you better address drop-out rates, optimize resources, and create the experiences that increase retention",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Education",
            "image": "",
            "description": "We leverage powerful AI technology platforms to help you better address drop-out rates, optimize resources, and create the experiences that increase retention",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Education",
      paragraph:
        "We leverage powerful AI and cloud services to revolutionize educational use cases with more personalized learning experiences, expanded access to resources, and providing collaborative and interactive learning environments.",
      link: "/Education"
    },
    Rising: {
      title: "Rising to the Challenge",
      paragraph: [
        "We recognize the education sector is unique when it comes to adopting new technologies. While it is one of the biggest opportunity areas for the benefits provided by emerging tech, it comes with significant budget and operational limitations as well as a much longer purchasing cycle. And most academic institutions do not already have the IT expertise in-house to build and execute an emerging technology adoption strategy.",
        "Terawe fits perfectly into that paradigm because we operate on <b>data-first principles</b>. From our successful technology adoption engagements to our experience in delivering favorable academic outcomes, we provide all the necessary data points up front in order to validate and accelerate new technology adoption."
      ],
      points: [
        {
          title: "77%",
          paragraph:
            "of teachers believe AI can help them customize learning experiences",
          link: "https://www.weforum.org/agenda/2023/05/ai-accelerate-students-holistic-development-teaching-fulfilling/"
        },
        {
          title: "Up to 40%",
          paragraph:
            "of teachers’ time can be freed up by automating clerical tasks with AI",
          link: "https://www.mckinsey.com/industries/education/our-insights/how-artificial-intelligence-will-impact-k-12-teachers"
        },
        {
          title: "<10%",
          paragraph:
            "of schools and universities have formal guidance on applying AI",
          link: "https://www.unesco.org/en/articles/unesco-survey-less-10-schools-and-universities-have-formal-guidance-ai"
        }
      ]
    },
    Impacts: {
      title: "AI Impacts Education",
      paragraph:
        "The potential impact of AI on education has significant benefits from both admin/educator and student perspective(s).",
      points: [
        {
          title: "For Admins & Educators",
          image: Teachers,
          points: [
            {
              title: "Personalized Teaching",
              paragraph:
                "As AI personalizes learning for students, it can also assist educators by analyzing student abilities and histories, which helps teachers tailor their teaching methods to better meet individual needs."
            },
            {
              title: "AI-Augmented Grading",
              paragraph:
                "AI automates tasks such as grading, allowing educators to focus more on teaching and mentoring. It also provides insights into student performance, helping identify areas for improvement."
            },
            {
              title: "Continuous Improvement",
              paragraph:
                "AI can track student progress over time, enabling continuous evaluation and adjustments to teaching strategies and helping educators land subject matter more effectively with their students."
            },
            {
              title: "Student Retention",
              paragraph:
                "Schools have an inherent interest in progressing students successfully to graduation, and leveraging AI to improve their learning experiences also serves to keep them in school."
            }
          ],
          link: [
            "https://www.classpoint.io/blog/the-pros-and-cons-of-ai-in-education",
            "https://thejournal.com/articles/2021/06/23/7-benefits-of-ai-in-education.aspx",
            "https://www.technologyreview.com/2020/03/04/905535/unleashing-the-power-of-ai-for-education/"
          ]
        },
        {
          title: "For Students",
          image: Students,
          points: [
            {
              title: "Personalized Learning",
              paragraph:
                "As AI personalizes learning for students, it can also assist educators by analyzing student abilities and histories, which helps teachers tailor their teaching methods to better meet individual needs."
            },
            {
              title: "Tutoring and Support",
              paragraph:
                "AI-powered chatbots and virtual tutors can answer questions promptly and help students improve their skills in specific areas, outside of regular classroom hours."
            },
            {
              title: "Quick Responses",
              paragraph:
                "Quick Responses AI is able to address and respond to repetitive queries instantly.  Thus, students no longer need to wait days or even weeks for answers to their questions."
            },
            {
              title: "Universal 24/7 Access",
              paragraph:
                "AI tools make learning accessible anytime and anywhere. Students can access educational content at their convenience, enhancing flexibility and inclusivity."
            }
          ],
          link: ["abc"]
        }
      ]
    },
    Solution: {
      title: "Our Education Solution Areas",
      paragraph:
        "The potential impact of AI on education poses significant benefits from both admin/educator and student perspective(s).",
      points: [
        {
          title: "Student Performance Management",
          paragraph:
            "We use AI, analytics, and machine learning to assess student academic performance issues respective to curriculum module(s) and generate proactive course corrections as intelligent insights.",
          image: StudentPerformanceManagement
          // link: "https://education.minecraft.net/en-us/blog/irelands-future-is-mine"
        },
        {
          title: "Teaching Assessments",
          paragraph:
            "We use next-gen video analysis services such as emotion recognition, sentiment analysis, and gesture tracking to perform enhanced assessments of teaching behaviors, and produce intelligent recommendations for areas of improvement.",
          image: TeacherAssessment
          // link: "https://education.minecraft.net/en-us/blog/irelands-future-is-mine"
        },
        {
          title: "Student Health & Wellness",
          paragraph:
            "We proactively address overall student dropout rates by identifying those students at a high risk of dropping out, allowing the educational institution to target their efforts and resources more efficiently and to make early interventions.",
          image: StudentHealthWellness
          // link: "https://education.minecraft.net/en-us/blog/irelands-future-is-mine"
        },
        {
          title: "Research-as-a-Service",
          paragraph:
            "We empower students in academic research and lab sciences with direct access to limitless computing resources at a global scale through an intuitive, productive experience, without requiring your IT staff.",
          image: ResearchAsAService
          // link: "https://education.minecraft.net/en-us/blog/irelands-future-is-mine"
        },
        {
          title: "Academic Workload Acceleration",
          paragraph:
            "We enable university data center managers to quickly and efficiently scale on demand beyond their data center boundaries to minimize job queue wait times and furnish resources to projects more effectively.",
          image: WorkloadAcceleration
          // link: "https://education.minecraft.net/en-us/blog/irelands-future-is-mine"
        },
        {
          title: "Cloud Workspaces",
          paragraph:
            "We provide purpose-built cloud infrastructure, platforms, and services in simple, easy-to-use workspace environments for students and faculty to obtain hands-on experience with the latest technologies.",
          image: CloudWorkspaces
        },
        {
          title: "Online Learning",
          paragraph:
            "We enable end-to-end digital learning experiences by integrating Identity, LMS, CRM, and Payments with AI-enabled experiences to provide students seamless experiences throughout their lifecycle.",
          image: OnlineLearning
        }
      ]
    },
    DNA: {
      title: "Education is Terawe’s DNA",
      paragraph:
        "The education sector is the longest standing industry vertical practice at Terawe.  From our earliest days over a decade ago, we've been strategically identifying and applying the emerging tech platforms and services that effectively bolster learning systems and increase access to educational services for disadvantaged people all over the world.",
      // "Increasing both access and impact of educational systems were Terawe’s first successes",
      points: [
        {
          title: "Digital 4 Business",
          paragraph:
            "Together with our consortium partners, we are reshaping the European digital landscape with a new online masters program that merges academia and industry.",
          image: D4B,
          backgroundColor: "white",
          link: "https://digital4business.eu/"
        },
        {
          title: "Design Future London",
          paragraph:
            "We're helping young Londoners (ages 5-24) develop their confidence and skills through a challenge to design what they think the future of London could and should be.",
          image: London,
          backgroundColor: "pink",
          link: "https://www.london.gov.uk/programmes-strategies/planning/digital-planning/design-future-london"
        },
        {
          title: "The Future is MINE",
          paragraph:
            "We enabled 3,300 K-8 public schools with the logistical & operational tools necessary for all students to participate in the Republic of Ireland’s Future Is Mine STEM program.",
          image: feature,
          backgroundColor: "green",
          link: "https://education.minecraft.net/en-us/blog/irelands-future-is-mine"
        },
        {
          title: "Literacy For Life",
          paragraph:
            "We launched the Lit4Life Global Literacy Program with Microsoft and World Vision, aiming to build sustainable solutions to address illiteracy amongst both adults and children. ",
          image: Life,
          backgroundColor: "gray",
          link: "https://lit4lifeblog.azurewebsites.net/tag/lit4life"
        }
      ],
      subParagraph: [
        "In this regard, it has been a tremendous honor and a privilege  to partner with global ambassadors Microsoft, World Vision, UNESCO, the Education Authority, and European Commission to lead the technology strategy & utilization of multiple educational programs.",
        "Our ongoing strategic partnership with these organizations enables us with deep platform expertise necessary to guide you in making the right choices for the best academia outcomes."
      ],
      // partnershipImages
      d4U: {
        image: Digital4Security,
        paragraph:
          "Terawe is a proud contributing technology partner of the EU Digital4Security consortium, empowering businesses all over Europe with a new wave of digital resilience."
      }
    },
    TeraweSolutions: {
      title: "Terawe’s Education Solutions",
      points: [
        {
          paragraph:
            "A simplified and intuitive web portal that connects students, data scientists, and researchers with global-scale cloud resources instantly.",
          link: "/ManageX",
          image: manageX,
          customClass: "manageX"
        },
        {
          paragraph:
            "Using AI, analytics, and machine learning to increase both student performance and retention while promoting student wellness & mental health.",
          image: StudentWellbeing,
          link: "/4Site",
          customClass: "fourSite"
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "Time to Act on Student Mental Health",
        "Guaranteeing Secure Online Examinations during the COVID-19 Pandemic",
        "AI and Student Wellbeing"
      ]
    }
  },
  Finance: {
    helmet: [
      {
        title: "Terawe FinTech | Compliance, Risk, and Transformation",
        description:
          "Terawe is ready and able for technology consulting, proposals, and project execution to digitally transform your financial risk and compliance solutions",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Finance",
            "image": "",
            "description": "Terawe is ready and able for technology consulting, proposals, and project execution to digitally transform your financial risk and compliance solutions",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Finance",
      paragraph:
        "Let us help you achieve unprecedented efficiency and accuracy in financial decision-making by streamlining processes, mitigating risks, and uncovering valuable insights to optimize investments, enhance customer experiences, and drive sustainable growth.",
      link: "/Managed"
    },
    Opportunities: {
      title: "Opportunities for Financial Services",
      paragraph:
        "Regardless of where you specifically align across the financial services sector, technologies like AI, data analytics, and machine learning are creating new opportunities for service delivery and market differentiation.",
      points: [
        {
          title: "Banking",
          paragraph:
            "Empower your bank with AI-driven solutions to revolutionize customer experiences, risk management, and operational efficiency. By leveraging advanced algorithms and data analytics, banks can personalize services, detect fraudulent activities, and automate tedious processes, thereby enhancing profitability, compliance, and competitiveness in an increasingly digital landscape.",
          image: Banking
        },
        {
          title: "Capital Markets",
          paragraph:
            "Gain a competitive edge in capital markets through AI-driven insights that revolutionize trading strategies, minimize risks, and maximize returns. Harness the power of advanced algorithms to uncover hidden opportunities, adapt swiftly to market dynamics, and unlock unprecedented efficiency in portfolio management, ultimately driving superior performance and profitability.",
          image: CapitalMarkets
        },
        {
          title: "Insurance",
          paragraph:
            "Transform the insurance landscape with AI-powered innovations that optimize underwriting, claims processing, and risk assessment. By leveraging cutting-edge algorithms and predictive analytics, insurers can enhance accuracy, reduce fraud, and personalize offerings, ultimately fostering stronger customer relationships, improving operational efficiency, and driving sustainable profitability in an ever-evolving industry.",
          image: Insurance
        }
      ]
    },
    Challenges: {
      title: "Challenges and Risks Abound",
      paragraph:
        "Delivering financial services has become more challenging and risky in recent years, requiring financial organizations to adapt by becoming more agile and innovative while maintaining a high degree of trust and resilience with their client base.",
      points: [
        {
          title: "Cybersecurity Threats",
          paragraph:
            "With the increasing digitization of financial services, cybersecurity threats such as data breaches, ransomware attacks, and identity theft pose a significant risk to both financial institutions and their customers."
        },
        {
          title: "Operational Resilience",
          paragraph:
            "Disruptions resulting from system failures, cyberattacks, or natural disasters can jeopardize business continuity.  Ensuring operational resilience through robust infrastructure, redundancy measures, and contingency planning is essential."
        },
        {
          title: "Regulatory Compliance",
          paragraph:
            "Financial institutions must navigate complex and evolving regulatory landscapes, which can vary significantly across different jurisdictions. Non-compliance can result in substantial fines, reputational damage, and legal consequences."
        },
        {
          title: "Market Volatility",
          paragraph:
            "Fluctuations in global financial markets can impact investment returns, liquidity, and the overall stability of the financial system. Managing market risks requires sophisticated risk management strategies and tools."
        },
        {
          title: "Data Privacy and Ethics",
          paragraph:
            "The collection of vast amounts of customer data raise concerns about privacy, security, and ethical considerations. Financial institutions must prioritize data protection and transparency to comply with regulations like GDPR and CCPA."
        },
        {
          title: " Geopolitical & Economic Uncertainty",
          paragraph:
            "Geopolitical tensions, trade disputes, and economic uncertainty can impact financial markets and consumer confidence. Financial institutions must monitor such developments and their impact to investment strategy and risk management."
        },
        {
          title: "Technological Disruption",
          paragraph:
            "Rapid advancements in technology, including AI, blockchain, and fintech innovations, are reshaping the financial services landscape. Traditional institutions must adapt to stay competitive while managing their associated risks."
        },
        {
          title: "Demographic Shifts",
          paragraph:
            "Changing demographics, including aging populations and shifting consumer preferences, present challenges for financial institutions in meeting the evolving needs of their customers and planning for long-term sustainability."
        }
      ]
    },
    Solution: {
      title: "Our Finance Solution Areas",
      paragraph:
        "Terawe’s solutions offer financial institutions a unique value equation across multiple disciplines and dependencies: ",
      points: [
        {
          title: "Enhanced Decision Making",
          paragraph:
            "We apply powerful AI and machine learning services to analyze vast amounts of data and generate actionable insights for more intelligent decision-making. Financial institutions can leverage these insights to optimize investment strategies, risk management practices, and customer interactions.",
          image: EnhancedDecisionMaking
        },
        {
          title: "Improved Customer Experiences",
          paragraph:
            "We build and deploy the AI-powered chatbots, personalized recommendations, and virtual assistants that empower financial institutions to deliver custom-tailored and suitably-targeted products and services to their customers, leading to seamless user experiences and higher satisfaction.",
          image: ImprovedCustomerExperiences
        },
        {
          title: "Fraud Detection and Prevention",
          paragraph:
            "Our pre-built machine learning algorithms enable you to identify patterns and anomalies indicative of fraudulent activities, and helping detect and prevent fraud in real-time with continuous, 24/7/365 monitoring and analysis of transaction data and user behavior.",
          image: FraudDetection
        },
        {
          title: "Data Modernization for Insight",
          paragraph:
            "By transforming legacy data systems into modern, agile platforms handling large volumes of both structured and unstructured data, we enable financial institutions with the real-time analysis and visualization necessary for contextual insights that drive better decision-making.",
          image: ProcessAutomation
        },
        {
          title: "Risk Management",
          paragraph:
            "Terawe specializes in advanced analytics and predictive modeling techniques that allow financial institutions to assess and mitigate various types of risks, including credit risk, market risk, and operational risk, thereby safeguarding their financial stability and regulatory compliance.",
          image: RiskManagement
        },
        {
          title: "Personalized Financial Advice",
          paragraph:
            "We build and deploy AI-powered robo-advisors that provide personalized investment advice and portfolio management services based on individual financial goals, risk tolerance, and market conditions. This democratizes access to wealth management services, helping customers make more intelligent decisions about their finances.",
          image: PersonalizedFinancialAdvice
        },
        {
          title: "Regulatory Compliance",
          paragraph:
            "Terawe helps you reduce the burden of compliance-related tasks while minimizing the risk of non-compliance penalties by employing AI and data analytics to automate compliance processes, suspicious transaction monitoring, and ensuring consistent adherence to regulatory requirements. ",
          image: RegulatoryCompliance
        },
        {
          title: "App Modernization for Finance",
          paragraph:
            "By modernizing monolithic applications to improve performance, speed, and security, we help financial institutions offer more responsive and reliable services to their customers, integrate new technologies like infusing AI into existing app estate and ensure compliance with evolving regulatory requirements using blockchain technology.",
          image: ProductInnovation
        }
      ]
    },
    Partner: {
      title: "We Are Your FinTech Technology Partner",
      partnersImages: VirtualDrivingAssistant,
      points: [
        {
          title: "Outcomes, Not Services",
          paragraph:
            "What matters most is that you achieve what you expect from your technology investments.  Terawe is an outcome-focused partner that ensures every phase of every engagement is on track to deliver the intended results."
        },
        {
          multiple: [
            {
              title: "Compliance Top of Mind",
              paragraph:
                "Financial solutions need the agility to operate alongside a shifting regulatory landscape.  Terawe designs and implements systems to withstand changes in operational dependencies, regional restrictions, and data policies, without sacrificing performance or security."
            },
            {
              title: "Technology Ambassadors",
              paragraph:
                "Terawe pursues innovation through an ethical intent framework of designing and implementing solutions that are accountable, transparent, secure, trustworthy, equitable, and inclusive of the entire audience segment."
            },
            {
              title: "Zero-Cost Overage Guarantee",
              paragraph:
                "We are willing to guarantee your costs will not spiral above what has been discussed and agreed upon…or we will cover the cost overages."
            }
          ]
        },
        {
          title: "System Integration",
          paragraph:
            "As pioneers of some of the most widely utilized DevOps and secure engineering systems in the world today, we ensure seamless solution integration with your existing IT footprint."
        }
      ]
    },
    Financial: {
      title: "Terawe's Financial Service Solutions",
      points: [
        {
          paragraph:
            "An intuitive, secure method for financial advisors and auditors to use cloud speed & scale to accelerate computationally-intensive risk assessment applications.",
          image: manageX,
          link: "/ManageX",
          customClass: "manageX"
        },
        {
          paragraph:
            "A system of innovation that infuses intelligence services with your financial data to develop personalized, tailored offers leveraging deep customer insights. ",
          image: GaliEye,
          link: "/GaliEye",
          customClass: "galiEye"
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: ["GaliEye", "Fraud Detection", "Proposal Management"]
    }
  },
  VehicleEngineering: {
    helmet: [
      {
        title: "Terawe | Transformative AI Automotive Engineering & IT",
        description:
          "Learn how Terawe can help you get more out of your mobility service & solutions through software acceleration and intelligent process management",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Transportation",
            "image": "",
            "description": "Learn how Terawe can help you get more out of your mobility service & solutions through software acceleration and intelligent process management",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Transportation",
      paragraph:
        "Bringing together AI, machine learning, edge computing & IoT to advance autonomous driving, vehicle electrification, predictive maintenance, and smart infrastructure integration that accelerate the future of land, water, and air mobility for vehicle manufacturers.",
      link: "/Managed"
    },
    Differentiation: {
      title: "Differentiation is Necessary",
      paragraph: [
        "Staying relevant and competitive in today’s vehicle manufacturing space means embracing technological advancements that adhere closely to market expectations and user preferences. Consumers have increasing demand for sustainability, connectivity, safety, convenience...and affordability.  At the same time, governing regulations around safety and sustainability have become more stringent than ever before.  And new players have entered the marketplace, causing disruption and reshaping traditional business models.",
        "Vehicle manufacturers that fail to embrace these new technologies risk falling behind competitors and missing out on opportunities to lead the industry forward.  The truth is <b>emerging technologies can favorably impact almost every phase of the vehicle engineering process,</b> and Terawe is suited to make that happen for you. We help you future-proof your operations by investing in technologies and strategies that enable you to build flexible and adaptable production systems, invest in research and development, and foster a culture of innovation."
      ],
      image: VehicleDifferentiation
    },
    VehicleMakers: {
      title: "Top-Of-Mind for Vehicle Makers",
      paragraph:
        "If you build vehicles, its likely these have been some of the concerns you’ve been thinking about.",
      points: [
        {
          title: "Electrification Strategy",
          paragraph:
            "Manufacturers must overcome challenges related to battery technology, charging infrastructure, range anxiety, and consumer acceptance to successfully transition to electric mobility."
        },
        {
          title: "Meeting Compliance Shifts",
          paragraph:
            "Compliance with shifting, increasingly-stringent regulations on emissions, safety standards, and other protections requires vehicle makers to investment significantly in technology, research, and development."
        },
        {
          title: "Autonomous Vehicle Development",
          paragraph:
            "Developing autonomous driving technology involves navigating complex regulatory frameworks, understanding the dependencies of platform technologies, ensuring safety and reliability, and addressing ethical and legal considerations."
        },
        {
          title: "Technology Integration",
          paragraph:
            "The integration of AI, connectivity, and ADAS into vehicles poses significant challenges for vehicle makers related to compatibility, interoperability, and cybersecurity…all while having to prioritize safety and reliability."
        },
        {
          title: "Supply Chain Disruption",
          paragraph:
            "Complex global supply chains are susceptible to disruptions such as natural disasters, geopolitical tensions, trade disputes, and pandemics, and manufacturers must implement strategies to mitigate supply chain risks and ensure production continuity."
        },
        {
          title: "Changing Consumer Preferences",
          paragraph:
            "As consumers increase demand for sustainability, connectivity, and personalized experiences, vehicle makers must adapt their product offerings and business models to meet changing consumer expectations and preferences."
        }
      ]
    },
    Solution: {
      title: "Our Transportation Solution Areas",
      points: [
        {
          title: "Modernized Apps with AI",
          paragraph:
            "By modernizing legacy apps to adopt event-driven patterns, we enable logistics companies to utilize real-time data from IoT sensors to make more intelligent decisions without latency.  We also leverage Generative AI to predict more optimal routes, identify potential delays in advance, and automate responses to unforeseen events.",
          image: ModernizedAppsWithAI
        },
        {
          title: "Intelligent E-Fleet",
          paragraph:
            "We ensure that your fleet of vehicles incorporates the necessary technologies to optimize efficiency, performance, and sustainability.  We help integrate cloud and container-based AI with IoT sensors at the Edge to reduce environmental impacts, save costs, integrate with grids, and with flexible global scaling.",
          image: IntelligentFleet
        },
        {
          title: "Battery Optimization",
          paragraph:
            "We place heavy emphasis on the primary technology upon which EVs are based – the battery.  And because optimized batteries ensure consistent performance and maximizes EV driving ranges, we strive to help our clients balance power output, energy capacity, and vehicle weight to deliver an optimal driving experience.",
          image: BatteryOptimization
        },
        {
          title: "Mobility-as-a-Service",
          paragraph:
            "We integrate various forms of transportation services and experiences into a single, accessible service that provides users with a convenient way to plan, book, and pay for their entire journey using a combination of public transit, ridesharing, biking, car rental, and other modes of transport.",
          image: MobilityAsAService
        },
        {
          title: "3D Design Visualization",
          paragraph:
            "We help you strategize and implement a design engineering process that encompasses Edge, IoT devices, and cloud-based AI platforms to empower your abilities to create and engineer new vehicle designs more effectively, leading to the development of safer, more efficient, and innovative vehicles.",
          image: DesignVisualization
        },
        {
          title: "Digital Twins",
          paragraph:
            "By creating digitized virtual replicas (“digital twins”) of mechanical components for use in your design, test, and validation processes, we help vehicle makers save an enormous amount of cost and resources while accelerating their time to market with lower error and defect rates. ",
          image: DigitalTwins
        },
        {
          title: "Crash Test Simulation",
          paragraph:
            "By matching highly-scalable HPC+AI infrastructure with  <a href='https://lsdyna.ansys.com/' target='_blank'>LS-DYNA</a>, <a href='https://altair.com/radioss/' target='_blank'>Radioss</a>, and/or <a href='https://www.3ds.com/products/simulia/abaqus' target='_blank'>Abaqus</a> applications, we help you accelerate your crash test simulations to build safer vehicles to scale, comply with regulatory requirements, optimize designs for cost and performance, and innovate in terms of vehicle safety features. ",
          image: CrashTestSimulation
        },
        {
          title: "Virtual Driving Assistant",
          paragraph:
            "Driving assistance enhances safety, improves user experiences, and increases efficiencies.  Terawe has both the virtual agent development experience and working knowledge of IoT devices at the Edge to help you implement an assisted driving experience that align to your needs and preferences.",
          image: VirtualDrivingAssistant
        },
        {
          title: "Supply Chain Transformation",
          paragraph:
            "We help shipping and transport companies adopt the event-driven architecture necessary to ensure real-time data processing and decision-making, which in turn enables supply chains to react swiftly to shifts in demand, supply disruptions, and other unforeseen events.  This not only improves efficiency but reduces cost but also enhance resilience to compete better in a dynamic market.",
          image: SupplyChainTransformation
        }
      ]
    },
    Partner: {
      title: "What Differentiates Us",
      partnersImages: VirtualDrivingAssistant,
      points: [
        {
          title: "We Focus on What Matters",
          paragraph:
            "What matters most is that you achieve what you expect from your technology investments.  Terawe is an outcome-focused partner that ensures every phase of every engagement is on track to deliver the intended results."
        },
        {
          multiple: [
            {
              title: "Goalposts with Guarantees",
              paragraph:
                "We are willing to guarantee your costs will not spiral above what has been discussed and agreed upon…or we will cover the cost overages."
            },
            {
              title: "Dedicated HPC Practice",
              paragraph:
                "Terawe is one of very few technology partners with a dedicated HPC practice in-house, helping simplify and accelerate adoption for complex application scenarios."
            },
            {
              title: "Customized Support Tiers",
              paragraph:
                "We embrace the specific needs and expectations our clients’ customers have, and use that to build customer support packages and options that directly address those needs and deliver the most supportive experiences possible."
            }
          ]
        },
        {
          title: "IoT, Edge, and Digital Twins",
          paragraph:
            "Terawe has deep expertise and practical working knowledge of the pivotal technologies that vehicle makers are using to differentiate themselves.  Let us put that advantage to work for you."
        }
      ]
    },
    Engineering: {
      title: "Terawe’s Transportation Solutions",
      points: [
        {
          paragraph:
            "A simple & secure method for 3D industrial designers to instantly access global-scale HPC resources for their CFD and FEA application workloads",
          image: manageX,
          link: "/ManageX",
          customClass: "manageX"
        },
        {
          paragraph:
            "A predictive analytics tool using AI and ML automatically detect and identify engineering and/or build anomalies before any process disruption",
          image: LogoAnomalyDetection,
          link: "/4Site",
          customClass: "fourSite"
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "An Alternate Approach to Anomaly Detection",
        "Predictive Maintenance",
        "The Ubiquity of Scale-Up and Scale-Out Infrastructures for HPC and AI"
      ]
    }
  },
  Healthcare: {
    helmet: [
      {
        title: "Terawe Healthcare | AI, Cloud & IoT Solutions for Research",
        description:
          "Benefit from more advanced research, better patient-in-care hospital services, and optimized clinical trial management With Terawe's machine learning models.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Healthcare",
            "image": "",
            "description": "Benefit from more advanced research, better patient-in-care hospital services, and optimized clinical trial management With Terawe's machine learning models.",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Healthcare",
      paragraph:
        "Building cutting-edge technology solutions enabling healthcare providers and caregivers to shift a model of identifying & treating symptoms to one that promotes healthy lifestyles and greater long-term wellness. ",
      link: "/Managed"
    },
    Patient: {
      title: "The New Patient Paradigm ",
      paragraph: [
        "The healthcare industry is moving towards a <b>new patient paradigm</b>, one that proactively strives for preventative, connected, and continuous health and wellness. Private and secure services are absolute essentials, but those services need to be agile and available at a moment's notice. While improving services and managing costs are constant concerns, the opportunity to revolutionize patient care is too significant to ignore. ",
        "<b>Terawe healthcare solutions</b> deliver better, deeper insights, thereby empowering both health organizations and patients themselves to produce better outcomes. Our Cloud Commitment Principles guarantee privacy, security and compliance. Additionally, our analytics and machine learning offerings support advanced research, simulations, social listening and clinical trial management. Lastly, our collaboration services empower clinical teams to collaborate productivity and efficiently, making early detection and accurate intervention standard operating practices. "
      ],
      image: PatientParadigm
    },
    Application: {
      title: "AI is the Healthcare Application ",
      paragraph:
        "Modernized healthcare transformational shift towards patient-centered, digitally-enabled healthcare that aims to improve access, quality, and outcomes while empowering patients to actively participate in their health and care journey. ",
      points: [
        {
          title: "$188B ",
          paragraph:
            "Expected size of the global healthcare AI market is by 2030 ",
          link: "https://radixweb.com/blog/ai-in-healthcare-statistics"
        },
        {
          title: "320% ",
          paragraph:
            "Expected ROI to be realized within fourteen months for every $1.00 invested in AI ",
          link: "https://www.grandviewresearch.com/industry-analysis/artificial-intelligence-ai-healthcare-market"
        },
        {
          title: "$380B ",
          paragraph:
            "Estimated savings in US healthcare spending within the next five years resulting from wider adoption of AI ",
          link: "https://www.forbes.com/sites/joemckendrick/2023/02/22/healthcare-may-be-the-ultimate-proving-ground-for-artificial-intelligence/"
        },
        {
          title: "79% ",
          paragraph:
            "Approximate percentage of healthcare organizations already utilizing AI technology ",
          link: "https://www.grandviewresearch.com/industry-analysis/artificial-intelligence-ai-healthcare-market"
        }
      ]
    },
    Challenges: {
      title: "Significant Challenges Are In The Way ",
      paragraph:
        "Navigating the challenges of transforming healthcare requires innovation, collaboration, and an appetite to change. ",
      points: [
        {
          title: "Data Security and Privacy ",
          paragraph:
            "With digitization of health records and proliferation of connected devices, ensuring the security and privacy of patient data is critical for healthcare providers. "
        },
        {
          title: "Rising Costs ",
          paragraph:
            "Healthcare providers are under pressure to deliver quality care while managing their costs, driven by an aging population, chronic disease, and technological advances. "
        },
        {
          title: "Shift to Value-Based Care ",
          paragraph:
            "The growing focus on improving patient outcomes requires providers to adopt new payment models, collaborate across healthcare settings, and prioritize preventive care and population health management. "
        },
        {
          title: "Workforce Shortages ",
          paragraph:
            "Shortages of healthcare physicians, nurses, and allied health workers can strain existing resources and impact the quality of care delivered. "
        },
        {
          title: "Health Inequities ",
          paragraph:
            "Addressing disparities in socioeconomic status, race, ethnicity, and geography requires a concerted effort from healthcare providers, policymakers, and community organizations. "
        },
        {
          title: "Regulatory Compliance ",
          paragraph:
            "In the most heavily-regulated industries in the world, healthcare providers must stay compliant to multiple levels of industry regulations at the local, national, and international levels. "
        },
        {
          title: "Consumer Expectations ",
          paragraph:
            "Healthcare providers must adapt to meet today’s patient expectations of  convenience, transparency, and personal care experiences without compromising care quality or exorbitant cost. "
        }
      ]
    },
    Solution: {
      title: "Our Healthcare Solution Areas ",
      points: [
        {
          title: "Connected Personalized Care ",
          paragraph:
            "We help improve the effectiveness, efficiency, and safety of medical care by tailoring each instance of care provisioning to the unique characteristics of each individual patient, bringing together decisions, treatments, practices, and products into one immersive care experience. ",
          image: PersonalizedCare
        },
        {
          title: "Precision Care ",
          paragraph:
            "Using alternate realty technologies blended with AI and high performance computing, we assist doctors and surgeons with highly detailed, 3D visualizations that help reduce the invasiveness of surgical procedures while optimizing the process ",
          image: PrecisionCare
        },
        {
          title: "Health Team Insights ",
          paragraph:
            "We use big data analytics and machine learning to produce contextual insights and intelligent recommendations that empower the care team with greater abilities to develop new service models, advance drug discovery, determine product utilization patterns, and clinical trial management. ",
          image: HealthTeamInsights
        },
        {
          title: "Remote Workspaces ",
          paragraph:
            "We synergize and integrate a variety of value-add services into the Microsoft Teams collaboration hub, such as Cognitive, Bot Services, and Sharepoint, to produce a chat-based secure collaborative virtual workspace for remote health care members and teams to huddle, share ideas, manage processes, and execute initiatives. ",
          image: RemoteWorkspaces
        },
        {
          title: "Accelerated Genomics ",
          paragraph:
            "We can help process your large-scale sequencing data, genome assembly, and bioinformatics applications on demand, using the fastest supercomputers in the world today - without changing your fundamental workflow or escalating things to your IT team. ",
          image: AcceleratedGenomics
        },
        {
          title: "Cancer Screening ",
          paragraph:
            "We use AI-powered cognitive services such as intelligent image segmentation and object classification to empower earlier diagnoses of cancer growths and subsequent clinical interventions, resulting in vastly improved outcomes as well as elongated lifespans. ",
          image: CancerScreening
        },
        {
          title: "Medical Skilling ",
          paragraph:
            "We help increase the efficacy and effectiveness of your medical training & skilling programs by leveraging AI services such as Sentiment Analysis and Emotion Recognition to assess performance and produce intelligent insights. ",
          image: MedicalSkilling
        },
        {
          title: "Digital Twins ",
          paragraph:
            "Using 3D virtual modeling technologies with data generated from IoT devices running at the Edge, we help reduce the invasiveness of surgical procedures by giving doctors a working virtual model for analysis and strategy. ",
          image: HealthcareDigitalTwins
        },
        {
          title: "Capacity Planning ",
          paragraph:
            "We use AI, machine learning and predictive analytics to help hospital managers better predict and prepare for the peaks and troughs of care demand, enabling a shift from reactive to proactive management, and facilitating continuous capacity planning. ",
          image: CapacityPlanning
        }
      ]
    },
    Terawe: {
      title: "Terawe’s Healthcare Solutions",
      points: [
        {
          image: manageX,
          paragraph:
            "A simple & secure method to instantly access global-scale HPC resources for drug discovery and medical research initiatives ",
          link: "/ManageX",
          customClass: "manageX"
        },
        {
          image: GaliEye,
          paragraph:
            "A system of innovation that infuses intelligence services with health & medical data to develop plans of wellness ",
          link: "/GaliEye",
          customClass: "galiEye"
        },
        {
          image: Hospital,
          paragraph:
            "Using predictive analytics to help hospital managers and clinicians better prepare for the ebbs and flows of care demand ",
          link: "/4Site",
          customClass: "fourSite"
        }
      ]
    },
    Partner: {
      title: "Our Differentiators in Healthcare ",
      partnersImages: VirtualDrivingAssistant,
      points: [
        {
          title: "Plans of Wellness vs. Paths of Illness ",
          paragraph:
            "We operate on the premise that healthcare should be a proactive means of facilitating healthy living through greater wellness management, versus reacting to ailments by identifying symptoms and disease treatment. "
        },
        {
          multiple: [
            {
              title: "Zero-Cost Overage Guarantees ",
              paragraph:
                "We hold ourselves accountable to our cost projections to the point where we will cover any cost overruns out of our own pocket. "
            },
            {
              title: "Rare, Unique HPC+AI Expertise ",
              paragraph:
                "We have working knowledge and experience in using the cloud’s most powerful, scalable infrastructure augmented with the fastest GPU and FPGA processors, to help accelerate your most complex application workloads. "
            },
            {
              title: "Custom Support Tiers ",
              paragraph:
                "We build customer support packages and options that directly address our clients’ needs and deliver the most supportive experiences possible. "
            }
          ]
        },
        {
          title: "IoT, Edge, and Digital Twins ",
          paragraph:
            "Terawe already possesses practical, working knowledge of emerging tech platforms with key use case scenarios for healthcare that our competitors are just learning about. "
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "Preventing Social Welfare Fraud during the COVID-19 Pandemic",
        "Time to Act on Student Mental Health",
        "Reactive to Proactive bed management with Data & AI"
      ]
    }
  },
  Energy: {
    helmet: [
      {
        title: "Terawe Energy | Sustainable, Clean, and Smart Solutions",
        description:
          "Let us help you execute a sensible strategy to smart energy solutions that lean on renewable and sustainable resources for intelligent energy management",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Energy",
            "image": "",
            "description": "Let us help you execute a sensible strategy to smart energy solutions that lean on renewable and sustainable resources for intelligent energy management",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Energy",
      paragraph:
        "We help energy companies adapt to changing market dynamics, regulatory frameworks, and consumer preferences while managing risks and seizing opportunities associated with the transition to cleaner and more renewable energy sources. ",
      link: "/Managed"
    },
    Powering: {
      title: "Powering A Sustainable Future ",
      paragraph: [
        "Now is the time for energy companies to design & implement systems and processes that minimize negative environmental impacts and promote sustainable development. But how easy is it to create and maintain systems that meet the needs of present generations without compromising the ability of future generations to meet their own needs?  By integrating sustainability into engineering practices, Terawe can help you create a more environmentally responsible and resilient future. "
      ],
      image: SmartCharging
    },
    Transitioning: {
      title: "Challenges with Transitioning ",
      paragraph:
        "Energy companies face several challenges in today's sustainable world as they navigate the transition towards cleaner and more renewable energy sources ",
      points: [
        {
          title: "Workforce Skills Gap ",
          paragraph:
            "Attaining necessary, specialized expertise in renewable energy technologies, grid management, energy efficiency, and sustainability practices can be challenging for employee recruitment and retention initiatives. "
        },
        {
          title: "Transition to Renewables ",
          paragraph:
            "Transitioning energy resources to renewables requires significant investment in new infrastructure, technologies, and business models - requiring careful planning and adaptation to changing market dynamics and regulations. "
        },
        {
          title: "Grid Integration ",
          paragraph:
            "Integrating renewable energy into existing grids poses technical challenges related to grid stability, reliability, and flexibility, for which energy companies must invest in grid upgrades, energy storage solutions, and smart grid technologies. "
        },
        {
          title: "Social & Environment ",
          paragraph:
            "Significant social & environmental concerns related to land use, water usage, wildlife protection, and community engagement need priority attention to earn public trust and maintain a social license for normal operations. "
        },
        {
          title: "Regulation Uncertainty ",
          paragraph:
            "The regulatory environment for energy is constantly evolving to address climate change and environmental concerns, creating uncertainty about the potential impact long-term investment decisions and business strategies. "
        },
        {
          title: "Market Pressures ",
          paragraph:
            "Energy companies face increasing competition from renewable energy developers and independent producers, while at the same time, consumers are more environmentally conscious and demand cleaner energy options. "
        }
      ]
    },
    Energy: {
      title: "What We Can Do for Energy Companies ",
      paragraph:
        "Terawe’s value to energy companies is tailored specific to each specialty area within the energy sector. ",
      section: "Common Processes & Workflows",
      offer: "What We Can Offer",
      points: [
        {
          title: "Exploration and Production ",
          paragraph:
            "Resource discovery, drilling, extraction, and production involving seismic surveys, drilling operations, and well completions ",
          offer: [
            "App acceleration for upstream seismic processing & reservoir modeling ",
            "Remote well monitoring with IoT & the Edge "
          ]
        },
        {
          title: "Refining and Petrochemicals ",
          paragraph:
            "Resource processing into refined products such as gasoline, diesel, jet fuel, and petrochemical feedstock. ",
          offer: [
            "Robotic process automation (RPA) ",
            "Predictive maintenance ",
            "Data analytics for refinement optimization "
          ]
        },
        {
          title: "Renewable Energy ",
          paragraph:
            "Production of sustainable energy resources such as solar, wind, hydroelectric, geothermal, and biomass. ",
          offer: [
            "App acceleration for CFD and WRF ",
            "Location planning & optimization ",
            "Remote operation management "
          ]
        },
        {
          title: "Electricity Generation ",
          paragraph:
            "Generation, transmission, and distribution of electricity of energy to residential, commercial, and industrial consumers ",
          offer: [
            "Smart city planning & management ",
            "IoT & Edge for power station monitoring ",
            "Predictive maintenance to avoid downtime "
          ]
        },
        {
          title: "Storage & Grid Management ",
          paragraph:
            "Operation and optimization of electric power grids to ensure reliability, stability, and efficiency. ",
          offer: [
            "Containerized AI at the Edge for storage optimization ",
            "Data analytics for intelligent grid management "
          ]
        },
        {
          title: "Efficiency & Conservation ",
          paragraph:
            "Technologies, policies, and practices aimed at minimizing energy waste and maximizing energy productivity. ",
          offer: [
            "Energy usage insights & trends over time ",
            "Intelligent conservation recommendations ",
            "Smart energy device development "
          ]
        },
        {
          title: "Trading & Marketing ",
          paragraph:
            "Buying, selling, and exchanging energy commodities such as crude oil, natural gas, electricity, and renewable energy credits. ",
          offer: [
            "Predictive analytics for intelligent decision-making on energy investments",
            "Real-time market updates & notifications "
          ]
        },
        {
          title: "Consulting & Pro Services ",
          paragraph:
            "Advisory services, technical expertise, and strategic guidance to energy companies, governments, and other stakeholders. ",
          offer: [
            "Remote workstations at the Edge ",
            "Intelligent fleet operations ",
            "Virtual workspaces for collaboration "
          ]
        },
        {
          title: "Research & Development ",
          paragraph:
            "Development of new technologies, materials, and processes to improve energy efficiency, reduce emissions, and advance renewable energy sources. ",
          offer: [
            "App acceleration for molecular and chemical research ",
            "Remote monitoring with IoT & the Edge "
          ]
        }
      ]
    },
    Solution: {
      title: "Our Solution Areas for Energy ",
      points: [
        {
          title: "Anomaly Detection ",
          paragraph:
            "We extract and generate valuable insights into the performance, reliability, and security of energy infrastructure, helping you make more informed decisions, mitigate risks, and improve operational efficiencies. ",
          image: EnergyAnomalyDetection
        },
        {
          title: "Application Scaling ",
          paragraph:
            "Upstream energy applications for seismic processing and reservoir modeling (such as <a href='https://www.mathworks.com/products/matlab.html' target='_blank' /> MATLAB</a>, <a href='https://www.software.slb.com/products/eclipse' target='_blank' />ECLIPSE</a>, and <a href='https://www.slb.com/products-and-services/delivering-digital-at-scale/software/petrel-subsurface-software/petrel' target='_blank' />PETREL</a>) are heavily parallelized. Instead of migrating  those apps to the cloud, Terawe brings cloud-scale and computational power into your application without disrupting your workflow or requiring IT resources. ",
          image: AppScaling
        },
        {
          title: "Carbon Capture & Storage (CCS) ",
          paragraph:
            "We help energy producers advance their CCS initiatives by using our ManageX cloud abstraction service, combined with geospatial intelligence and AI, to simulate the scenarios that minimize CO₂ emissions from their industrial processes and power plants. ",
          image: CarbonCaptureStorage
        },
        {
          title: "Smart Charging ",
          paragraph:
            "Terawe uses AI, advanced communication protocols and control technologies to optimize EV charging infrastructure, based on various factors such as grid conditions, electricity demand, renewable energy availability, and user preferences, putting energy companies ahead of the EV curve. ",
          image: EnergySmartCharging
        },
        {
          title: "Smart Grids ",
          paragraph:
            "We help energy companies integrate with existing grids using advanced metering infrastructure (AMI), grid automation, and demand response systems that enable real-time monitoring, control, and optimization of grid operations, allowing for better management of intermittent renewable energy generation and demand fluctuations.",
          image: SmartGrids
        },
        {
          title: "Predictive Maintenance ",
          paragraph:
            "We leverage analytics and machine learning from data captured by IoT devices on the Edge to conduct heavy machinery performance & operational monitoring that helps energy companies optimize their operations while reducing downtime and minimizing maintenance costs. ",
          image: PredictiveMaintenance
        },
        {
          title: "Real-Time Safety Monitor ",
          paragraph:
            "We offer a fully managed IoT Gateway Appliance that uses compute and hardware-accelerated machine learning at your Edge locations to monitor video streams for safety violations real-time, to guard against and anticipate potential explosions, and to provide exploratory analysis for future strategies. ",
          image: RealTimeSafetyMonitoring
        },
        {
          title: "Accelerated Research ",
          paragraph:
            "As energy companies research & innovate new ways to improve their efficiencies, reduce emissions, and advance renewable energy sources, Terawe brings the high performance computation and optimization technologies that make these efforts productive, efficient, and comprehensive. ",
          image: AcceleratedResearch
        }
      ]
    },
    Terawe: {
      title: "Terawe’s Energy Solutions",
      points: [
        {
          image: manageX,
          paragraph:
            "A simple & secure method to instantly access global-scale HPC resources for seismic processing & reservoir modeling apps ",
          link: "/ManageX",
          customClass: "manageX"
        },
        {
          image: GaliEye,
          paragraph:
            "Intuitive insights that reduce risk and provide intelligent recommendations for your sustainable energy investment ",
          link: "/GaliEye",
          customClass: "galiEye"
        },
        {
          image: Predictive,
          paragraph:
            "Pre-trained ML models that predict and avoid potentially crippling mechanical problems before they become an issue. ",
          link: "/4Site",
          customClass: "fourSite"
        }
      ]
    },
    Partner: {
      title: "Why Partner With Us for Energy?",
      partnersImages: WhyPartnerWithUs,
      points: [
        {
          title: "Multi-Disciplined Expertise ",
          paragraph:
            "Terawe is unique in that we have working experience in both upstream and downstream areas of the energy sector, which helps you move forward with confidence and a 360-degree solution perspective. "
        },
        {
          multiple: [
            {
              title: "Zero-Cost Coverage Guarantees ",
              paragraph:
                "We hold ourselves accountable to our cost projections to the point where we will cover any cost overruns out of our own pocket. "
            },
            {
              title: "Converged HPC+Cloud ",
              paragraph:
                "Seismic processing and reservoir modeling are extremely complex, computationally-intensive applications, and we have experience in cloud-enabling those scenarios to accelerate and optimize results.            "
            },
            {
              title: "We Know The Edge ",
              paragraph:
                "Edge computing plays a pivotal role in energy transformation, and Terawe is currently one of the top recommended partners for Edge computing applications and use cases. "
            }
          ]
        },
        {
          title: "Custom Support Tiers ",
          paragraph:
            "We build customer support packages and options that directly address our clients’ needs and deliver the most supportive experiences possible. "
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "An Alternate Approach to Anomaly Detection",
        "Predictive Maintenance",
        "Right-Focusing Your Research with HPC as a Managed Service"
      ]
    }
  },
  Manufacturing: {
    helmet: [
      {
        title: "Terawe Corp | Industrial Consulting & Engineering Services",
        description:
          "Managed IT operations and cloud services for digital twins, crash test simulation, vehicle design engineering, and predictive maintenance",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Manufacturing",
            "image": "",
            "description": "Managed IT operations and cloud services for digital twins, crash test simulation, vehicle design engineering, and predictive maintenance",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Manufacturing",
      paragraph:
        "Helping manufacturers increase their efficiencies and minimizing downtimes while improving quality to maintain their competitiveness in a rapidly evolving global marketplace. ",
      link: "/Managed"
    },
    Industry: {
      title: "Industry 4.0 ",
      paragraph:
        "The fourth industrial revolution promises a transformative shift in manufacturing processes through the integration of digital technologies. It combines cyber-physical systems, IoT, AI, machine learning and cloud computing to create smart factories capable of autonomous operation, real-time data analysis, and adaptive production.  Manufacturers willing to invest in these more efficient operational systems hold the promise of revolutionizing manufacturing with much greater efficiency, improved product quality, an increase to their market agility, and supporting more innovation to legacy processes and tactics…ultimately leading to more sustainable growth and competitiveness. ",
      points: [
        {
          title: "Operate safe and agile factories ",
          paragraph:
            "Build the productive, smart factory of the future that minimizes downtimes and optimizes efficiencies with industrial IoT, cloud, AI, and mixed reality. ",
          image: UnlockInnovation
        },
        {
          title: "Create more resilient supply chains ",
          paragraph:
            "Improve supply chain visibility, greater inventory dependability, and increased market agility through intelligent planning and execution, demand sensing, and traceability.",
          image: SupplyChain
        },
        {
          title: "Unlock innovation and new services ",
          paragraph:
            "Accelerating the engineering of new business value and promoting greater innovation across your organization using digital feedback loops and digital twins.",
          image: AgileFactories
        }
      ]
    },
    Challenges: {
      title: "Challenges With Transitioning ",
      paragraph:
        "Manufacturers wanting to remain competitive and profitable require a holistic approach that encompasses strategic planning, investment in technology and talent, collaboration with industry partners, and a commitment to continuous learning and improvement. ",
      points: [
        {
          title: "Legacy Systems Integration ",
          paragraph:
            "Heavy reliance on legacy systems can make new system integration complex and require careful planning to ensure smooth operations. ",
          icon: <CommunicationIcon1 />
        },
        { image: Img01 },
        {
          title: "Data Management and Security ",
          paragraph:
            "Managing and analyzing data effectively and securely present significant challenges for organizations with stringent regulatory requirements. ",
          icon: <CommunicationIcon2 />
        },
        { image: Img02 },
        {
          title: "Change Management ",
          paragraph:
            "The changes that come with transitioning can be met with resistance from employees accustomed to traditional ways of working. ",
          icon: <CommunicationIcon3 />
        },
        { image: Img03 },
        {
          multiple: [
            {
              title: "Skills Gap ",
              paragraph:
                "Finding and retaining the talent necessary to administer and manage digital technologies is challenging, especially in regions where there is a shortage of qualified professionals. ",
              icon: <CommunicationIcon4 />
            },
            {
              title: "Cybersecurity Risks ",
              paragraph:
                "Threats such as hacking, malware, and ransomware attacks require manufacturers to implement robust cybersecurity measures",
              icon: <CommunicationIcon5 />
            }
          ]
        },
        { image: Img04 },
        {
          multiple: [
            {
              title: "Interoperability ",
              paragraph:
                "Ensuring the interoperability necessary for seamless data exchange and collaboration can be difficult due to the proliferation of proprietary standards and technologies. ",
              icon: <CommunicationIcon6 />
            },
            {
              title: "Regulatory Compliance ",
              paragraph:
                "Ensuring compliance with constantly shifting regulations while undergoing digital transformation adds significant complexity to the process.",
              icon: <CommunicationIcon7 />
            }
          ]
        }
      ]
    },
    Manufacturers: {
      title: "What We Can Do for Manufacturers",
      paragraph:
        "The value we bring to manufacturers is specific to each and every part of the manufacturing process.",
      section: "Common Processes & Workflows",
      offer: "What We Can Offer",
      points: [
        {
          title: "Design & Development ",
          paragraph:
            "New product concepts, creating specifications, industrial design, prototyping for refinement and adjustment, and research for product improvement ",
          offer:
            "3D remote visualization, cloud-scale acceleration for CFD and FEA apps, and IoT & digital twins for simulated prototyping "
        },
        {
          title: "Raw Material Sourcing ",
          paragraph:
            "Supplier identification & selection, material procurement, and quality control ",
          offer:
            "Intelligent search for optimized supplier qualification, image & object recognition for quality control process automation "
        },
        {
          title: "Production Planning ",
          paragraph:
            "Determining production capacities, planning the production timelines and resource allocation, and managing inventory levels ",
          offer:
            "AI-powered predictive analytics for optimized planning and maximizing inventories consistently to demand curve "
        },
        {
          title: "Manufacturing",
          paragraph:
            "Transforming materials into components or finished products through assembly, fabrication, and quality assurance. ",
          offer:
            "Cloud-scale acceleration for design applications, predictive maintenance for optimized operations, and process automation for QC "
        },
        {
          title: "Finishing ",
          paragraph:
            "Ensuring products have a finished appearance and meet cleanliness regulatory standards, including finalized coatings and packaging ",
          offer:
            "Cloud-scale acceleration for CFD and design engineering applications, process automation for coating application & packaging "
        },
        {
          title: "Quality Control & Testing ",
          paragraph:
            "Monitoring and inspecting products during and after production process, and performing functional performance testing ",
          offer:
            "Automated quality control, 3D simulation engineering acceleration, data analytics for improving testing procedural efficacy "
        },
        {
          title: "Logistics & Distribution ",
          paragraph:
            "Storing finished products, shipping products to customers, and managing transportation and delivery logistics to final destination(s). ",
          offer:
            "Intelligent inventory analytics, optimized quality control, predictive maintenance and intelligent routing for smart fleet "
        },
        {
          title: "Maintenance & Support ",
          paragraph:
            "Equipment maintenance with repairs as needed to ensure optimal performance, post-sales support and service to customers. ",
          offer:
            "Predictive maintenance for optimized operations, data-driven proactive support tactics, 360-degree customer experiences "
        },
        {
          title: "Continuous Improvement ",
          paragraph:
            "Implementing more sustainable principles, continuously analyzing and improving manufacturing processes, and incorporating feedback ",
          offer:
            "Green engineering practices, intelligent CI/CD cycling, data analytics for feedback quantification, and remote collaboration "
        }
      ]
    },
    Solution: {
      title: "Our Solution Areas for Manufacturing",
      points: [
        {
          title: "Intelligent Asset Tracking ",
          paragraph:
            "We use spatial anchors combined with IoT sensors, RFID tags, Bluetooth Low Energy (BLE), and GPS services all in concert to help manufacturers better monitor and manage their equipment, inventories, and other valuable assets to improve efficiencies, reduce losses, and optimize operations. ",
          image: IntelligentAssetTracking
        },
        {
          title: "Predictive Maintenance ",
          paragraph:
            "We use data analytics and IoT sensors to detect anomalies in equipment performance and identify possible defects in processes, thereby predicting the optimal maintenance timing allowing for convenient scheduling of corrective maintenance and preventing unexpected equipment failures. ",
          image: ManufacturingPredictiveMaintenance
        },
        {
          title: "Data Processing at the Edge ",
          paragraph:
            "We help manufacturers become more agile and nimble by analyzing and processing their data at the same point that data is being generated, allowing for instantaneous responses to critical inputs, lower latencies, reduced bandwidth requirements, and overall enhanced reliabilities. ",
          image: DataProcessingEdge
        },
        {
          title: "Real-time Safety Monitoring ",
          paragraph:
            "Take advantage of our complete, cloud-managed <b>IoT Gateway Appliance</b> that uses compute and hardware-accelerated machine learning at edge locations to monitor video streams for safety violations in real-time, guard proactively against potential disruptions, and conduct exploratory analysis where relevant. ",
          image: ManufacturingRealTimeSafetyMonitoring
        },
        {
          title: "IoT & Digital Twins ",
          paragraph:
            "By creating digitized virtual replicas (“digital twins”) of mechanical components for use in your design, test, and validation processes, we help manufacturers save an enormous amount of cost and resources while accelerating their time to market with lower error and defect rates. ",
          image: IotDigitalTwins
        },
        {
          title: "Process Automation ",
          paragraph:
            "We work with manufacturers to strategize where automation can provide the greatest value for their productivity, efficiency, and profitability goals, and then engineer the systems required to support reporting, scalability and innovation, ultimately leading to greater competitive advantages. ",
          image: ManufacturingProcessAutomation
        },
        {
          title: "3D Design Acceleration ",
          paragraph:
            "By leveraging supercomputing-scale performance in the cloud along with the latest and greatest processors, we bring the cloud to your app with acceleration for  both accuracy and precision determinations of product designs without disrupting your workflow by forcing a different way of doing things. ",
          image: DesignAcceleration3D
        },
        {
          title: "Intelligent Fleet ",
          paragraph:
            "We leverage IoT sensors, Edge Computing, Containerized AI, and cloud-based ML to engineer a fleet of intelligent vehicles that help manufacturers enhance their supply chain efficiencies and reduce costs with optimizing routing, predictive maintenance, and real-time data analytics. ",
          image: IntelligentFleet
        }
      ]
    },
    Terawe: {
      title: "Terawe’s Manufacturing Solutions",
      points: [
        {
          image: manageX,
          paragraph:
            "A simple & secure method for 3D industrial designers to instantly access global-scale HPC resources for their CFD and FEA application workloads",
          link: "/ManageX",
          customClass: "manageX"
        },
        {
          image: GaliEye,
          paragraph:
            "Data analytics and smart recommendations for manufacturers to optimize supply chain operations and matching inventories to demand curves",
          link: "/GaliEye",
          customClass: "galiEye"
        },
        {
          image: LogoAnomalyDetection,
          paragraph:
            "A predictive analytics tool using AI and ML automatically detect and identify engineering and/or build anomalies before any process disruption",
          link: "/4Site",
          customClass: "fourSite"
        }
      ]
    },
    Partner: {
      title: "Why We Should Be Your Transformation Partner ",
      partnersImages: WhyPartnerWithUs,
      points: [
        {
          title: "Focus on Outcomes ",
          paragraph:
            "What matters most is that you achieve what you expect from your technology investments.  Terawe is an outcome-focused partner that ensures every phase of every engagement is on track to deliver the intended results. "
        },
        {
          multiple: [
            {
              title: "Goalposts With Guarantees ",
              paragraph:
                "We are willing to guarantee your costs will not spiral above what has been discussed and agreed upon…or we will cover the cost overages. "
            },
            {
              title: "Responsible AI ",
              paragraph:
                "Terawe designs, develops, and deploys AI with good intentions to empower our clients’ employees and businesses with trust and confidence for long-term success. "
            },
            {
              title: "Unique HPC Practice In-House ",
              paragraph:
                "Terawe is one of very few technology partners with a dedicated HPC practice in-house, helping simplify and accelerate adoption for complex application scenarios. "
            }
          ]
        },
        {
          title: "Custom Support Tiers ",
          paragraph:
            "We embrace the specific needs and expectations our clients’ customers have, and use that to build customer support packages and options that directly address those needs and deliver the most supportive experiences possible. "
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "An Alternate Approach to Anomaly Detection",
        "Predictive Maintenance",
        "The Ubiquity of Scale-Up and Scale-Out Infrastructures for HPC and AI"
      ]
    }
  },
  Retail: {
    helmet: [
      {
        title: "Terawe Retail | Digital Consulting & Engineering Services ",
        description:
          "Produce the omnichannel experiences your customers expect with our advanced digital transformation solutions for the retail sector",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Retail",
            "image": "",
            "description": "Produce the omnichannel experiences your customers expect with our advanced digital transformation solutions for the retail sector",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Retail",
      paragraph:
        "We help large retailers leverage the power of their data to optimize their efficiencies while  delivering immersive customer experiences that accelerate growth and loyalty through deeper customer insights and relationships.        ",
      link: "/Managed"
    },
    Intelligent: {
      title: "What Does Intelligent Retail Look Like? ",
      paragraph:
        "By leveraging cutting-edge technology platforms, retailers can develop innovative solutions that deliver immersive customer experiences, regardless of where their customers are or how they like to shop. ",
      image: IntelligentRetail,
      points: [
        "<b>Know Your Customers:</b> Deliver personalized experiences across multiple channels to drive more relevant offers and recommendations, grow revenue, and improve customer experience with real-time personalization. ",
        "<b>Build Resilient Supply Chains:</b> Enable informed decisions confidently, quickly, and accurately with predictive, intelligent, and agile supply chains to predict and respond to risks with multichannel fulfillment optimization.",
        "<b>Empower Your Employees:</b> Boost employee productivity, providing them a seamless and smooth captivating experience with digital smart store operations, enabling employees to be efficient brand ambassadors with powerful insights.",
        "<b>Reimagine Retail:</b> Enable immersive experiences to drive customer loyalty such as seamless checkout experience, delivering real-time offers and insights, and tailoring interactions based on detected changes in customer behavior."
      ]
    },
    Transforming: {
      title: "Challenges with Transforming Retail ",
      points: [
        {
          title: "New Technology Adoption ",
          paragraph:
            "The retail sector often grapples with the rapid pace of technological advancements, and keeping pace with emerging technologies, such as AI, Edge Computing, and IoT devices, can be alienating and eventually prohibitive for many established retailers.",
          icon: <TransformingRetail1 />
        },
        {
          title: "Data Security and Privacy ",
          paragraph:
            "Retailers must ensure security and privacy in handling large amounts of customer data, which requires active safeguarding of sensitive information from breaches and staying in compliance with data protection regulations to maintain customer trust.",
          icon: <TransformingRetail2 />
        },
        {
          title: "Customer Expectations ",
          paragraph:
            "Modern consumers expect seamless, ‘omnichannel’ experiences across online and offline environments, which requires retailers to invest in intuitive UX design, connected systems, personalized recommendations, and efficient, friendly customer service.",
          icon: <TransformingRetail3 />
        },
        {
          title: "Market Competition ",
          paragraph:
            "Because the retail landscape is highly competitive with new players disrupting traditional models, established retailers must become more nimble and  agile, able to monitor and react quickly to market trends, and differentiate themselves to remain relevant. ",
          icon: <TransformingRetail4 />
        },
        {
          title: "Supply Chain Management ",
          paragraph:
            "Maintaining a fluid supply chain is critical for retail success, and retailers must optimize their inventory management, enhance logistics, and ensure timely deliveries in order to meet more stringent customer demands. ",
          icon: <TransformingRetail5 />
        },
        {
          title: "Implementation Costs ",
          paragraph:
            "Adopting new technologies can be an expensive venture, and retailers must take the time and effort to carefully allocate resources, balance investments, and manage expenses during their transformation journey. ",
          icon: <TransformingRetail6 />
        },
        {
          title: "Cultural and Organizational Change ",
          paragraph:
            "Shifting from traditional workflows to new methodology is never a simple prospect, and retailers will undoubtedly face resistance to change from in-store employees, store managers, corporate staff, and even customers. ",
          icon: <TransformingRetail7 />
        }
      ]
    },
    Retailers: {
      title: "What We Can Do For Retailers",
      paragraph:
        "We can help you differentiate your business from the competition with specific augmentations and enhancements that redefine consumer experiences.",
      section: "Common Processes & Workflows",
      offer: "What We Can Offer",
      points: [
        {
          title: "Product Sourcing and Procurement ",
          paragraph:
            "Identifying and selecting suppliers, business negotiations, order placement, shipment receiving, quality control inspection ",
          offer: [
            "Insights to guide supplier selection ",
            "Optimized receiving logistics ",
            "Optical quality assurance for accelerated inspection & validation "
          ]
        },
        {
          title: "Inventory Management ",
          paragraph:
            "Optimizing inventory balance between supply and demand, product restocking, product storage ",
          offer: [
            "Robotic automation for intelligent warehouse ",
            "Smart storage logistics w/intelligent shelf life"
          ]
        },
        {
          title: "Merchandising",
          paragraph:
            "Choosing optimal product mix, product display arrangements, price setting, marketing promotion ",
          offer: [
            "Insights for product matching & display arrangements ",
            "Market & data analytics for best pricing practices ",
            "Customer data analysis for smart promotion "
          ]
        },
        {
          title: "Sales",
          paragraph:
            "Customer engagement, value-add positioning, sales transaction, POS systems, upselling/cross-selling ",
          offer: [
            "Intelligent recommendations for value-add options ",
            "Connected systems for POS ",
            "Mobile shopping apps with 360-degree UX "
          ]
        },
        {
          title: "Customer Service ",
          paragraph:
            "Assistance and support interaction, handling returns/exchanges, collecting feedback ",
          offer: [
            "Virtual agents with customized, branded voices ",
            "Data analytics for feedback aggregation & reporting ",
            "Machine learning for improving the sales process "
          ]
        },
        {
          title: "Order Fulfillment ",
          paragraph:
            "Order processing, picking and packing, shipping and delivery logistics ",
          offer: [
            "Optimized fulfillment processing ",
            "Intelligent fleet with optimized routing ",
            "Predictive maintenance for delivery vehicle health "
          ]
        },
        {
          title: "Post-Sale Services ",
          paragraph:
            "Customer follow-up engagement, warranty fulfillment, repair services, customer loyalty & retention programs ",
          offer: [
            "Intelligent insights for timed alerts & notifications ",
            "Behavioral insights for loyalty program tiers "
          ]
        },
        {
          title: "Data Analysis and Reporting ",
          paragraph:
            "Identify market trends, sales performance measurement, inventory analysis, customer behavior analysis, market strategy ",
          offer: [
            "Machine learning for market & sales trend prediction ",
            "Intelligent insights on customer behavior ",
            "IoT on the Edge for real-time sales activity tracking "
          ]
        },
        {
          title: "Continuous Improvement ",
          paragraph:
            "Integrating feedback into the sales process, staff training and development, sales technology adoption & upgrades ",
          offer: [
            "Process automation for accelerated responsiveness ",
            "Intelligent recommendations for leveraging feedback ",
            "Sales training & skilling assessments "
          ]
        }
      ]
    },
    Solution: {
      title: "Our Retail Solution Areas ",
      points: [
        {
          title: "Customer 360",
          paragraph:
            "Using AI and machine learning with customer data, we help retailers unlock key insights about purchasing trends, behaviors, and tendencies that enable 360-degrees of immersive shopping experiences. ",
          image: Customer360
        },
        {
          title: "Frictionless Checkout",
          paragraph:
            "We lean upon our IoT Gateway Appliance in concert with sensors, cameras, and RFID tokens to minimize friction associated with checking out, such as waiting in lines and scanning individual items. ",
          image: FrictionlessCheckout
        },
        {
          title: "Intelligent Asset Tracking",
          paragraph:
            "We use spatial anchors in concert with alternate reality technologies (AR/VR/MR) to persistently attach digital information to physical items and locations that enhance the accuracy and efficacy of tracking & managing assets. ",
          image: RetailIntelligentAssetTracking
        },
        {
          title: "Smart Shelves  ",
          paragraph:
            "Using IoT & Edge computing technologies, we help retailers implement the weight sensors, cameras, image recognition services and digital displays to help streamline operations, enhance customer satisfaction, and gain a competitive edge in the market. ",
          image: SmartShelves
        },
        {
          title: "Virtual Mirror ",
          paragraph:
            "We use powerful AI services and virtualization technologies to create virtual reflection and augmentation experiences that enable large retailers to to grow sales, create positive word-of-mouth and expand upon their differentiation with their competition. ",
          image: VirtualMirror
        },
        {
          title: "Real Time Safety Monitoring",
          paragraph:
            "Using accelerated machine learning on the edge with our cloud-managed appliance, we help generate the intelligent insights retailers need to quickly identify violations and improve safety conditions for employees and customers alike. ",
          image: RetailRealTimeSafetyMonitoring
        }
      ]
    },
    Terawe: {
      title: "Terawe’s Retail Solutions",
      points: [
        {
          image: GaliEye,
          paragraph:
            "Intelligent insights that enable retailers to provide the omnichannel experiences modern consumers expect ",
          link: "/GaliEye",
          customClass: "galiEye"
        },
        {
          image: Inventory,
          paragraph:
            "Pre-trained ML models that predict potentially crippling supply chain & inventory issues before they become a problem.",
          link: "/4Site",
          customClass: "fourSite"
        }
      ]
    },
    Partner: {
      title: "Why Partner With Terawe? ",
      partnersImages: WhyPartnerWithUs,
      points: [
        {
          title: "Focus on Outcomes ",
          paragraph:
            "What matters most is that you achieve what you expect from your technology investments.  Terawe is an outcome-focused partner that ensures every phase of every engagement is on track to deliver the intended results. "
        },
        {
          multiple: [
            {
              title: "Goalposts With Guarantees ",
              paragraph:
                "We are willing to guarantee your costs will not spiral above what has been discussed and agreed upon…or we will cover the cost overages. "
            },
            {
              title: "Responsible AI ",
              paragraph:
                "Terawe designs, develops, and deploys AI with good intentions to empower our clients’ employees and businesses with trust and confidence for long-term success."
            },
            {
              title: "Retail Partnerships ",
              paragraph:
                "Terawe is no stranger to the retail space, having worked previously with national and global retail brands on strategic technology investments and implementation."
            }
          ]
        },
        {
          title: "Custom Support Tiers ",
          paragraph:
            "We embrace the specific needs and expectations our clients’ customers have, and use that to build customer support packages and options that directly address those needs and deliver the most supportive experiences possible. "
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "Virtual Mirror",
        "Proposal Management",
        "Fraud Detection"
      ]
    }
  },
  PublicSector: {
    helmet: [
      {
        title: "Terawe | Managed IT and AI Services for the Public Sector",
        description:
          "Let our experience working with government and public sector organizations help you achieve your desired technology management outcomes",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Public Sector",
            "image": "",
            "description": "Let our experience working with government and public sector organizations help you achieve your desired technology management outcomes",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Public Sector",
      paragraph:
        "We help government agencies and organizations in the public sector conceive, develop, and deliver trustworthy, secure solutions that maintain data privacy and align with local regulatory requirements while enabling captivating citizen services.",
      link: "/Managed"
    },
    Transformed: {
      title: "Transforming Citizen Services",
      paragraph: [
        "Transforming services for citizens and government organizations, including local, state, federal, and supra-national entities, requires a holistic approach that leverages technology, stakeholder engagement, process optimization, and policy innovation.",
        "By adopting these strategies, organizations can improve service delivery, increase efficiency, and better meet the needs of the public.  Consider some of these transformational measures recently taken by public sector organizations:"
      ],
      points: [
        {
          title: "Estonia",
          paragraph:
            "Estonia has implemented comprehensive e-government services, allowing citizens to vote, pay taxes, and access healthcare services online. ",
          image: Estonia,
          link: "https://e-estonia.com/"
        },
        {
          title: "New York City",
          paragraph:
            "New York City’s 311 system provides a centralized platform for citizens to report issues and request services, improving responsiveness and accountability. ",
          image: NewYork,
          link: "https://portal.311.nyc.gov/"
        },
        {
          title: "Singapore          ",
          paragraph:
            "Singapore uses smart technologies for city management, including transportation, healthcare, and housing, enhancing the quality of life for its residents. ",
          image: Singapore,
          link: "https://www.smartnation.gov.sg/"
        }
      ]
    },
    Opportunities: {
      title: "Opportunities for the Public Sector",
      points: [
        {
          title: "Citizen Safety & Security ",
          paragraph:
            "Predictive policing, enhanced surveillance & monitoring, and optimized emergency response",
          image: CitizenSafety
        },
        {
          title: "Public Education ",
          paragraph:
            "Personalized learning experiences, administrative efficiencies, and learning analytics",
          image: PublicEducation
        },
        {
          title: "Public Transport ",
          paragraph:
            "Optimized traffic management, public transit optimization, and intelligent vehicle fleet",
          image: PublicTransport
        },
        {
          title: "Civic Administration",
          paragraph:
            "Process automation, decision-making insights, and immersive citizen services",
          image: GovtAdministration
        },
        {
          title: "Sustainable Cities ",
          paragraph:
            "Intelligent resource management, climate modeling & monitoring, smart waste management",
          image: SustainableCities
        },
        {
          title: "Economic Development ",
          paragraph:
            "Market insights & analytics, intelligent employment sourcing, and fraud detection",
          image: EconomicDevelopment
        },
        {
          title: "Urban Development ",
          paragraph:
            "Smart cities, infrastructure predictive maintenance, and optimized land use planning",
          image: UrbanDevelopment
        },
        {
          title: "Legal & Regulatory",
          paragraph:
            "Document intelligence, intelligent legal research, automated compliance services ",
          image: LegalRegulatoryServices
        }
      ]
    },
    Challenges: {
      title: "Challenges for the Public Sector",
      points: [
        {
          title: "Data ",
          paragraph:
            "Poor quality datasets, disconnected ‘siloed’ data storage assets, and ensuring data security & information privacy",
          icon: <GovtChellangeData />
        },
        {
          title: "Technical ",
          paragraph:
            "Outdated legacy systems, complexities with scaling, and minimal in-house technical expertise",
          icon: <GovtChellangeTechnical />
        },
        {
          title: "Organizational ",
          paragraph:
            "Resistance to change, cultural barriers to adoption, and effective coordination measures between bureaus",
          icon: <GovtChellangeOrganizational />
        },
        {
          title: "Regulatory ",
          paragraph:
            "Navigating constantly-shifting regulations, ethical considerations, and service transparency ",
          icon: <GovtChellangeRegulatory />
        },
        {
          title: "Financial ",
          paragraph:
            "Implementation costs, stringent budget constraints, and complex purchasing models & processes ",
          icon: <GovtChellangeFinancial />
        },
        {
          title: "Public Trust ",
          paragraph:
            "Gaining acceptance for new methods, and  effectively communicating benefits and limitations ",
          icon: <GovtChellangePublictrust />
        }
      ]
    },
    Solution: {
      title: "Our Public Sector Solution Areas",
      points: [
        {
          title: "Smart Cities ",
          paragraph:
            "We leverage data-driven solutions that integrate information and communication technologies (ICT) and the Internet of Things (IoT) to help public sector organizations enhance the quality of life for its citizens across various aspects of urban life. ",
          image: SmartCities
        },
        {
          title: "Intelligent Transport",
          paragraph:
            "We help governments ensure more timely, punctual service schedules for their public transportation systems, improve rider safety with better awareness of potential hazards, and create more enjoyable, memorable experiences for both riders and employees. ",
          image: IntelligentTransport
        },
        {
          title: "Sustainable Services",
          paragraph:
            "We use powerful AI platforms and document intelligence services to bring process automation into the public sector, which both accelerates document processing and handling activities while reducing significant paper & material waste. ",
          image: SustainableServices
        },
        {
          title: "Citizen Security & Safety",
          paragraph:
            "Using hardware-accelerated machine learning at the Edge, we help governments address citizen safety issues such as improved policing, optimized emergency response, safer infrastructure, and more secure information protection for citizens. ",
          image: CitizenSecurity
        },
        {
          title: "Intelligent Asset Tracking",
          paragraph:
            "We use spatial anchors in concert with alternate reality technologies (AR/VR/MR) to persistently attach digital information to physical items and locations that enhance the accuracy and efficacy of tracking & managing assets. ",
          image: GovtIntelligentAssetTracking
        },
        {
          title: "Smart Fleet",
          paragraph:
            "With IoT sensors, Edge computing, and data analytics tools, we enable public sector organizations with the systems of intelligence that help them modernize their transportation systems, improve service quality, and enhance safety for employees and citizens. ",
          image: SmartFleet
        },
        {
          title: "Application Modernization",
          paragraph:
            "We help governments become unconstrained from the limitations of their aging legacy applications, bringing powerful services of intelligence and insight to modernize core functions and capabilities that deliver the transformational outcomes they seek.",
          image: ApplicationModernization
        }
      ]
    },
    Terawe: {
      title: "Terawe's Public Sector Solutions",
      points: [
        {
          image: manageX,
          paragraph:
            "A simple & secure method to instantly access global-scale HPC resources in CAE and CAD workflows for urban development ",
          link: "/ManageX",
          customClass: "manageX"
        },
        {
          image: GaliEye,
          paragraph:
            "Intuitive insights to modernize transportation systems for better rider safety and more memorable experiences ",
          link: "/GaliEye",
          customClass: "galiEye"
        },
        {
          image: UrbanInsight,
          paragraph:
            "Pre-trained ML models that interact with government data to generate intelligent insights for smart city planning ",
          link: "/4Site",
          customClass: "fourSite"
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "Preventing Social Welfare Fraud during the COVID-19 Pandemic",
        "Proposal Management",
        "AI: Real World Challenges with Image Segmentation"
      ]
    }
  },
  Communications: {
    helmet: [
      {
        title: "Terawe Corp | Transformational Telecom Consulting Services",
        description:
          "Digitally transform your communication operations with our business solutions and digital consulting services for the telecommunications sector",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Telecommunications",
            "image": "",
            "description": "Digitally transform your communication operations with our business solutions and digital consulting services for the telecommunications sector",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Telecommunications",
      paragraph:
        "By using powerful, cloud-based communication, collaboration and advanced analytics platforms, we help telecommunication companies accelerate their digital transformation objectives and deliver benefits to the organization, their employees and their customers. ",
      link: "/Managed"
    },
    Impact: {
      title: "Impact from Market Shifts & Cultural Trends ",
      paragraph:
        "Telcos are striving to stay relevant due to several interconnected reasons driven by technological advancements, changing consumer behaviors, and the evolving competitive landscape. ",
      points: [
        {
          title: "Declining Traditional Revenue Streams ",
          paragraph:
            "The cord-cutting trend along with the decline in landline usage have contributed towards declining service revenues. ",
          icon: <CommunicationIcon1 />
        },
        {
          title: "Customer Retention and Acquisition ",
          paragraph:
            "Increasing their flexibility in terms of service packages, pricing models, and custom options are necessary to enhance customer experiences ",
          icon: <CommunicationIcon2 />
        },
        {
          title: "Growth of Internet and Data Services ",
          paragraph:
            "Telcos have had to increase their investments that cater to demand for high-speed internet and the rollout of 5G technology. ",
          icon: <CommunicationIcon3 />
        },
        {
          title: "Service Diversification ",
          paragraph:
            "Telcos are diversifying by bundling services, investing in content creation tools, or partnering with streaming service providers. ",
          icon: <CommunicationIcon4 />
        },
        {
          title: "New Advancements ",
          paragraph:
            "Telcos are rapidly Integrating new technologies such as AI, Edge, and the cloud for new experience services such as the ‘Smart Home’ ",
          icon: <CommunicationIcon5 />
        },
        {
          title: "Competitive Pressure ",
          paragraph:
            "The entry of non-traditional players increases competition, forcing telcos to consider mergers and acquisitions to consolidate. ",
          icon: <CommunicationIcon6 />
        }
      ]
    },
    Communications: {
      title: "The Future of Telecommunications ",
      paragraph:
        "AI offers telecommunication companies the ability to enhance their operations, customer experiences, and service offerings, thereby positioning themselves to remain relevant in this rapidly evolving digital landscape. ",
      points: [
        {
          title: "Optimized Networks ",
          paragraph:
            "Predictive maintenance, dynamic resource allocation, and self-optimizing networks (SON) ",
          icon: <ManageServiceIcon />
        },
        {
          title: "Enhanced Data Analytics ",
          paragraph:
            "Customer insights, predictive analytics, greater market agility ",
          icon: <ManageServiceIcon />
        },
        {
          title: "Immersive Customer UX ",
          paragraph:
            "AI-powered chatbot, virtual assistants, and personalized recommendations ",
          icon: <ManageServiceIcon />
        },
        {
          title: "Fraud Detection ",
          paragraph:
            "Anomaly detection, behavioral analytics, intelligent alerts & notifications ",
          icon: <ManageServiceIcon />
        },
        {
          title: "New Revenue Streams ",
          paragraph:
            "AI-as-a-service, intelligent home, IoT device services, cloud services ",
          icon: <ManageServiceIcon />
        },
        {
          title: "Improved Planning and Development ",
          paragraph:
            "5G implementation & deployment, capacity management, service alerts & notifications ",
          icon: <ManageServiceIcon />
        },
        {
          title: "Operational Efficiencies ",
          paragraph:
            "Process automation, supply chain optimization, predictive analytics ",
          icon: <ManageServiceIcon />
        },
        {
          title: "Enhanced QoC ",
          paragraph:
            "Traffic management, service assurance, customer satisfaction ",
          icon: <ManageServiceIcon />
        }
      ]
    },
    Approach: {
      title: "Our Approach",
      image: OurApproach,
      paragraph: [
        "One key way Terawe delivers telco organizations differentiated value is by establishing a seamless, integrated, and organized IT operational fabric that spans from the cloud to the far edge. ",
        "We delicately and efficiently balance all your service and functional needs, wherever they may be physically located around the enterprise, with the best choices of technological enablement that produces the intended outcomes. This enables telcos to access their apps, store data, and run their services anywhere, securely, compliant, and across physical and/or virtual boundaries.",
        "In this manner, Terawe can help telcos reduce their operational and process latencies while cutting costs, to put them in a position of delivering deep, contextual insights in real time, accelerating them towards their transformational goals."
      ]
    },
    Solution: {
      title: "Our Telco Solution Areas",
      points: [
        {
          title: "Network Optimization ",
          paragraph:
            "We use powerful AI services to analyze network data in real-time to optimize network performance by dynamically adjusting network parameters to improve efficiency and reliability. ",
          image: NetworkOptimization
        },
        {
          title: "Predictive Maintenance",
          paragraph:
            "By analyzing data from network equipment and sensors, our AI services can predict equipment failures before they occur, enabling proactive maintenance to minimize downtime and reduce operational costs. ",
          image: CommPredictiveMaintenance
        },
        {
          title: "Churn Prediction",
          paragraph:
            "By using AI and machine learning to track and measure customer usage trends, we help telcos identify which customers are at risk of churning and when – enabling more proactive measures to retain customers. ",
          image: ChurnPrediction
        },
        {
          title: "Fraud Detection",
          paragraph:
            "We help telcos prevent revenue loss and protect their customers' accounts by using AI algorithms to analyze large volumes of transaction data and detect fraudulent activities, such as un-authorized usage or SIM card cloning. ",
          image: CommFraudDetection
        },
        {
          title: "IoT & Edge Management ",
          paragraph:
            "Given the proliferation of IoT devices in the communications sector, we use AI to help telcos manage and optimize their IoT networks, analyze sensor data, and derive valuable insights for various applications. ",
          image: IoTEdgeManagement
        },
        {
          title: "Enhanced Security",
          paragraph:
            "With our AI-powered cybersecurity solutions, we help telcos detect and mitigate cyber threats in real-time, protecting telco networks and customer data from unauthorized access, malware, and other security breaches. ",
          image: EnhancedSecurity
        },
        {
          title: "Smart Network",
          paragraph:
            "We enable more efficient use of network resources and better quality of service for telco customers by using AI to optimize resource allocation, traffic routing, and capacity planning across networks. ",
          image: SmartNetwork
        },
        {
          title: "Personalized Experiences",
          paragraph:
            "As a combination of speech synthesis, NLP, and bot services, we leverage powerful Conversational AI platforms to develop next-gen, naturally interactive virtual agents for customer support, virtual store tours, and omnichannel customer experiences. ",
          image: PersonalizedExperiences
        },
        {
          title: "Cloud Service Provider",
          paragraph:
            "As an Advanced and Preferred Partner to Microsoft, we enable Telcos to package, sell, provision and manage bundled cloud service offers that helps them become more agile, improve their service quality, differentiate better from their competitors, and ultimately deliver better user experiences.",
          image: CloudServiceProvider
        }
      ]
    },
    Terawe: {
      title: "Terawe's Telecommunications Solutions ",
      points: [
        {
          image: manageX,
          paragraph:
            "Offering power, scale, and speed of the cloud for your network optimization, capacity planning, and data analysis purposes",
          link: "/ManageX",
          customClass: "manageX"
        },
        {
          image: GaliEye,
          paragraph:
            "Deep insights producing intelligent recommendations for optimizing network operations and regional functionality ",
          link: "/GaliEye",
          customClass: "galiEye"
        },
        {
          image: Churn,
          paragraph:
            "Customer usage and behavioral trend analytics to help reduce customer churn and increase long term retention ",
          link: "/4Site",
          customClass: "fourSite"
        }
      ]
    },
    Telcos: {
      title: "Why Telcos Partner With Us ",
      image: PartnerWithUs,
      paragraph: [
        "<b>Focus on Outcomes:</b> What matters most is that you achieve what you expect from your technology investments.  Terawe is an outcome-focused partner that ensures every phase of every engagement is on track to deliver the intended results. ",
        "<b>Responsible AI:</b> Terawe designs, develops, and deploys AI with good intentions to empower our clients’ employees and businesses with trust and confidence for long-term success. ",
        "<b>Customized Support Tiers:</b> We embrace the specific needs and expectations our clients’ customers have, and use that to build customer support packages and options that directly address those needs and deliver the most supportive experiences possible. ",
        "<b>Goalposts With Guarantees:</b> We are willing to guarantee your costs will not spiral above what has been discussed and agreed upon…or we will cover the cost overages. "
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "An Alternate Approach to Anomaly Detection",
        "Predictive Maintenance",
        "The Ubiquity of Scale-Up and Scale-Out Infrastructures for HPC and AI"
      ]
    }
  },
  Government: {
    helmet: [
      {
        title: "Terawe | Managed IT and AI Services for the Government",
        description:
          "Let our experience working with government and Government organizations help you achieve your desired technology management outcomes",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Government",
            "image": "",
            "description": "Let our experience working with government and Government organizations help you achieve your desired technology management outcomes",
            "brand": "Terawe"
          }`
      }
    ],
    heroBanner: {
      title: "Government",
      paragraph:
        "We help government agencies conceive, develop, and deliver trustworthy, secure solutions that maintain data privacy and align with local regulatory requirements while enabling captivating citizen services.",
      link: "/Managed"
    },
    Transforming: {
      title: "Top of Mind for Transforming Governments",
      points: [
        {
          title: "Data Security & Privacy",
          list: [
            "Ensuring the protection of sensitive information from cyber threats ",
            "Complying with privacy regulations and standards (e.g., GDPR, HIPAA)"
          ],
          icon: DataSecurity
        },
        {
          title: "Interoperability",
          list: [
            "Facilitating seamless integration and communication between different departments ",
            "Ensuring compatibility with existing technologies and platforms"
          ],
          icon: Interoperability
        },
        {
          title: "Limited Budget",
          list: [
            "Managing the financial investment required for digital transformation ",
            "Justifying expenditures and demonstrating return on investment (ROI) to stakeholders"
          ],
          icon: LimitedBudget
        },
        {
          title: "Change Management",
          list: [
            "Overcoming resistance to change among employees and stakeholders ",
            "Providing training and support to ensure smooth transitions to new processes"
          ],
          icon: ChangeManagementIcon
        },
        {
          title: "Citizen Engagement",
          list: [
            "Improving the accessibility and user-friendliness of digital services for citizens ",
            "Enhancing transparency and communication with the public"
          ],
          icon: CitizenEngagement
        },
        {
          title: "Regulatory Compliance",
          list: [
            "Ensuring that digital initiatives comply with existing laws and regulations ",
            "Keeping up with changes in regulatory requirements"
          ],
          icon: RegulatoryComplianceIcon
        },
        {
          title: "Scalability & Future Proofing",
          list: [
            "Developing systems that can scale with the growing needs of the population ",
            "Investing in technologies that are adaptable to future advancements"
          ],
          icon: Scalability
        },
        {
          title: "Performance & Reliability",
          list: [
            "Ensuring that digital systems are reliable and perform efficiently ",
            "Minimizing downtime and ensuring continuity of services"
          ],
          icon: Performance
        },
        {
          title: "Ethical Considerations",
          list: [
            "Addressing concerns related to the ethical use of technology, including AI and data analytics ",
            "Ensuring fairness, accountability, and transparency"
          ],
          icon: EthicalConsiderations
        },
        {
          title: "Digital Inclusion",
          list: [
            "Ensuring service accessibility to all citizens, including the disabled and those  underserved ",
            "Bridging the digital divide to prevent exclusion of any demographic group"
          ],
          icon: DigitalInclusion
        },
        {
          title: "Talent & Expertise",
          list: [
            "Recruiting and retaining skilled personnel with expertise in digital technologies ",
            "Building internal capabilities and fostering a culture of innovation"
          ],
          icon: TalentExpertise
        },
        {
          title: "Infrastructure",
          list: [
            "Upgrading existing infrastructure to support digital transformation ",
            "Ensuring robust and resilient infrastructure to handle increased digital activity"
          ],
          icon: Infrastructure
        }
      ]
    },
    Opportunities: {
      title: "Opportunities for Governments",
      points: [
        {
          title: "Limitless Analytics",
          paragraph:
            "Location-specific, geo-tagged first response assistance & crisis management enables first responders with timely insights from location specific data.",
          image: LimitlessAnalytics
        },
        {
          title: "Remote Access",
          paragraph:
            "Enhance the judicial decision-making process by unifying court data across agency silos with AI-powered paperless court system and eventually virtual hearings.",
          image: RemoteWork
        },
        {
          title: "Agency Collaboration",
          paragraph:
            "Deliver connected government services by enabling timely insights to promote cross-agency collaboration with predictive risk modeling & scoring.",
          image: CrossAgencyCollaboration
        },
        {
          title: "Automated Services",
          paragraph:
            "Immediately respond to citizen queries around the clock and receive personalized benefits & recommendations with intelligent conversational agents.",
          image: AutomatedCitizenServices
        }
      ]
    },
    Solution: {
      title: "Our Government Solution Areas",
      points: [
        {
          title: "Public Safety",
          paragraph:
            "Using hardware-accelerated machine learning at the Edge, we help governments address citizen safety issues such as improved policing, optimized emergency response, safer infrastructure, and more secure information protection for citizens.",
          image: PublicSafety
        },
        {
          title: "Document Digitization",
          paragraph:
            "Our document intelligence services enable us to help government agencies increase their efficiency in document processing, content accessibility, and security of information management…ultimately enabling better service delivery and decision-making. ",
          image: DocumentDigitization
        },
        {
          title: "Process Automation",
          paragraph:
            "We leverage powerful AI platform services to automate repetitive tasks and manual operations while reducing human error that ultimately helps government organizations increase efficiency, reduce operational cost, and improve service delivery.",
          image: Govt_ProcessAutomation
        },
        {
          title: "Application Modernization",
          paragraph:
            "We help governments become unconstrained from the limitations of their aging legacy applications, bringing powerful services of intelligence and insight to modernize core functions and capabilities that deliver the transformational outcomes they seek.",
          image: ApplicationModernization
        },
        {
          title: "Search & Discovery",
          paragraph:
            "We help government agencies index all their data – structured or unstructured, analog or digital, on premise or in the cloud – into a unified data estate that provides instantaneous search & discovery experiences they never had available before.",
          image: SearchandDiscovery
        },
        {
          title: "Civic Engineering",
          paragraph:
            "Whether it’s putting in a new transportation line, improving aging buildings, or managing urban green spaces, we enable agencies with 3D visualization and alternate reality services to engineer and improve urban areas efficiently, sustainably, and cost-effectively.",
          image: ChangeImage
        }
      ]
    },
    Terawe: {
      title: "Terawe's Government Solutions",
      points: [
        {
          image: manageX,
          paragraph:
            "A simple & secure method to instantly access global-scale HPC resources for CAE and CAD applications",
          link: "/ManageX",
          customClass: "manageX"
        },
        {
          image: GaliEye,
          paragraph:
            "Intuitive insights to modernize transportation systems for better rider safety and experiences",
          link: "/GaliEye",
          customClass: "galiEye"
        },
        {
          image: DocumentIntelligence,
          paragraph:
            "Pre-trained ML models that help accelerate document ingestion and indexing for search & discovery purposes",
          link: "/4Site",
          customClass: "fourSite"
        }
      ]
    },
    resources: {
      title: "More Resources",
      resourceTitle: [
        "Preventing Social Welfare Fraud during the COVID-19 Pandemic",
        "AI: Real World Challenges with Image Segmentation",
        "Proposal Management"
      ]
    }
  }
};
/*
title: 'aaa',
paragraph: "hhh",
paragraph: ['zzz', 'zzz'],
link: "hhh",
image: azh
points: [
          {
            title: 'aaa',
            paragraph: 'zzz',
            image: abc,
            icon: abc
          }
        ]
heroBanner: {
      title: "Communications",
      paragraph:
        "We",
      link: "/Managed"
    },
resources: {
  title: 'More Resources',
  resourceTitle: ["aaa", "aaa", "aaa"],
}

heroBanner: {
  title: "Education",
  link: "/Managed"
}
*/
export default IndustriesObject;
