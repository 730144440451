import React from "react";
import bannerImage from "../../assets/img/transportation/Vehicle_SolutionAreas_3DDesignVisualization.jpg";
import InnerBanner from "../UiComponents/InnerBanner";
import IndustriesObject from "./IndustriesObject";
import {
  ImageContent,
  MainTitle,
  MetaHelmet,
  PartnersBlock,
  ResourcesBlock,
  SolutionsBlock,
  TitleParagraphBlock,
  VerticalCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function VehicleEngineering() {
  const root = IndustriesObject.VehicleEngineering;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <section className="sectionContainer">
        <div className="container">
          <MainTitle title={root.Differentiation.title} />
        </div>
        <div className="container">
          <ImageContent
            title={root.Differentiation.title}
            image={root.Differentiation.image}
            points={root.Differentiation.paragraph}
          />
        </div>
      </section>

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle
            title={root.VehicleMakers.title}
            paragraph={root.VehicleMakers.paragraph}
          />
        </div>
        <div className="container">
          <TitleParagraphBlock
            marker={true}
            points={root.VehicleMakers.points}
          />
        </div>
      </section>

      <VerticalCarouselSlick
        title={root.Solution.title}
        paragraph={root.Solution.paragraph}
        points={root.Solution.points}
      />

      <PartnersBlock title={root.Partner.title} points={root.Partner.points} />

      <SolutionsBlock
        title={root.Engineering.title}
        points={root.Engineering.points}
        customClass={"spaceAround"}
      />

      <ResourcesBlock
        black={true}
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
