import React from "react";
import bannerImage from "../../assets/img/Healthcare/Healthcare_MainBanner.jpg";
import IndustriesObject from "./IndustriesObject";
import InnerBanner from "../UiComponents/InnerBanner";
import ValueProposition from "../UiComponents/ValueProposition";
import {
  HoverShowContent,
  MainTitle,
  MetaHelmet,
  PartnersBlock,
  ResourcesBlock,
  SolutionsBlock,
  TitleParagraphBlock,
  VerticalCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function Healthcare() {
  const root = IndustriesObject.Healthcare;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        bannerLight={true}
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <ValueProposition
        image={root.Patient.image}
        title={root.Patient.title}
        paragraph={root.Patient.paragraph}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle
            title={root.Application.title}
            paragraph={root.Application.paragraph}
          />
        </div>
        <div className="container">
          <HoverShowContent points={root.Application.points} />
        </div>
      </section>

      <section className="sectionContainer">
        <div className="container">
          <MainTitle
            title={root.Challenges.title}
            paragraph={root.Challenges.paragraph}
          />
        </div>
        <div className="container">
          <TitleParagraphBlock marker={true} points={root.Challenges.points} />
        </div>
      </section>

      <VerticalCarouselSlick
        title={root.Solution.title}
        paragraph={root.Solution.paragraph}
        points={root.Solution.points}
      />
      <PartnersBlock title={root.Partner.title} points={root.Partner.points} />

      <SolutionsBlock title={root.Terawe.title} points={root.Terawe.points} />

      <ResourcesBlock
        black={true}
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
