import React from "react";
import bannerImage from "../../assets/img/productivity/Productivity_MainBanner.jpg";
import SpecialitiesContentObject from "./SpecialitiesContentObject";
import InnerBanner from "../UiComponents/InnerBanner";
import ValueProposition from "../UiComponents/ValueProposition";
import {
  ImageContent,
  MainTitle,
  MetaHelmet,
  ResourcesBlock,
  TextCarouselSlick,
  TitledCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function Productivity() {
  const root = SpecialitiesContentObject.Productivity;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />

      <InnerBanner
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <ValueProposition
        image={root.valueProps.image}
        title={root.valueProps.title}
        paragraph={root.valueProps.paragraph}
      />

      <TextCarouselSlick
        bgBlack={true}
        title={root.AIBuilds.title}
        points={root.AIBuilds.points}
      />

      <TitledCarouselSlick
        title={root.solutions.title}
        points={root.solutions.points}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle
            title={root.Partner.title}
            paragraph={root.Partner.paragraph}
          />
        </div>
        <div className="container">
          <ImageContent
            title={root.Partner.title}
            image={root.Partner.image}
            points={root.Partner.points}
          />
        </div>
      </section>

      <ResourcesBlock
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
