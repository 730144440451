import OutcomePrediction from "../../assets/img/solutions/4Site-OutcomePrediction.jpg";
import OccupancyManagement from "../../assets/img/solutions/4Sit-OccupancyManagement.jpg";
import AnomalyDetection from "../../assets/img/solutions/4Site-AnomalyDetection.jpg";
import foutSiteResponsibleAI from "../../assets/img/solutions/4Site-ResponsibleAI.jpg";
import innovation from "../../assets/img/solutions/GaliEye-Theinnovationoftomorrowdeliveredtoday.jpg";
import Capabilities from "../../assets/img/solutions/GaliEye-GaliEyeCapabilities.jpg";
import System from "../../assets/img/solutions/GalieyeSOI.png";
import Intelligent from "../../assets/img/solutions/GaliEye-IntelligentRetail.jpg";
import Health from "../../assets/img/solutions/GaliEye-SmartHealth.jpg";
import Manufacturing from "../../assets/img/solutions/GaliEye-SmartManufacturing.jpg";
import Finance from "../../assets/img/solutions/GaliEye-IntelligentFinance.jpg";
import businessImpact from "../../assets/img/solutions/GaliEye-BusinessImpact.jpg";
import Energy from "../../assets/img/solutions/GaliEye-SustainableEnergy.jpg";
import Transport from "../../assets/img/solutions/GaliEye-IntelligentTransport.jpg";
import ai4uLogo from "../../assets/img/solutions/4Site-AI4U.jpg";
import Yale from "../../assets/img/solutions/YaleUniversity.jpg";
import managex from "../../assets/img/solutions/ManageXWhatisManageX.png";
import Ireland from "../../assets/img/solutions/ManageX-CustomerStories-IrelandDepartmentofEducation.jpg";
import Microsoft from "../../assets/img/solutions/ManageX-CustomerStories-MicrosoftCorporation.png";
// logos
import ManageXResearch from "../../assets/img/logos/ManageXResearch.svg";
import ManageXHPC from "../../assets/img/logos/ManageXHPC.svg";
import ManageXAcademy from "../../assets/img/logos/ManageXAcademy.svg";

const SolutionsObject = {
  GaliEye: {
    helmet: [
      {
        title: "Terawe GaliEye | Cloud-Based AI Insights for Industry",
        description:
          "Our ML models extract and generate intelligent insights from your data for predictive maintenance, asset inspection, real-time monitoring, and other use cases.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Innovative solutions infused with intelligence",
            "image": "",
            "description": "Our ML models extract and generate intelligent insights from your data for predictive maintenance, asset inspection, real-time monitoring, and other use cases.",
            "brand": "Terawe"
          }`
      }
    ],
    pageTitle: {
      heading: "Innovative solutions infused with intelligence",
      title: "GaliEye Innovation through Insight | Terawe Corporation",
      paragraph:
        "Harness new market opportunities for your organization by extracting and understanding insights hiding within your data.",
      link: "/GaliEye"
    },
    Tomorrow: {
      title: "Tomorrow's Innovation Delivered Today",
      image: innovation,
      paragraph: [
        "Companies don’t purchase products or services anymore. They embark into constructive experiences that help them better manage their operations and processes while enriching their sense of satisfaction and improving the value of related outcomes.",
        "<b>GaliEye</b> enables businesses to innovate, modernize and optimize their applications by infusing their existing data estate with intelligence services that provide the insights necessary to create immersive, memorable experiences for both B2B and B2C scenarios across cloud, edge and on-premises boundaries."
      ],
      hashLink: "/GaliEye#innovationTomorrowID"
    },
    innovations: {
      title: "GaliEye’s System of Innovation",
      image: System,
      paragraph: [
        "GaliEye is powered by <b>‘System of Innovation’ (“SOI”)</b> that empowers organizations to turn their data, knowledge, and experience into actionable, intelligent insights that can transform virtually any industry or workflow into optimized, highly efficient user experiences.",
        "With <b>GaliEye Data Spaces</b> serving as the model training and inferencing hub, GaliEye delivers insight data through comprehensive dashboards that provide intuitive, logical pathways for organizations to embark on their transformational journeys at a reduced risk and with higher overall chances for success."
      ],
      hashLink: "/GaliEye#innovationSystemID"
    },
    gelieyeCapabilities: {
      title: "GaliEye Capabilities",
      image: Capabilities,
      paragraph: [
        "Data Optimization Normalization of your entire data estate (on/off premise, in cloud, or remote) for optimum platform interaction and functionality.",
        "Existing Templates Pre-built container modules for accelerated development and higher productivities from your engineering teams.",
        "Constant Improvement Continuous innovation and development (CI/CD) model that harnesses innovation as part of the build/versioning process.",
        "Rich User Experiences Real-time, device-agnostic dashboards with custom settings & policies for multiple personas within your organization. Safe & Secure Comprehensive redundancy, security, and compliance with industry-leading SLAs."
      ],
      points: [
        {
          title: "Data Optimization",
          paragraph:
            "Normalization of your entire data estate (on/off premise, in cloud, or remote) for optimum platform interaction and functionality."
        },
        {
          title: "Existing Templates",
          paragraph:
            "Pre-built container modules for accelerated development and higher productivities from your engineering teams."
        },
        {
          title: "Constant Improvement",
          paragraph:
            "Continuous innovation and development (CI/CD) model that harnesses innovation as part of the build/versioning process."
        },
        {
          title: "Rich User Experiences",
          paragraph:
            "Real-time, device-agnostic dashboards with custom settings & policies for multiple personas within your organization."
        },
        {
          title: "Safe & Secure",
          paragraph:
            "Comprehensive redundancy, security, and compliance with industry-leading SLAs."
        }
      ],
      hashLink: "/GaliEye#capabilitiesID"
    },
    whatif: {
      title: "GaliEye Solution Areas",
      points: [
        {
          id: "gelieye-intelligent-retail",
          title: "Retail",
          question: `large retailers could provide immersive and memorable shopping experiences?`,
          image: Intelligent,
          paragraph: [
            "Transforming retail equates to providing immersive, captivating experiences to shoppers that drive long term customer retention and loyalty. GaliEye empowers large retailers with an intelligent supply chain for consistent inventories, efficient store operations with highly productive employees, and a seamless shopping experience for customers that is both enjoyable and memorable. By turning common, everyday behaviors into delightful, omnichannel experiences, GaliEye keeps retailers in step with modern consumer expectations."
          ],
          list: [
            "Real-time personalization",
            "Multichannel fulfillment optimization",
            "Digital smart store operations",
            "Autonomous store"
          ],

          link: "/Retail"
        },
        {
          id: "gelieye-smart-health",
          question:
            "hospitals could facilitate plans of wellness that keep people from initially getting sick?",
          title: "Health",
          image: Health,
          paragraph: [
            "Healthcare providers are moving towards a more proactive paradigm of fostering & promoting wellness in a personalized way as opposed to identifying symptoms and proscribing treatment. GaliEye helps accomplish that goal by leveraging machine learning & AI across petabyte-sized data sets to identify those wellness trends and patterns. Real-time data aggregation enables providers with the understanding and insight needed to deliver an ‘anytime, anywhere’ personalized care solution that steers patients towards healthy outcomes."
          ],
          list: [
            "Connected, personalized care",
            "Precision care ",
            "Wellness outcomes"
          ],
          link: "/Healthcare"
        },
        {
          id: "gelieye-intelligent-finance",
          question:
            " manufacturers could eliminate disruptions and make ‘downtime’ a thing of the past?",
          title: "Finance",
          image: Finance,
          paragraph: [
            "Insurance, banking, and capital market institutitions want to offer more intelligent, innovative financial experiences, but are constantly challenged by resource and cost limitations. GaliEye empowers these organizations to develop personalized, tailored offers that leverage deep customer insights and improve their own business agility. It also enables such organizations to minimize their overall risk by automating document processing and increasing fraud prevention effectiveness."
          ],
          list: [
            "Personalized experiences",
            "Modernize operations",
            "Crime & fraud prevention",
            "Risk modeling"
          ],
          link: "/Finance"
        },
        {
          id: "gelieye-smart-manufacturing",
          question: `financial institutions could people feel closer to and more secure about their investments?`,

          title: "Manufacturing",
          image: Manufacturing,
          paragraph: [
            "In no other industry has digital transformation been more impactful than in process/discrete manufacturing. GaliEye#smartHealth helps industries accelerate their transformation by leveraging digital capabilities to re-invent old physical processes and constructs. Whether the goal is to introduce new robotic automation, to gain greater predictability of failures for heavy machinery (such as Windmills, CNC machines, Cranes) or to optimize inventories by directly aligning supply chain metrics with actual demand curves, GaliEye empowers heavy industries to transform how and when they want."
          ],
          list: [
            "Predictive maintenance",
            "Process continuity",
            "Supply chain optimization",
            "Worker safety"
          ],
          link: "/Manufacturing"
        },
        {
          id: "gelieye-sustainable-energy",
          question: `our sources of energy become more sustainable, posing far less risk to people and the planet?`,
          title: "Energy",
          image: Energy,
          paragraph: [
            "Transforming how we resource and utilize energy into more sustainable models for long term environmental health is rivaled by both explorative and economic risks. Energy companies also could be challenged by lacking experience with technologies best suited to enable transformation. By deriving intuitive insights from big data sets using AI and machine learning services, GaliEye can help reduce these risk factors and provide solid, logical recommendations for your sustainability journey."
          ],
          list: [
            "Geospatial intelligence",
            "Carbon Capture & Storage (CCS) optimization",
            "Sustainability roadmaps",
            "EIS/EIR documentation & reporting"
          ],
          link: "/Energy"
        },
        {
          id: "gelieye-intelligent-transportation",
          title: "Transportation",
          question: `civic organizations could make public transportation feel as efficient as driving yourself?`,
          image: Transport,
          paragraph: [
            "Did you ever think that taking a bus or riding the subway could become a transformational experience? Civic engineers are actively transforming these systems and GaliEye is playing a pivotal role. Using AI and machine learning on data captured in real-time, GaliEye helps local governments ensure more timely, punctual service schedules for their public transportations systems, improve rider safety with better awareness of potential hazards, and create more enjoyable, memorable experiences for both riders and employees."
          ],
          list: [
            "Smart fleet management",
            "Mobility-as-a-Service",
            "Connected workforce",
            "Intelligent railway operations"
          ],
          link: "/Automotive"
        }
      ]
    },
    businessImpact: {
      title: "Business Impact",
      image: businessImpact,
      paragraph:
        "Transform customer experiences By seamlessly integrating AI into products & services, GaliEye empowers you to immerse your customers into 360-degree experiences that redefines your brand identity and differentiates you from your competitors. Increase workforce productivity By empowering workers with more collaborative toolsets and more powerful, workstation-class experiences in remote areas, GaliEye helps increase organizational productivities and accelerates innovation. Achieve operational excellence Using AI platforms in concert with Edge computing and IoT devices, GaliEye helps bring all your operational elements together into alignment that help you reach your goals with consistency and effectiveness. Reimagine your business By uncovering hidden insights from your data, GaliEye enables to reimagine your business with a new, fresh perspective that infuses enthusiasm and excitement into your customer base.",
      points: [
        {
          title: "Transform customer experiences",
          paragraph:
            "By seamlessly integrating AI into products & services, GaliEye empowers you to immerse your customers into 360-degree experiences that redefines your brand identity and differentiates you from your competitors."
        },
        {
          title: "Increase workforce productivity",
          paragraph:
            "By empowering workers with more collaborative toolsets and more powerful, workstation-class experiences in remote areas, GaliEye helps increase organizational productivities and accelerates innovation."
        },
        {
          title: "Achieve operational excellence",
          paragraph:
            "Using AI platforms in concert with Edge computing and IoT devices, GaliEye helps bring all your operational elements together into alignment that help you reach your goals with consistency and effectiveness."
        },
        {
          title: "Reimagine your business",
          paragraph:
            "By uncovering hidden insights from your data, GaliEye enables to reimagine your business with a new, fresh perspective that infuses enthusiasm and excitement into your customer base."
        }
      ],
      hashLink: "/GaliEye#businessImpactID"
    }
  },
  FourSite: {
    helmet: [
      {
        title: "Terawe 4Site | Pre-trained AI and ML Models for Industry",
        description:
          "Our extensive library of pre-trained ML models generate real time insights necessary for applications requiring zero-latency responsiveness at the Edge",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Catapult your AI vision into reality.",
            "image": "",
            "description": "Our extensive library of pre-trained ML models generate real time insights necessary for applications requiring zero-latency responsiveness at the Edge",
            "brand": "Terawe"
          }`
      }
    ],
    pageTitle: {
      heading: "Catapult your AI vision into reality.",
      title: "4Site Model Library | Terawe Corporation",
      paragraph: "Pre-trained AI to accelerate your insights.",
      link: "/4Site"
    },
    ai4u: {
      title: "AI for You",
      image: ai4uLogo,
      paragraph: [
        "Unleash the power of pre-trained AI for unprecedented insight",
        "4site is your ultimate catalog of AI models for unlocking the full potential of artificial intelligence to revolutionize enterprise-level decision-making.",
        "With our cutting-edge pretrained AI models, we empower businesses to accelerate their insights and gain a competitive edge in today's fast-paced world. Harnessing the power of advanced machine learning techniques and a vast array of data, 4site delivers unparalleled accuracy and efficiency, enabling you to make data-driven decisions with confidence.",
        "Integrate into your existing infrastructure with ease. Our catalog offers a comprehensive suite of value propositions, including real-time analysis, predictive modeling, and actionable recommendations. Experience the transformational impact of AI-driven insights with 4site and stay ahead of the curve in this era of unparalleled innovation."
      ],
      hashLink: "/4Site#ai4uID"
    },
    ethicalAI: {
      image: foutSiteResponsibleAI,
      title: "Ethical AI",
      paragraph:
        "We believe developing AI-powered solutions mandates adherence to the following commitments:",

      points: [
        {
          title: "Responsibility",
          paragraph:
            " Our models are bound to specific use cases and integrated with secure control protocols."
        },
        {
          title: "Transparency",
          paragraph:
            "We let data be the guide for models instead of bending to individual preferences or positions."
        },
        {
          title: "Inclusivity",
          paragraph:
            " We support and bolster inclusivity towards the benevolence of people and their communities."
        },
        {
          title: "Accountability",
          paragraph:
            " We put our own ‘skin in the game’ with high-touch engagements under a shared risk model."
        }
      ],
      hashLink: "/4Site#ethicalAIID"
    },
    benefits4Site: {
      title: "Examples of 4Site in Action",
      paragraph:
        "Outcome Prediction Models trained by emotion recognition data, speech analysis, and trending performance metrics aimed to identify people and/or processes in your organization most in need of assistance. Use case example: Student Retention Occupancy Management Public health data, climate science, and population trends building models that maximize facility accommodations for organizations in the healthcare and hospitality verticals. Use case example: Hospital Bed Management Anomaly Detection Data accrued from system telemetry, portfolio shifting, and erroneous operations modeled to better predict and avoid potentially crippling problems before they become an issue. Use case example: Predictive Maintenance",
      points: [
        {
          title: "Outcome Prediction",
          paragraph:
            "Models trained by emotion recognition data, speech analysis, and trending performance metrics aimed to identify people and/or processes in your organization most in need of assistance.",
          image: OutcomePrediction,
          link: "/insight/Student-Retention"
        },
        {
          title: "Occupancy Management",
          paragraph:
            "Public health data, climate science, and population trends building models that maximize facility accommodations for organizations in the healthcare and hospitality verticals.",
          image: OccupancyManagement,
          link: "/insight/hospital-BedManagement"
        },
        {
          title: "Anomaly Detection",
          paragraph:
            "Data accrued from system telemetry, portfolio shifting, and erroneous operations modeled to better predict and avoid potentially crippling problems before they become an issue.",
          image: AnomalyDetection,
          link: "/insight/Anomaly-Detection"
        }
      ],
      hashLink: "/4Site#benefits4SiteID"
    }
  },
  ManageX: {
    helmet: [
      {
        title: "Terawe ManageX | Simplified Hybrid Cloud for Industry",
        description:
          "Terawe's ManageX platform services give you a cloud workspace environment for intuitive cloud infrastructure management and utilization.",
        keywords: "abc",
        schema: `
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Your Cloud. Simplified",
            "image": "",
            "description": "Terawe's ManageX platform services give you a cloud workspace environment for intuitive cloud infrastructure management and utilization.",
            "brand": "Terawe"
          }`
      }
    ],
    pageTitle: {
      heading: "Your Cloud. Simplified",
      paragraph:
        "You don’t have to know the cloud <b>in order to be able</b> to use the cloud.",
      link: "/ManageX"
    },
    whatManageX: {
      title: "What is ManageX?",

      paragraph: [
        "You already know what you do and how you do it. So taking advantage of cloud computing & AI platforms shouldn’t distract you away from the core knowledge base that may have taken you years to establish.",
        "<b>ManageX</b> augments your knowledge base by turning complex, archaic cloud control panels into simplified point-and-click experiences, allowing you to keep focused on your core processes while propelling you faster towards your intended outcomes."
      ],
      image: managex,
      hashLink: "/ManageX#whatManageXID"
    },
    manageXOfferings: {
      title: "ManageX Offerings",
      hashLink: "/ManageX#manageXOfferingsID",
      paragraph: [
        "All ManageX offerings benefit from the comprehensive security, resiliency, and compliance of Terawe’s SLA-backed <b>ManageX Cloud Workspaces.</b>",
        'Click <a href=https://managex.terawe.com/ target="_blank">here</a> to go to the ManageX website.'
      ],
      manageXBlogs: [
        {
          image: ManageXResearch,
          title: "Research",
          link: "https://managex.terawe.com/research",
          linkText: "Take Me There!",
          linkText2: "ManageX Research",
          paragraph:
            "Bring the speed, scale, and security of the cloud directly into your academic research workflow…without changing what you do or how you do it."
        },
        {
          image: ManageXAcademy,
          title: "Academy",
          link: "https://managex.academy/",
          linkText: "Take Me There!",
          linkText2: "ManageX Academy",
          paragraph:
            "Build your knowledge base & working experience of game-changing cloud services that can improve productivities and efficiencies for any application."
        },
        {
          image: ManageXHPC,
          title: "HPC",
          link: "https://managex.terawe.com/hpc/i",
          linkText: "Take Me There!",
          linkText2: "ManageX HPC",
          paragraph:
            "Get all the advantages of HPC without the cost of development, deployment, use, and management with ManageX - your HPC solution in the cloud."
        }
      ]
    },
    quotesCustomers: {
      title: "Quotes from Customers",
      hashLink: "/ManageX#quotesCustomersID",
      point: [
        {
          paragraph:
            "”Terawe is a member of my biomedical engineering team: they <b>collaborate actively</b> in my lab’s research.”"
        },
        {
          paragraph:
            "“Terawe <b>captures all the needs of my IT requirements,</b> enabling me to deploy cloud IT infrastructure with just a few clicks.”"
        },
        {
          paragraph:
            "“ManageX Workspaces are <b>a repeatable and compelling enabler</b> for my work in the cloud.”"
        },
        {
          paragraph:
            "“ManageX enables me to <b>monitor and manage the cloud for my entire team.</b>”"
        },
        {
          paragraph:
            "“Because <b>ManageX takes care of all things IT</b>, I can help my team craft their code to exploit sophisticated cloud resources like GPUs and clusters.”"
        }
      ]
    },
    customerStories: {
      title: "Customer Stories",
      hashLink: "/ManageX#customerStoriesID",
      university: [
        {
          image: Yale,
          title: "Cloud Migration Services",
          paragraph:
            "We facilitate the transitioning of both critical and non-critical applications, data sets, and management services to cloud platforms without disruption to your business or compromising your existing security and/or compliance protocols.",
          author: {
            title: "Dr. Gregory Tietjen",
            subTitle:
              "Associate Professor of Principal Investigator Yale School of Medicine"
          },
          story:
            "<b>Yale School of Medicine</b> turned to Microsoft to map out a solution that would address their ongoing challenge of improving usability of donated organs. They wanted a democratized solution that would share all insights and resulting data to any lab - anywhere in the world - that might need them. Microsoft recommended the cloud expert team at <b>Terawe Corporation</b> to design and implement a SaaS-type solution to fulfill this mission.",
          storyLink:
            "https://customers.microsoft.com/en-in/story/1503801187147368221-yale-school-medicine-researchers-use-azure-make-every-organ-count",
          storyLinkText: "Access the full story at Microsoft Customer Stories"
        },
        {
          image: Ireland,
          title: "Application Modernization",
          paragraph:
            "Update and enhance your legacy software applications to align them with current technology standards, utilizing cloud-native design models and microservice  APIs to improve functionality, boost scalability, and increase resilience.",
          story:
            "The Republic of Ireland’s <b>Future is Mine</b> program was born out of the abition to increase STEM learning in both education cirriculums and in students’ everyday lives, using Minecraft: Education Edition as the operational model. But since Ireland lacks a centralized IT management for their school system, getting every student at all 3,300 public K-6 schools setup and running with the appropriate account &amp; license capabilities posed a significant challenge. <b>Terawe’s ManageX Platform</b> was leveraged as the operational solution for all accounts, licenses, support tiers, and multi-channel alert notifications.",
          storyLink:
            "https://education.minecraft.net/en-us/blog/irelands-future-is-mine",
          storyLinkText: "Access the full story at the Microsoft Education Site"
        },
        {
          image: Microsoft,
          title: "Cost Optimization",
          paragraph:
            "Conduct thorough cost analyses of your entire cloud estate and review pre-curated options provided by our AI-powered cost optimizer to determine the most appropriate IT spending decisions for your organization.",
          story:
            "The ability to quickly and effectively demonstrate application and/or platform environments to customer prospects is a key tool in the Microsoft relationship sales chest. Microsoft turned to Gold Partner <b>Terawe Corporation</b> to provide both the captivating demonstration experiences themselves and the means to select, schedule, and deploy any experience anywhere, anytime, on any device. The result was the <b>Immersive Experience Center</b>, a 24/7 web portal that connects Terawe-engineered demonstration environments to Microsoft employees’ calendars and contacts, enabling their sales teams with point-and-click demonstration scheduling, provisioning, and tracking capabilities with their client prospects."
        }
      ]
    }
  }
};
export default SolutionsObject;
