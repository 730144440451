import React from "react";
import bannerImage from "../../assets/img/Offerings/serviceProviderHeader.jpg";
import InnerBanner from "../UiComponents/InnerBanner";
import OfferingsObject from "./OfferingsObject";
import {
  BlockTextCentered,
  ImageContent,
  MetaHelmet,
  TextCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function ServiceProvider() {
  const root = OfferingsObject.ServiceProvider;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <ImageContent
            title={root.TrustedPartner.title}
            titleInner={root.TrustedPartner.title}
            image={root.TrustedPartner.image}
            points={root.TrustedPartner.points}
          />
        </div>
      </section>

      <BlockTextCentered
        title={root.ValueProposition.title}
        paragraph={root.ValueProposition.paragraph}
      />

      <TextCarouselSlick
        bgBlack={true}
        slideScroll={2}
        title={root.OurServices.title}
        points={root.OurServices.points}
      />

      <section className="sectionContainer">
        <div className="container">
          <ImageContent
            title={root.ManagedServices.title}
            titleInner={root.ManagedServices.title}
            image={root.ManagedServices.image}
            points={root.ManagedServices.points}
            learnMore={root.ManagedServices.learnMore}
          />
        </div>
      </section>

      <TextCarouselSlick
        bgBlack={true}
        title={root.CustomersQuotes.title}
        points={root.CustomersQuotes.points}
      />
    </React.Fragment>
  );
}
