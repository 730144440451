import { Link } from "react-router-dom";
// import { TouchIcon } from "./Icons";
import { Fragment, useState } from "react";
import InsightContentObject, {
  InsightCategories
} from "../insights/InsightContentObject";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// modal
import { ArrowForward, Close, Pause, PlayArrow } from "@mui/icons-material";
import { Box, IconButton, Modal } from "@mui/material";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { useRef } from "react";
import { useEffect } from "react";
import { saveAs } from "file-saver";
export const IconBlockVertical = ({ isImage = false, ...props }) => {
  return (
    <section className={`sectionContainer ${props.bgBlack ? "bgBlack" : ""}`}>
      <div className="container">
        <MainTitle title={props.title} />
      </div>
      <div className="container">
        {props.points.map((item, index) => (
          <div
            className={`iconBlockVertical ${
              props.containerCustomClass ? props.containerCustomClass : ""
            }`}
            key={index.toString()}
          >
            {isImage ? (
              <img
                src={item.icon}
                className={props.imagecustomClass ? props.imagecustomClass : ""}
                alt={item.title}
              />
            ) : (
              <div className="iconContainer">{item.icon}</div>
            )}

            <div className="iconContent">
              <h5>
                {!isImage ? item.icon : <></>} {item.title}
              </h5>
              <p dangerouslySetInnerHTML={{ __html: item.paragraph }} />
              {/* {item.link && (
                <Link to={item.link} target="_blank">
                  Source
                </Link>
              )} */}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export const FlipBlock = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={`service-box ${
        props.customContainerClass ? props.customContainerClass : ""
      } ${props.bgImage ? "bgImage" : ""}`}
    >
      <div
        className={`${props.iconimages ? "flipoxwithimage" : ""} ${
          props.iconFront ? "iconsFront" : ""
        } service-icon`}
        style={props.image && { backgroundImage: `url('${props.image}') ` }}
      >
        {props.icon && <h6>{props.icon}</h6>}
        {props.iconimages && <img src={props.iconimages} alt={props.title} />}
        <h3>{props.title}</h3>
        {/* <TouchIcon /> */}
      </div>
      <div className="service-content">
        {props.contentIcon && props.contentIcon}
        {props.iconBack && <img src={props.iconBack} alt={props.title} />}
        {props.paragraph && <p>{props.paragraph}</p>}
        {props.list && (
          <ul>
            {props.list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
        {props.link && (
          <Link to={props.link} target="_blank">
            Learn More
          </Link>
        )}
      </div>
    </div>
  );
};

export const MainTitle = (props) => {
  return (
    <div className="mainTitle">
      <h3 className="title">{props.title}</h3>
      {props.paragraph &&
        (Array.isArray(props.paragraph) ? (
          props.paragraph.map((item, index) => (
            <p
              key={index.toString()}
              dangerouslySetInnerHTML={{ __html: item }}
            ></p>
          ))
        ) : (
          <p dangerouslySetInnerHTML={{ __html: props.paragraph }}></p>
        ))}
    </div>
  );
};

export const ResourcesBlock = (props) => {
  // filtering resources from insights object
  function filterByTitles(objects, titles) {
    return objects.filter((obj) => titles.includes(obj.title));
  }

  const filteredResources = filterByTitles(
    InsightContentObject.Insight.points,
    props.resourceTitle
  );
  // console.log(filteredResources, props.resourceTitle);
  return (
    <Fragment>
      <section className={`sectionContainer ${props.black ? "bgBlack" : ""}`}>
        <div className="container">
          <MainTitle title={props.title} />
        </div>
        <div className="container">
          {filteredResources.map((item, index) => (
            <div className="resourcesContainer" key={index.toString()}>
              <img src={item.image} alt={item.title} />
              <div className="resource">
                <p className="type"> {item.type}</p>
                <h5>{item.title}</h5>
                <p>{item.paragraph}</p>
                <Link to={`/insight${item.link}`}>Read More</Link>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <Link to={"/Insights"} className="btn">
            View All Resources
          </Link>
        </div>
      </section>
    </Fragment>
  );
};

export const InsightsBlocks = (props) => {
  return (
    <Fragment>
      {props.Insights.filter((items) => {
        // Convert both category and search term to lowercase for case-insensitive comparison
        const category = props.currentCategory?.toLowerCase();
        const searchTerm = props.searchInsight?.toLowerCase();

        // Check if the item matches the current category or if "All" is selected
        const matchesCategory =
          category === "all" || items.type?.toLowerCase() === category;

        // Check if the item matches the search term in any relevant field (e.g., title, paragraph)
        const matchesSearch =
          !searchTerm ||
          items.title?.toLowerCase().includes(searchTerm) ||
          items.paragraph?.toLowerCase().includes(searchTerm);

        // Return true if both category and search filters match
        return matchesCategory && matchesSearch;
      }).map((item, index) => (
        <div className="resourcesContainer" key={index.toString()}>
          <img src={item.image} alt={item.title} />
          <div className="resource">
            <p className="type"> {item.type}</p>
            <h5>{item.title}</h5>
            <p className="insight-bodypara">{item.paragraph}</p>
            <Link
              to={`/insight${item.link}${
                item?.type === InsightCategories.whitePaper.title
                  ? `?type=${InsightCategories.whitePaper.title}`
                  : ""
              }`}
            >
              Read More
            </Link>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export const HoverShowContent = ({ isBackGroundImage = false, ...props }) => {
  return (
    <Fragment>
      {props.points.map((item, index) => (
        <div
          style={{
            backgroundImage: isBackGroundImage ? `url(${item.back})` : ""
          }}
          className={`hoverContent ${
            props.customClass ? props.customClass : ""
          }`}
          key={index.toString()}
        >
          {item.secTitle && <h5>{item.secTitle}</h5>}

          {item.image && (
            <img
              className="HoverShowContent-image"
              src={item.image}
              alt={item.title}
            />
          )}
          <h3
            style={{
              width: "100%",
              padding: isBackGroundImage ? "10px" : "0",
              background: isBackGroundImage
                ? `linear-gradient(
              rgba(0, 0, 0, 0.7), 
              rgba(0, 0, 0, 0.7)
            )`
                : ""
            }}
            className={props.customTitleClass}
          >
            {item.title}
          </h3>
          <p
            style={{
              width: "100%",
              padding: isBackGroundImage ? "10px" : "0",
              background: isBackGroundImage
                ? `linear-gradient(
                  rgba(0, 0, 0, 0.7), 
                  rgba(0, 0, 0, 0.7)
                )`
                : ""
            }}
          >
            {item.paragraph}
          </p>
          <Link to={item.link} target="_blank">
            Source
          </Link>
          {/* <TouchIcon /> */}
        </div>
      ))}
    </Fragment>
  );
};

export const TitleParagraphBlock = (props) => {
  return (
    <Fragment>
      {props.points.map((item, index) => (
        <div
          className={`titleParagraphBlock ${
            props.marker ? "yellowBordered" : ""
          }`}
          key={index.toString()}
        >
          <h5>{item.title}</h5>
          <p>{item.paragraph}</p>
        </div>
      ))}
    </Fragment>
  );
};

export const ImageContent = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        classes={{
          backdrop: "contact-us-model-backdrop"
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="imagemodalContainer-root">
          <div className="imgHeader">
            <IconButton className="iconbtn" onClick={handleClose}>
              <Close className="closeIcon" />
            </IconButton>
          </div>
          <img src={props.image} alt={props.title} />
        </Box>
      </Modal>
      <div
        className={`ImageContent ${props.rowReverse ? "rowReverse" : ""} ${
          props.isModalImage ? "ModalImageContent" : ""
        } ${props.customClass ? props.customClass : ""}`}
      >
        <div
          onClick={() => {
            if (props.isModalImage) {
              handleOpen();
            } else {
              return;
            }
          }}
          className={`imageBackground ${
            props.customimageblock ? props.customimageblock : ""
          }`}
          style={{ backgroundImage: `url(${props.image})` }}
        >
          <img
            className={`${props.isModalImage ? "cursor-pointer" : ""} image`}
            src={props.image}
            alt={props.title}
          />
          {props.iconModal && props.iconModal}
        </div>
        <div className={` content`}>
          {props.titleInner && <h5>{props.titleInner}</h5>}
          {props.points.map((item, index) => (
            <p
              dangerouslySetInnerHTML={{ __html: item }}
              key={index.toString()}
            />
          ))}
          {props.list && (
            <ul>
              {props.list.map((item, index) => (
                <li
                  key={index.toString()}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              ))}
            </ul>
          )}
          {props.learnMore &&
            props.learnMore.map((item, index) => (
              <Link to={item.link} key={index.toString()} className="learnMore">
                {item.text}
              </Link>
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export const ImageCustomContent = ({
  BelowTextrender,
  isBelowTextrender = false,
  ...props
}) => {
  return (
    <Fragment>
      <div className="ImageContent-main">
        <div className="container">
          <MainTitle title={props.title} />
        </div>
        <div className={`${props.imagecontentcstm} ImageContent`}>
          {props.image && (
            <img
              className={`${props.customImageClass}`}
              src={props.image}
              alt={props.title}
            />
          )}
          <div className="content">
            {props.points.map((item, index) => (
              <div className="ImageContent-section-container">
                {item?.image && (
                  <img
                    className={`${props.customImageClass} `}
                    src={item.image}
                    alt={item.title}
                  />
                )}
                {item?.title ? (
                  <div className="ImageContent-body-container">
                    <h5
                      className="ImageContent-body-title"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                      key={index.toString()}
                    ></h5>
                    <p
                      className="ImageContent-body"
                      dangerouslySetInnerHTML={{ __html: item.paragraph }}
                      key={index.toString()}
                    ></p>
                    {isBelowTextrender && <BelowTextrender item={item} />}
                  </div>
                ) : (
                  <p
                    dangerouslySetInnerHTML={{ __html: item }}
                    key={index.toString()}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export const ImageCollage = (props) => {
  return (
    <Fragment>
      <section className={`sectionContainer ${props.black ? "bgBlack" : ""}`}>
        <div className="container">
          <MainTitle title={props.title} paragraph={props.paragraph} />
        </div>
        <div className="container">
          <div className="collageImage">
            {props.points.map((item, index) => (
              <div
                className={index === 0 ? "collageBig" : "collage"}
                key={index.toString()}
              >
                <div className="collageText">
                  <h5>{item.title}</h5>
                  <p>{item.paragraph}</p>
                </div>
                <img src={item.image} alt={item.title} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export const BlockTextCentered = (props) => {
  return (
    <Fragment>
      <section
        className={`sectionContainer ${
          props.customContainerClass ? props.customContainerClass : ""
        } ${props.bgBlack ? "bgBlack" : ""}`}
      >
        <div className="container">
          <div className="textCenteredBlock">
            <h4>{props.title}</h4>
            {props.paragraph.map((item, index) => (
              <p key={index.toString()}>{item}</p>
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export const TextButton = (props) => {
  return (
    <section
      className={`sectionContainer ${
        props.customClass ? props.customClass : ""
      }`}
    >
      <div className="container">
        <MainTitle title={props.title} paragraph={props.paragraph} />
      </div>

      <div className="container">
        {props.points.map((item, index) => (
          <div className="textButtonBlock" key={index.toString()}>
            <h5>{item.title}</h5>
            <p dangerouslySetInnerHTML={{ __html: item.paragraph }} />
            <a href={item.link} target="_blank" rel="noreferrer">
              <img src={item.image} alt="Le" />
              <ArrowForward />
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export const TitleImageContentBlock = (props) => {
  return (
    <section className="sectionContainer TitleImageContentBlock bgBlack">
      <div className="container">
        <MainTitle title={props.title} paragraph={props.paragraph} />
      </div>
      {props.points.map((item, index) => (
        <div className="sectionContent" key={index.toString()}>
          <div className="container sectionTitle">{item.title}</div>
          <div className="container textBlocks">
            <div className="image">
              <img src={item.image} alt={item.title} />
            </div>
            <div className="content">
              {item.points.map((list, indexing) => (
                <div className="blocks" key={indexing.toString()}>
                  <h5>{list.title}</h5>
                  <p>{list.paragraph}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export const TextBoxColored = (props) => {
  return (
    <Fragment>
      {props.points.map((item, index) => (
        <div
          className={`textBoxColored ${item.backgroundColor}`}
          key={index.toString()}
        >
          <img src={item.image} alt="abc" />
          <div className="content">
            <h6>{item.title}</h6>
            <p>{item.paragraph}</p>
            {item.link && (
              <Link to={item.link} target="_blank" rel="noreferrer">
                Learn More
              </Link>
            )}
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export const GrayBox = (props) => {
  return (
    <Fragment>
      {props.points.map((item, index) => (
        <div className={`grayBox ${props.boxes}`} key={index.toString()}>
          <h5>{item.title}</h5>
          <p>{item.paragraph}</p>
        </div>
      ))}
    </Fragment>
  );
};

export const PartnersBlock = (props) => {
  // console.log(props);
  return (
    <Fragment>
      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle title={props.title} paragraph={props.paragraph} />
        </div>
        <div className="partnersBlock">
          <div className="container">
            <div className="partnersContainer">
              {props.points.map((item, index) => (
                <Fragment key={index.toString()}>
                  {item.multiple ? (
                    <div className="multipleBlocks">
                      {item.multiple.map((item, index) => (
                        <div
                          className={`titleParagraphBlock ${
                            props.marker ? "yellowBordered" : ""
                          }`}
                          key={item.title + index.toString()}
                        >
                          <h5>{item.title}</h5>
                          <p>{item.paragraph}</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      className={`titleParagraphBlock ${
                        props.marker ? "yellowBordered" : ""
                      }`}
                      key={index.toString()}
                    >
                      <h5>{item.title}</h5>
                      <p>{item.paragraph}</p>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export const HoverSlideUp = (props) => {
  return (
    <Fragment>
      <section className={`sectionContainer ${props.bgBlack ? "bgBlack" : ""}`}>
        <div className={`container`}>
          <MainTitle title={props.title} paragraph={props.paragraph} />
        </div>
        <div className={`container ${props.customClassContainer}`}>
          {props.points.map((item, index) => (
            <div
              className={`${
                props.hoverContainer ? props.hoverContainer : ""
              } hoverSlideUp`}
              key={index.toString()}
            >
              <img src={item.image} alt={item.title} />
              <div className="item__overlay">
                <h5 id="person1" aria-hidden="true">
                  {item.title}
                </h5>
                <div className="item__body">
                  <p>{item.paragraph}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Fragment>
  );
};
export const AccordionPanel = (props) => {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Fragment>
      <section
        className={`sectionContainer ${
          props.black ? "bgBlack" : ""
        } accordionPanel`}
      >
        <div className="container">
          <MainTitle title={props.title} paragraph={props.paragraph} />
        </div>
        <div className="container">
          {props.points.map((item, index) => (
            <Accordion
              key={index.toString()}
              expanded={expanded === `panel${index + 1}`}
              onChange={handleChange(`panel${index + 1}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}bh-content`}
                id={`panel${index + 1}bh-header`}
              >
                <h5>{item.title}</h5>
              </AccordionSummary>
              <AccordionDetails>
                <div className="content">
                  <div>
                    <h5>{props.section}</h5>
                    <p>{item.paragraph}</p>
                  </div>
                  <div>
                    <h5>{props.offer}</h5>
                    {Array.isArray(item.offer) ? (
                      <ul>
                        {item.offer.map((item, index) => (
                          <li key={index.toString() + item}>{item}</li>
                        ))}
                      </ul>
                    ) : (
                      <p>{item.offer}</p>
                    )}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </section>
    </Fragment>
  );
};

export const SolutionsBlock = (props) => {
  return (
    <Fragment>
      <section className={`sectionContainer ${props.bgBlack ? "bgBlack" : ""}`}>
        <div className="container">
          <MainTitle title={props.title} paragraph={props.paragraph} />
        </div>
        <div
          className={`container cloudSolution ${
            props.customClass ? props.customClass : ""
          }`}
        >
          {props.points.map((item, index) => (
            <div
              className={`solutionCon ${
                item.customClass ? item.customClass : ""
              }`}
              key={index.toString()}
            >
              <img src={item.image} alt="" />
              <p>{item.paragraph}</p>
              <Link to={item.link} className="btnGray">
                Learn More
              </Link>
            </div>
          ))}
        </div>
      </section>
    </Fragment>
  );
};

export const TextCarouselSlick = (props) => {
  const [playButton, setPlayButton] = useState(false);
  let sliderRef = useRef(null);
  const play = () => {
    sliderRef.slickPlay();
  };
  const pause = () => {
    sliderRef.slickPause();
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: props.slideScroll ? props.slideScroll : 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1195,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Fragment>
      <section className={`sectionContainer ${props.bgBlack ? "bgBlack" : ""}`}>
        <div className="container">
          <MainTitle title={props.title} paragraph={props.paragraph} />
        </div>

        <div className="container containerBlock slickContainer">
          <Slider
            ref={(slider) => (sliderRef = slider)}
            {...settings}
            className="textCarouselSlick"
          >
            {props.points.map((item, index) => (
              <div key={index} className="slickCon">
                {item.title && <h3>{item.title}</h3>}
                {item.paragraph ? (
                  <p dangerouslySetInnerHTML={{ __html: item.paragraph }}></p>
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: item }}></p>
                )}
              </div>
            ))}
          </Slider>
          <div
            className="playPause"
            onClick={() => setPlayButton((prevState) => !prevState)}
          >
            {playButton ? (
              <PlayArrow onClick={play} />
            ) : (
              <Pause onClick={pause} />
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export const VerticalCarouselSlick = (props) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  const navSettings = {
    slidesToShow: props.points.length,
    swipeToSlide: false,
    focusOnSelect: true,
    infinite: false,
    arrows: false,
    touchMove: false,
    asNavFor: nav1,
    className: "sliderNav",
    ref: (slider) => (sliderRef2 = slider)
  };

  const slideSettings = {
    asNavFor: nav2,
    fade: true,
    ref: (slider) => (sliderRef1 = slider),
    arrows: false,
    className: "sliderMain",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: true
        }
      }
    ]
  };

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);
  return (
    <section className="sectionContainer slickSolution">
      <div className="container">
        <MainTitle title={props.title} paragraph={props.paragraph} />
      </div>
      <div className="container containerBlock">
        <div className="SlickVerticalSlider">
          <Slider {...navSettings}>
            {props.points.map((item, index) => (
              <div key={index}>{item.title}</div>
            ))}
          </Slider>
          <Slider {...slideSettings}>
            {props.points.map((item, index) => (
              <div key={index}>
                <img src={item.image} alt={item.title} />
                <h5>{item.title}</h5>
                <p dangerouslySetInnerHTML={{ __html: item.paragraph }} />
                {item.link && (
                  <Link to={item.link} className="links">
                    Learn More
                  </Link>
                )}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export const TitledCarouselSlick = (props) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  const navSettings = {
    slidesToShow: props.points.length,
    swipeToSlide: false,
    focusOnSelect: true,
    infinite: false,
    arrows: false,
    touchMove: false,
    asNavFor: nav1,
    className: "sliderNav",
    ref: (slider) => (sliderRef2 = slider)
  };

  const slideSettings = {
    asNavFor: nav2,
    ref: (slider) => (sliderRef1 = slider),
    arrows: false,
    className: "slickTitleCon",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          asNavFor: false,
          dots: true
        }
      }
    ]
  };

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);
  return (
    <section className="sectionContainer slickSolution">
      <div className="container">
        <MainTitle title={props.title} />
      </div>
      <div className="slickTitleNav">
        <div className="container containerBlock">
          <Slider {...navSettings}>
            {props.points.map((item, index) => (
              <div key={index}>{item.title}</div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="container containerBlock">
        <Slider {...slideSettings}>
          {props.points.map((item, index) => (
            <div key={index}>
              <img src={item.image} alt={item.title} />
              <div className="slideTitleContent">
                <h5>{item.title}</h5>
                <p dangerouslySetInnerHTML={{ __html: item.paragraph }} />
                {item.keywords && (
                  <ul className="sliderKeywords">
                    {item.keywords.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                )}
                {item.list && (
                  <ul className="carousalList">
                    {item.list.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                )}
                {item.link && (
                  <Link to={item.link} className="links">
                    Learn More
                  </Link>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export const UniversitySlick = (props) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Fragment>
      <section className={`sectionContainer ${props.bgBlack ? "bgBlack" : ""}`}>
        <div className="container">
          <MainTitle title={props.title} paragraph={props.paragraph} />
        </div>

        <div className="container containerBlock">
          <Slider {...settings} className="UniversitySlick">
            {props.points.map((item, index) => (
              <div key={index}>
                <div className="imageText">
                  <div className="text">
                    <h5>{item.title}</h5>
                    <p>“{item.paragraph}”</p>
                    {item.author && (
                      <blockquote>
                        <h6>{item.author.title}</h6>
                        <p>{item.author.subTitle}</p>
                      </blockquote>
                    )}
                  </div>
                  <img src={item.image} alt={item.title} />
                </div>
                <p
                  className="details"
                  dangerouslySetInnerHTML={{ __html: item.story }}
                />
                {item.storyLink && (
                  <Link to={item.storyLink} target="_blank">
                    {item.storyLinkText}
                  </Link>
                )}
              </div>
              // <div key={index} className="slickCon">
              //   {item.title && <h3>{item.title}</h3>}
              //   {item.paragraph ? (
              //     <p dangerouslySetInnerHTML={{ __html: item.paragraph }}></p>
              //   ) : (
              //     <p dangerouslySetInnerHTML={{ __html: item }}></p>
              //   )}
              // </div>
            ))}
          </Slider>
        </div>
      </section>
    </Fragment>
  );
};

export const PartnersLogoSlick = (props) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: props.slideScroll ? props.slideScroll : 2,
    responsive: [
      {
        breakpoint: 1195,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Fragment>
      <section className={`sectionContainer logoCarouselSlick`}>
        <div className="container">
          <MainTitle title={props.title} paragraph={props.paragraph} />
        </div>

        <div className="container containerBlock">
          <Slider {...settings}>
            {props.images.map((item, index) => (
              <div key={index} className="slickCon">
                <img src={item} alt="logos" />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </Fragment>
  );
};

export const MetaHelmet = (props) => {
  return (
    <Fragment>
      {props.helmet.map((item, index) => (
        <Helmet key={index}>
          <title>{item.title}</title>
          <meta name="description" content={item.description} />
          {/* inline script elements */}
          {props.schema && item.schema && (
            <script type="application/ld+json">{`
              
                  ${item.schema}
              
            `}</script>
          )}
          {/* <meta name="keywords" content={item.keywords} /> */}
        </Helmet>
      ))}
    </Fragment>
  );
};

export const DownloadPDF = ({ filePath, fileName, text }) => {
  const downloadFile = () => {
    saveAs(filePath, fileName);
  };
  return (
    <Fragment>
      <button onClick={downloadFile} className="downloadButton">
        {text ? text : "Download"}
      </button>
    </Fragment>
  );
};

// export const TitleParagraphBlock = (props) => {
//   return(
//     <Fragment>
//       {props.points.map((item, index) => (

//       ))}
//     </Fragment>
//   )
// }

// Panel Title
/* <div className="title">
                <h5>Design & Development</h5>
                <div>
                  <h6>Dependencies</h6>
                  <p>
                    New product concepts, creating specifications, industrial
                    design, prototyping for refinement and adjustment, and
                    research for product improvement
                  </p>
                </div>
                <div>
                  <h6>What we Offer</h6>
                  <p>
                    3D remote visualization, cloud-scale acceleration for CFD
                    and FEA apps, and IoT & digital twins for simulated
                    prototyping
                  </p>
                </div>
              </div> */
