import { Fragment, useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";
import { ContentCopy } from "@mui/icons-material";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";

export default function PopUp(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openBar, setOpenBar] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleCopyText = () => {
    navigator.clipboard.writeText(props.modalText);
    setAnchorEl(null);
    setOpenBar(true);
  };

  const open = Boolean(anchorEl);

  // on mount, listen to scroll event on any element in the page (document, and its child)
  // if scroll is triggered, close the popover
  useEffect(() => {
    document.addEventListener("scroll", handlePopoverClose, true);
  }, []);

  // on unmount, remove the scroll event listener
  useEffect(
    () => () => {
      document.removeEventListener("scroll", handlePopoverClose, true);
    },
    []
  );

  return (
    <Fragment>
      <p
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
      >
        {props.text.replace("Terawe", "")}
      </p>
      <Popover
        id="mouse-over-popover"
        className="popOver"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        <div className="popUpFooter">
          <h5>
            {props.text
              .replace("United States", "USA")
              .replace("United Kingdom", "UK")}
          </h5>
          <p>{props.modalText}</p>
          <ContentCopy onClick={handleCopyText} />
        </div>
      </Popover>
      <Snackbar
        open={openBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => setOpenBar(false)}
      >
        <Alert
          onClose={() => setOpenBar(false)}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          Text Copied
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
