import React from "react";
import bannerImage from "../../assets/img/Offerings/ManagedServices-Header.jpg";
import TieredSupport from "../../assets/img/Offerings/multTieredSupport.png";
import InnerBanner from "../UiComponents/InnerBanner";
import OfferingsObject from "./OfferingsObject";
import {
  BlockTextCentered,
  ImageContent,
  MainTitle,
  MetaHelmet
} from "../UiComponents/ContentBlocks";

export default function ManagedServices() {
  const root = OfferingsObject.ManagedServices;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <ImageContent
            title={root.SharingTheLoad.title}
            titleInner={root.SharingTheLoad.title}
            image={root.SharingTheLoad.image}
            points={root.SharingTheLoad.points}
          />
        </div>
      </section>

      <BlockTextCentered
        title={root.ValueProposition.title}
        paragraph={root.ValueProposition.paragraph}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <ImageContent
            rowReverse={true}
            title={root.OurServices.title}
            titleInner={root.OurServices.title}
            image={root.OurServices.image}
            points={root.OurServices.points}
            list={root.OurServices.list}
          />
        </div>
      </section>

      <section className="sectionContainer">
        <div className="container">
          <ImageContent
            title={root.ManagedService.title}
            titleInner={root.ManagedService.title}
            image={root.ManagedService.image}
            points={root.ManagedService.points}
          />
        </div>
      </section>

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle title={"Multi-tiered Support"} />
          <img src={TieredSupport} alt="Multi-tiered Support" />
        </div>
      </section>
    </React.Fragment>
  );
}
