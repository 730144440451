import React, { useEffect, useState } from "react";
import {
  API_KIND,
  BASE_URL,
  formKeys,
  job_form_id,
  orgId
} from "../../utils/config";
import axios from "axios";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";
import { Fields } from "../../Components/Contactus";
import { useForm } from "react-hook-form";
import { CircularProgress, TextField, Typography } from "@mui/material";
import { Fragment } from "react";

const Applyjobform = ({ handleClose, handleSnackOpen, selectedItem }) => {
  const [formObjct, setFormObject] = useState(null);
  const [token, setToken] = useState("");
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);
  const [isLoadingForm, setIsloadingForm] = useState();
  const refreshReCaptcha = false;
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {},
    mode: "onChange"
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      formData.append(item, data[item] ? data[item] : "");
    });
    formData.append("jobId", selectedItem.id);
    formData.append("captchaToken", token);
    setIsLoadingSubmission(true);
    axios
      .post(`${BASE_URL}/org/${orgId}/form/${job_form_id}`, formData)
      .then((res) => {
        if (res.data.code === 0) {
          handleClose();
          handleSnackOpen();
          setIsLoadingSubmission(false);
        }
      })
      .catch((err) => {
        setIsLoadingSubmission(false);
      });
  };

  useEffect(() => {
    setIsloadingForm(true);
    axios
      .post(BASE_URL, {
        kind: API_KIND.webForm,
        data: {
          formKey: formKeys.jobApplyForm,
          orgId: orgId
        }
      })
      .then((res) => {
        setFormObject(res.data);
        var formFields = res.data?.data?.content?.fields;

        reset(
          formFields
            .filter(
              (ele) =>
                ele.type !== "FILE" ||
                ele.type !== "CAPTCHA" ||
                ele.hidden !== true
            )
            .reduce((acc, ele) => {
              acc[ele.key] = "";
              return acc;
            }, {})
        );
        setIsloadingForm(false);
      })
      .catch((err) => {
        setIsloadingForm(false);
        console.error(err);
      });
  }, [reset]);
  return (
    <Fragment>
      <h5>Application Form</h5>
      <GoogleReCaptchaProvider
        reCaptchaKey={"6LfOrvUpAAAAAJS1_A4Vs6dSjk6HTF0xZJZBAH0l"}
      >
        {isLoadingForm ? (
          <div className="align-block-middle">
            <CircularProgress />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
            {formObjct?.data &&
              Object.values(formObjct?.data?.content?.fields).map(
                (item, index) => {
                  return item.key === "jobId" ? (
                    <div className="field-container" key={index.toString()}>
                      <TextField
                        value={selectedItem?.role}
                        variant="standard"
                        className="custom-textfield"
                        label={"JOB ROLE"}
                      />
                    </div>
                  ) : (
                    <div className="field-container" key={index.toString()}>
                      <Fields
                        control={control}
                        errors={errors}
                        name={item.key}
                        setError={setError}
                        required={item.required}
                        cstmlabel={item.label}
                        validation={item.validation}
                        type={item.type}
                      />
                      {errors[item.key] && (
                        <Typography variant="error">
                          {errors[item.key].message}
                        </Typography>
                      )}
                    </div>
                  );
                }
              )}
            <div className="form-submit-btn">
              <button className="button-bordered-white" type="submit">
                <span className="inner z-index--2 relative">
                  {isLoadingSubmission ? (
                    <CircularProgress size={25} />
                  ) : (
                    "Submit"
                  )}
                </span>
                <span className="hover"></span>
              </button>

              <GoogleReCaptcha
                className="google-recaptcha-custom-class"
                onVerify={(token) => {
                  setToken(token);
                }}
                refreshReCaptcha={refreshReCaptcha}
              />
            </div>
          </form>
        )}
      </GoogleReCaptchaProvider>
    </Fragment>
  );
};

export default Applyjobform;
