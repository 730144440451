import React from "react";
import bannerImage from "../../assets/img/IoT/IoT-Banner.jpg";
import SpecialitiesContentObject from "./SpecialitiesContentObject";
import InnerBanner from "../UiComponents/InnerBanner";
import ValueProposition from "../UiComponents/ValueProposition";
import {
  MainTitle,
  MetaHelmet,
  ResourcesBlock,
  TextCarouselSlick,
  TitledCarouselSlick,
  TitleParagraphBlock
} from "../UiComponents/ContentBlocks";

export default function Iot() {
  const root = SpecialitiesContentObject.IoT;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />

      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <ValueProposition
        image={root.valueProps.image}
        title={root.valueProps.title}
        paragraph={root.valueProps.paragraph}
        imageDetails={root.valueProps.imageDetails}
      />

      <TextCarouselSlick
        bgBlack
        slideScroll={2}
        title={root.DigitalTwins.title}
        points={root.DigitalTwins.points}
      />

      <section className="sectionContainer">
        <div className="container">
          <MainTitle title={root.Terawe.title} />
        </div>
        <div className="container">
          <TitleParagraphBlock marker points={root.Terawe.points} />
        </div>
      </section>

      <TitledCarouselSlick
        title={root.solutions.title}
        points={root.solutions.points}
      />

      <ResourcesBlock
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
