import React from "react";
import StudiosContentObject from "./StudiosContentObject";
import InnerBanner from "../UiComponents/InnerBanner";
import bannerImage from "../../assets/img/studio/Studios-DataLab-MainHeader.jpg";
import ValueProposition from "../UiComponents/ValueProposition";
import WrapValueProposition from "../UiComponents/WrapValueProposition";
import { MetaHelmet } from "../UiComponents/ContentBlocks";

export default function DataLab() {
  const root = StudiosContentObject.DataLab;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
      />
      <WrapValueProposition
        image={root.dataEngineering.image}
        isOntop={true}
        title={root.dataEngineering.title}
        paragraph={root.dataEngineering.paragraph}
        list={root.dataEngineering.points}
      />
      <WrapValueProposition
        image={root.bigData.image}
        isOntop={true}
        customContainer={"agilePipelines"}
        mainContainerClass="bgDark"
        title={root.bigData.title}
        paragraph={root.bigData.paragraph}
        list={root.bigData.points}
      />
      <WrapValueProposition
        image={root.governanceCompliance.image}
        isOntop={true}
        title={root.governanceCompliance.title}
        paragraph={root.governanceCompliance.paragraph}
        list={root.governanceCompliance.points}
      />
      <ValueProposition
        image={root.visualizationReporting.image}
        isOntop={true}
        // customtextcontainer="flipped-text-container"
        mainContainerClass="bgDark"
        title={root.visualizationReporting.title}
        paragraph={root.visualizationReporting.paragraph}
        list={root.visualizationReporting.points}
      />
    </React.Fragment>
  );
}
