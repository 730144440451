import React from "react";
import StudiosContentObject from "./StudiosContentObject";
import InnerBanner from "../UiComponents/InnerBanner";
import bannerImage from "../../assets/img/studio/Studios-GreenRoomMainHeader.jpg";

import ValueProposition from "../UiComponents/ValueProposition";
import { MetaHelmet } from "../UiComponents/ContentBlocks";

export default function GreenRoom() {
  const root = StudiosContentObject.GreenRoom;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
      />
      <ValueProposition
        image={root.GreenTransformation.image}
        // isOntop={true}
        title={root.GreenTransformation.title}
        paragraph={root.GreenTransformation.paragraph}
        list={root.GreenTransformation.points}
      />
      <ValueProposition
        image={root.AccountableESG.image}
        isOntop={true}
        customConiainer="flip"
        customtextcontainer="flipped-text-container"
        mainContainerClass="bgDark"
        title={root.AccountableESG.title}
        paragraph={root.AccountableESG.paragraph}
        list={root.AccountableESG.points}
      />
      <ValueProposition
        image={root.EnergyEfficientComputing.image}
        isOntop={true}
        title={root.EnergyEfficientComputing.title}
        paragraph={root.EnergyEfficientComputing.paragraph}
        list={root.EnergyEfficientComputing.points}
      />
      <ValueProposition
        image={root.EnvironmentalImpactBlueprints.image}
        // isOntop={true}
        mainContainerClass="bgDark"
        customConiainer="flip"
        customtextcontainer="flipped-text-container"
        title={root.EnvironmentalImpactBlueprints.title}
        paragraph={root.EnvironmentalImpactBlueprints.paragraph}
        list={root.EnvironmentalImpactBlueprints.points}
      />
      <ValueProposition
        image={root.SustainabilitySimulations.image}
        // isOntop={true}
        title={root.SustainabilitySimulations.title}
        paragraph={root.SustainabilitySimulations.paragraph}
        list={root.SustainabilitySimulations.points}
      />
    </React.Fragment>
  );
}
