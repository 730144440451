const WrapValueProposition = ({ isOntop = true, ...props }) => {
  return (
    <div className="valuePropsContainer wrapvaluePropsContainer">
      <section
        className={`${props.mainContainerClass} services-intro bgImageBlock relative view-detect`}
      >
        {props.title && isOntop && (
          <div className={`container headContainer`}>
            <hgroup className="view-detect">
              <h3
                className="section-subtitle"
                dangerouslySetInnerHTML={{ __html: props.title }}
              />
            </hgroup>
            <div
              className={`${
                props.customParagraphClasses ? props.customParagraphClasses : ""
              } copy`}
              dangerouslySetInnerHTML={{ __html: props.paragraph }}
            ></div>
          </div>
        )}
        <div
          className={`container ${props.customContainer} wrap-text-around-image`}
        >
          <div className="col left">
            {/* <div className="media media--1 paroller-wrap">
              <div
                className="image paroller"
                style={{ backgroundImage: `url(${props.image})` }}
              ></div>
            </div> */}
            <img className="wrap-value-image" src={props.image} alt={props.title} />
            {props.imageDetails && (
              <p className="imageDetails">{props.imageDetails}</p>
            )}
          </div>
          {props.list && (
            <p
              className={`copy`}
              dangerouslySetInnerHTML={{ __html: props.list }}
            ></p>
          )}
        </div>
      </section>
    </div>
  );
};

export default WrapValueProposition;
