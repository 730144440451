import React from "react";
import bannerImage from "../../assets/img/Manufacturing/Manufacturing_Challenges_Img03.jpg";
import IndustriesObject from "./IndustriesObject";
import InnerBanner from "../UiComponents/InnerBanner";
import {
  AccordionPanel,
  FlipBlock,
  ImageCollage,
  MainTitle,
  MetaHelmet,
  PartnersBlock,
  ResourcesBlock,
  SolutionsBlock,
  VerticalCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function Manufacturing() {
  const root = IndustriesObject.Manufacturing;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <ImageCollage
        title={root.Industry.title}
        paragraph={root.Industry.paragraph}
        points={root.Industry.points}
      />

      <AccordionPanel
        black={true}
        title={root.Manufacturers.title}
        paragraph={root.Manufacturers.paragraph}
        section={root.Manufacturers.section}
        offer={root.Manufacturers.offer}
        points={root.Manufacturers.points}
      />

      <section className="sectionContainer collageFlipBox">
        <div className="container">
          <MainTitle
            title={root.Challenges.title}
            paragraph={root.Challenges.paragraph}
          />
        </div>
        <div className="container">
          {root.Challenges.points.map((item, index) =>
            item.image ? (
              <img key={index.toString()} src={item.image} alt={index.toString()} />
            ) : item.multiple ? (
              <div key={index.toString()} className="multipleItems">
                {item.multiple.map((item, idx) => (
                  <FlipBlock
                    key={idx.toString()}
                    title={item.title}
                    paragraph={item.paragraph}
                    icon={item.icon}
                  />
                ))}
              </div>
            ) : (
              <FlipBlock
                key={index.toString()}
                title={item.title}
                paragraph={item.paragraph}
                icon={item.icon}
              />
            )
          )}
        </div>
      </section>

      <VerticalCarouselSlick
        title={root.Solution.title}
        paragraph={root.Solution.paragraph}
        points={root.Solution.points}
      />

      <PartnersBlock title={root.Partner.title} points={root.Partner.points} />

      <SolutionsBlock title={root.Terawe.title} points={root.Terawe.points} />

      <ResourcesBlock
        black={true}
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
