import React from "react";
import bannerImage from "../../assets/img/Energy/EnergyHeader.jpg";
import IndustriesObject from "./IndustriesObject";
import InnerBanner from "../UiComponents/InnerBanner";
import {
  AccordionPanel,
  ImageContent,
  MainTitle,
  MetaHelmet,
  PartnersBlock,
  ResourcesBlock,
  SolutionsBlock,
  TitleParagraphBlock,
  VerticalCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function Energy() {
  const root = IndustriesObject.Energy;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <section className="sectionContainer">
        <div className="container">
          <ImageContent
            titleInner={root.Powering.title}
            image={root.Powering.image}
            points={root.Powering.paragraph}
          />
        </div>
      </section>

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle
            title={root.Transitioning.title}
            paragraph={root.Transitioning.paragraph}
          />
        </div>
        <div className="container">
          <TitleParagraphBlock
            marker={true}
            points={root.Transitioning.points}
          />
        </div>
      </section>

      <AccordionPanel
        title={root.Energy.title}
        paragraph={root.Energy.paragraph}
        section={root.Energy.section}
        offer={root.Energy.offer}
        points={root.Energy.points}
      />

      <VerticalCarouselSlick
        title={root.Solution.title}
        paragraph={root.Solution.paragraph}
        points={root.Solution.points}
      />

      <PartnersBlock title={root.Partner.title} points={root.Partner.points} />

      <SolutionsBlock title={root.Terawe.title} points={root.Terawe.points} />

      <ResourcesBlock
        black={true}
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
