import { Close } from "@mui/icons-material";
import { Box, IconButton, Modal } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { MainTitle } from "./ContentBlocks";

const ValueProposition = ({
  isOntop = false,
  isModalImage = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        classes={{
          backdrop: "contact-us-model-backdrop"
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="imagemodalContainer-root">
          <div className="imgHeader">
            <IconButton className="iconbtn" onClick={handleClose}>
              <Close className="closeIcon" />
            </IconButton>
          </div>
          <img src={props.image} alt={props.title} />
        </Box>
      </Modal>
      <div className="valuePropsContainer ">
        <section
          className={`${
            props.mainContainerClass ? props.mainContainerClass : ""
          } services-intro  bgImageBlock relative view-detect `}
        >
          {props.title && isOntop && (
            <div className="container">
              <MainTitle title={props.title} paragraph={props.subTitle} />
            </div>
          )}
          <div
            className={`container ${props.customConiainer} ${
              props.rowReverse ? "rowReverse" : ""
            }`}
          >
            <div className="col left">
              <div className="media media--1 paroller-wrap">
                <img
                  onClick={() => {
                    if (isModalImage) {
                      handleOpen();
                    }
                  }}
                  className={`${isModalImage ? "cursor-pointer" : ""} image`}
                  src={props.image}
                  alt={props.imageDetails}
                />
                {isModalImage && (
                  <div
                    onClick={() => {
                      if (isModalImage) {
                        handleOpen();
                      }
                    }}
                  >
                    {props.iconModal}
                  </div>
                )}
                {/* <div
                className="image paroller"
                style={{ backgroundImage: `url(${props.image})` }}
              ></div> */}
              </div>
              {props.imageDetails && (
                <p className="imageDetails">{props.imageDetails}</p>
              )}
            </div>
            <div className={`${props.customtextcontainer} col right`}>
              {props.title && !isOntop && (
                <hgroup className="view-detect">
                  <h3
                    className="section-subtitle"
                    dangerouslySetInnerHTML={{ __html: props.title }}
                  />
                </hgroup>
              )}

              {props.paragraph.map((item, index) => (
                <div
                  className={`${
                    props.customParagraphClasses
                      ? props.customParagraphClasses
                      : ""
                  } copy`}
                  dangerouslySetInnerHTML={{ __html: item }}
                  key={index.toString()}
                />
              ))}
              {props.list && (
                <ul>
                  {props.list.map((item, index) => (
                    <li key={index.toString()}>{item}</li>
                  ))}
                </ul>
              )}
              {props.link && (
                <Link to={props.link.url} target={props.link.target}>
                  {props.link.text}
                </Link>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default ValueProposition;
