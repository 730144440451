import React from "react";
import { AboutUsObject } from "./AboutUsObject";
import InnerBanner from "../UiComponents/InnerBanner";
import banner from "../../assets/img/aboutUs/AboutUs_CultureDiversity_MainBanner.jpg";
import {
  DownloadPDF,
  FlipBlock,
  ImageContent,
  MainTitle,
  MetaHelmet
} from "../UiComponents/ContentBlocks";
import TeraweDEIStatement from "../../assets/img/aboutUs/TeraweDEIStatement.pdf";
import Worldvision from "../../assets/img/logos/Worldvision_Logo.png";
import Microsoft from "../../assets/img/logos/Microsoft_Logo.png";
import Terawe from "../../assets/img/logos/Terawe_logo.png";
import RTE from "../../assets/img/logos/RTELogo.png";
import DreamSpace from "../../assets/img/logos/DreamSpaceLogo.png";
import Minecraft from "../../assets/img/logos/MinecraftLogo.png";
import manageX from "../../assets/img/logos/ManageXLogo.png";

export default function CultureDiversity() {
  const root = AboutUsObject.cultureAndDiversity;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={banner}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
        featureParagraphClass="titleCustomParagraph"
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle
            title={root.teraweIsProudToFacilitate.title}
            paragraph={root.teraweIsProudToFacilitate.paragraph}
          />
        </div>
        <div className={`container terawe-pride-container`}>
          {/* <HoverShowContent
            isBackGroundImage={true}
            customClass={"threeColumn aboutus"}
            points={root.teraweIsProudToFacilitate.points}
          /> */}
          {root.teraweIsProudToFacilitate.points.map((item, index) => (
            <FlipBlock
              bgImage
              customContainerClass={"three"}
              iconBack={item.image}
              image={item.back}
              title={item.title}
              key={index.toString()}
              paragraph={item.paragraph}
              // link={item.link}
            />
          ))}
        </div>
      </section>

      <section className="sectionContainer">
        <div className="container">
          <MainTitle title={root.makesusStronger.title} />
        </div>
        <div className="container">
          <div className="ImageContent">
            <div
              className="imageBackground "
              style={{ backgroundImage: `url(${root.makesusStronger.image})` }}
            />
            <div className="content">
              {root.makesusStronger.paragraph.map((item, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
              ))}
              <div className="aboutCampaign">
                <img
                  src={root.makesusStronger.Campaign.image}
                  alt={root.makesusStronger.title}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: root.makesusStronger.Campaign.paragraph
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle title={root.commitment.title} />
        </div>
        <div className="container">
          <ImageContent
            rowReverse
            image={root.commitment.image}
            points={root.commitment.paragraph}
            list={root.commitment.list}
          />
        </div>
        <div className="container">
          <p className="aboutPdfDownload">
            Click{" "}
            <DownloadPDF
              filePath={TeraweDEIStatement}
              fileName="TeraweDEIStatement.pdf"
              text="here"
            />{" "}
            to download a copy of our official DEI Statement (PDF)
          </p>
        </div>
      </section>

      <section className="sectionContainer">
        <div className="container">
          <MainTitle title={root.Achievement.title} />
        </div>
        <div className="container">
          <ImageContent
            customClass="imageContained"
            image={root.Achievement.image}
            points={root.Achievement.paragraph}
          />
        </div>
      </section>

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle title={root.Literacy.title} />
        </div>
        <div className="container">
          <div className="ImageContent rowReverse">
            <div
              className="imageBackground "
              style={{ backgroundImage: `url(${root.Literacy.image})` }}
            />
            <div className="content">
              {root.Literacy.paragraph.map((item, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
              ))}
              <div className="aboutImages">
                {root.Literacy.images.map((item, index) => (
                  <img key={index} src={item} alt={root.Literacy.title} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="logosImageContent">
          <div className="licLine"></div>
          <div className="container">
            <img src={Worldvision} alt="..." />
            <img src={Microsoft} alt="..." />
            <img src={Terawe} alt="..." />
          </div>
        </div>
      </section>

      <section className="sectionContainer ">
        <div className="container">
          <MainTitle title={root.creatingoppertunityeducation.title} />
        </div>
        <div className="container">
          <div className="ImageContent">
            <div
              className="imageBackground "
              style={{
                backgroundImage: `url(${root.creatingoppertunityeducation.image})`
              }}
            />
            <div className="content">
              {root.creatingoppertunityeducation.paragraph.map(
                (item, index) => (
                  <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
                )
              )}
            </div>
          </div>
        </div>
        <div className="logosImageContent">
          <div className="licLine"></div>
          <div className="container">
            <img src={DreamSpace} alt="..." />
            <img src={Minecraft} alt="..." />
            <img src={RTE} alt="..." />
            <img src={manageX} alt="..." />
          </div>
        </div>
      </section>

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle title={root.communityhealth.title} />
        </div>
        <div className="container">
          <div className="ImageContent rowReverse">
            <div
              className="imageBackground "
              style={{ backgroundImage: `url(${root.communityhealth.image})` }}
            />
            <div className="content">
              {root.communityhealth.paragraph.map((item, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
              ))}
              <div className="aboutImages small">
                {root.communityhealth.images.map((item, index) => (
                  <img
                    key={index}
                    src={item}
                    alt={root.communityhealth.title}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionContainer">
        <div className="container">
          <MainTitle title={root.youthProgramSupport.title} />
        </div>
        <div className="container">
          <div className="ImageContent imageContained">
            <div
              className="imageBackground "
              style={{
                backgroundImage: `url(${root.youthProgramSupport.image})`
              }}
            />
            <div className="content">
              {root.youthProgramSupport.paragraph.map((item, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
              ))}
              <div
                className="aboutImages small"
                style={{ justifyContent: "flex-end" }}
              >
                {root.youthProgramSupport.images.map((item, index) => (
                  <img
                    key={index}
                    src={item}
                    alt={root.youthProgramSupport.title}
                    className="lakeLogo"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
