import Education from "../Pages/Industries/Education";
import Energy from "../Pages/Industries/Energy";
import Finance from "../Pages/Industries/Finance";
import Healthcare from "../Pages/Industries/Healthcare";
import Manufacturing from "../Pages/Industries/Manufacturing";
// import PublicSector from "../Pages/Industries/PublicSector";
import Telecommunications from "../Pages/Industries/Telecommunications";
import Ai from "../Pages/Specialities/Ai";
import Hpc from "../Pages/Specialities/Hpc";
import Iot from "../Pages/Specialities/Iot";
import Productivity from "../Pages/Specialities/Productivity";
import InnovationCenter from "../Pages/Studios/InnovationCenter";
import DataLab from "../Pages/Studios/DataLab";
import DesignStudio from "../Pages/Studios/DesignStudio";
import HpcStudio from "../Pages/Studios/HpcStudio";
import GreenRoom from "../Pages/Studios/GreenRoom";
import Culture from "../Pages/about/WhoWeAre";
import Offices from "../Pages/about/CultureDiversity";
import OperationsCenter from "../Pages/Studios/OperationsCenter";
import Impact from "../Pages/Solutions/Galieye";
import Managex from "../Pages/Solutions/Managex";
import Careers from "../Pages/about/careers";
import Insights from "../Pages/insights/insights";
import CloudComputing from "../Pages/Specialities/CloudComputing";
import EdgeComputing from "../Pages/Specialities/EdgeComputing";
import BigData from "../Pages/Specialities/BigData";
import Foursite from "../Pages/Solutions/Foursite";
import ServiceProvider from "../Pages/Offerings/ServiceProvider";
import ManagedServices from "../Pages/Offerings/ManagedServices";
import ConsultingServices from "../Pages/Offerings/ConsultingServices";
import VehicleEngineering from "../Pages/Industries/VehicleEngineering";
import Retail from "../Pages/Industries/Retail";
import PublicSector from "../Pages/Industries/PublicSector";
// import Government from "../Pages/Industries/Government";


export const BASE_URL = process.env.REACT_APP_SERVICE_URL;
export const API_KIND = {
  webSettings: "get_web_settings",
  webForm: "get_web_form",
  getJobList: "get_job_list",
  locationList: "get_location_list",
  submitForm: "submit_web_form"
};
export const orgId = "3cd5af9c-787a-4081-96ca-18b311c10c1a";
export const job_form_id = "fa9803d6-ef90-44e2-a962-805693d07c41"
export const formKeys = {
  contactForm: "contact_us_form",
  jobApplyForm: "job_apply_form"
};

export const menus = [
  {
    menu: "Specialties",
    subMenu: [
      {
        name: "Cloud Computing",
        link: "CloudComputing",
        Component: CloudComputing
      },
      {
        name: "Edge Computing",
        link: "EdgeComputings",
        Component: EdgeComputing
      },
      {
        name: "Big Data & Analytics",
        link: "BigData",
        Component: BigData
      },
      {
        name: "AI & ML",
        link: "AIandML",
        Component: Ai
      },
      {
        name: "IoT and Digital Twins",
        link: "IoT",
        Component: Iot
      },
      {
        name: "Productivity",
        link: "Productivities",
        Component: Productivity
      },
      {
        name: "HPC",
        link: "HpcSpeciality",
        Component: Hpc
      }
    ]
  },
  {
    menu: "Solutions",
    customClass: "dropdownCentered",
    subMenu: [
      {
        name: "ManageX",
        link: "ManageX",
        Component: Managex
      },
      {
        name: "GaliEye",
        link: "GaliEye",
        Component: Impact
      },
      {
        name: "4Site",
        link: "4Site",
        Component: Foursite
      }
    ]
  },
  {
    menu: "Offerings",
    customClass: "dropdownCentered",
    subMenu: [
      {
        name: "Service Provider",
        link: "service-provider",
        Component: ServiceProvider
      },
      {
        name: "Managed Services",
        link: "Managed-Services",
        Component: ManagedServices
      },
      {
        name: "Consulting Services",
        link: "Consulting-Services",
        Component: ConsultingServices
      }
    ]
  },
  {
    menu: "Industries",
    subMenu: [
      {
        name: "Education",
        link: "Education",
        Component: Education
      },
      {
        name: "Energy",
        link: "Energy",
        Component: Energy
      },
      {
        name: "Finance",
        link: "Finance",
        Component: Finance
      },
      // {
      //   name: "Government",
      //   link: "Government",
      //   Component: Government
      // },
      {
        name: "Healthcare",
        link: "Healthcare",
        Component: Healthcare
      },
      {
        name: "Manufacturing",
        link: "Manufacturing",
        Component: Manufacturing
      },
      {
        name: "Public Sector",
        link: "PublicSector",
        Component: PublicSector
      },
      {
        name: "Retail",
        link: "Retail",
        Component: Retail
      },
      {
        name: "Transportation",
        link: "Automotive",
        Component: VehicleEngineering
      },
      {
        name: "Telecommunications",
        link: "Telecommunications",
        Component: Telecommunications
      }
    ]
  },
  {
    menu: "Studios",
    subMenu: [
      {
        name: "Innovation Center",
        link: "InnovationCenter",
        Component: InnovationCenter
      },
      {
        name: "Operations Center",
        link: "Operations-Center",
        Component: OperationsCenter
      },
      {
        name: "Design Studio",
        link: "DesignStudio",
        Component: DesignStudio
      },
      {
        name: "Data Lab",
        link: "DataLab",
        Component: DataLab
      },
      {
        name: "HPC Studio",
        link: "HPCStudio",
        Component: HpcStudio
      },
      {
        name: "Green Room",
        link: "GreenRoom",
        Component: GreenRoom
      }
    ]
  },
  {
    name: "Insights",
    link: "Insights",
    Component: Insights
  },
  {
    menu: "About Us",
    subMenu: [
      {
        name: "Who We Are",
        link: "about-us",
        Component: Culture
      },
      {
        name: "Culture & Diversity",
        link: "Culture-and-Diversity",
        Component: Offices
      }
      // {
      //   name: "Digital4Security",
      //   link: "Digital4Security",
      //   Component: Digital4Security
      // }
    ]
  },
  {
    name: "Careers",
    link: "Careers",
    Component: Careers
  }
];


