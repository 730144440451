import React from "react";
import bannerImage from "../../assets/img/AI_ML/AIandML_MainLanding.jpg";
import SpecialitiesContentObject from "./SpecialitiesContentObject";
import InnerBanner from "../UiComponents/InnerBanner";
import ValueProposition from "../UiComponents/ValueProposition";
import {
  HoverShowContent,
  MainTitle,
  MetaHelmet,
  ResourcesBlock,
  TextCarouselSlick,
  VerticalCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function Ai() {
  const root = SpecialitiesContentObject.AIandML;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <ValueProposition
        image={root.business.image}
        title={root.business.title}
        paragraph={root.business.paragraph}
        list={root.business.points}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle
            title={root.impact.title}
            paragraph={root.impact.paragraph}
          />
        </div>
        <div className="container">
          <HoverShowContent
            points={root.impact.points}
            customClass="hoverBig"
          />
        </div>
      </section>

      <VerticalCarouselSlick
        title={root.Solution.title}
        points={root.Solution.points}
      />

      <TextCarouselSlick
        bgBlack={true}
        slideScroll={2}
        title={root.whyUs.title}
        points={root.whyUs.points}
      />

      <ResourcesBlock
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
