import React from "react";
import StudiosContentObject from "./StudiosContentObject";
import InnerBanner from "../UiComponents/InnerBanner";
import bannerImage from "../../assets/img/studio/Studios-DevOps-MainBanner.jpg";
import ValueProposition from "../UiComponents/ValueProposition";
import WrapValueProposition from "../UiComponents/WrapValueProposition";
import { MetaHelmet } from "../UiComponents/ContentBlocks";

export default function Ai() {
  const root = StudiosContentObject.OperationsCenter;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        backgroundImage={bannerImage}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
      />
      <WrapValueProposition
        image={root.CiCd.image}
        isOntop={true}
        title={root.CiCd.title}
        paragraph={root.CiCd.paragraph}
        list={root.CiCd.points}
      />

      <WrapValueProposition
        image={root.agilePipelines.image}
        isOntop={true}
        customContainer={"agilePipelines"}
        mainContainerClass="bgDark"
        title={root.agilePipelines.title}
        paragraph={root.agilePipelines.paragraph}
        list={root.agilePipelines.points}
      />

      <WrapValueProposition
        image={root.labOps.image}
        isOntop={true}
        title={root.labOps.title}
        paragraph={root.labOps.paragraph}
        list={root.labOps.points}
      />

      <ValueProposition
        image={root.reliabilityEngineering.image}
        isOntop={true}
        mainContainerClass="bgDark"
        title={root.reliabilityEngineering.title}
        paragraph={root.reliabilityEngineering.paragraph}
        list={root.reliabilityEngineering.points}
      />
      <ValueProposition
        image={root.cloudService.image}
        // isOntop={true}
        customConiainer="flip"
        customtextcontainer="flipped-text-container"
        title={root.cloudService.title}
        paragraph={root.cloudService.paragraph}
        list={root.cloudService.points}
      />
    </React.Fragment>
  );
}
