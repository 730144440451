import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import $ from "jquery";
import InsideHeader from "../Components/InsideHeader";
import InsideFooter from "../Components/InsideFooter";

import { isMobile } from "react-device-detect";
import ScrollToHashElement from "../Pages/UiComponents/ScrollToHashElement";

export default function Layout() {
  const location = useLocation();
  useEffect(() => {
    if (!(location.pathname === "/" && !isMobile)) {
      $.getScript(process.env.PUBLIC_URL + "/assets/js/global.min.js");
      $("#menu-header-menu li span").removeAttr("style");
      $("header.primary .main-telephone").removeAttr("style");
      $(".button-bordered").removeAttr("style");
    }
  }, [location.pathname]);

  return (
    <>
      <InsideHeader />
      <main id="post-32" className="base" role="main">
        <Outlet />
        <ScrollToHashElement />
      </main>
      {!isMobile ? (
        location.pathname !== "/" && <InsideFooter />
      ) : (
        <InsideFooter />
      )}
    </>
  );
}
