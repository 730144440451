import React from "react";
import InnerBanner from "../UiComponents/InnerBanner";
import industriesBanner from "../../assets/img/education/educationHeader.jpg";
import IndustriesObject from "./IndustriesObject";
import {
  HoverShowContent,
  MainTitle,
  MetaHelmet,
  ResourcesBlock,
  SolutionsBlock,
  TextBoxColored,
  TitleImageContentBlock,
  VerticalCarouselSlick
} from "../UiComponents/ContentBlocks";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function Education() {
  const root = IndustriesObject.Education;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={industriesBanner}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />
      <section className="sectionContainer">
        <div className="container">
          <MainTitle
            title={root.Rising.title}
            paragraph={root.Rising.paragraph}
          />
        </div>
        <div className="container">
          <HoverShowContent
            customClass={"threeColumn"}
            points={root.Rising.points}
          />
        </div>
      </section>
      <TitleImageContentBlock
        title={root.Impacts.title}
        paragraph={root.Impacts.paragraph}
        points={root.Impacts.points}
      />

      <VerticalCarouselSlick
        title={root.Solution.title}
        paragraph={root.Solution.paragraph}
        points={root.Solution.points}
      />

      <section className="sectionContainer">
        <div className="container">
          <MainTitle title={root.DNA.title} paragraph={root.DNA.paragraph} />
        </div>
        <div className="container">
          <TextBoxColored points={root.DNA.points} />
        </div>
        <div className="container">
          <div className="educationD4S">
            <div className="D4SBanner">
              <img src={root.DNA.d4U.image} alt={root.DNA.title} />
              {root.DNA.d4U.paragraph}
            </div>
            <Link to="/Digital4Security">
              Learn More <ArrowForwardIcon />
            </Link>
          </div>
        </div>
        <div className="container educationParagraph">
          {root.DNA.subParagraph.map((item, index) => (
            <p
              key={index.toString()}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
        </div>
      </section>

      <SolutionsBlock
        title={root.TeraweSolutions.title}
        points={root.TeraweSolutions.points}
        customClass={"spaceAround"}
        bgBlack={true}
      />

      <ResourcesBlock
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
