import React from "react";
import SolutionsObject from "./SolutionsObject";
import {
  MainTitle,
  MetaHelmet,
  TextCarouselSlick,
  UniversitySlick
} from "../UiComponents/ContentBlocks";
import ManageXMainBanner from "../../assets/img/solutions/ManageXMainBanner.jpg";
import manageXLogo from "../../assets/img/solutions/ManageX-MainSolutionLogo.png";
import ScrollAnimation from "react-animate-on-scroll";
import ValueProposition from "../UiComponents/ValueProposition";
import { Link } from "react-router-dom";

import InnerBanner from "../UiComponents/InnerBanner";

export default function Managex() {
  const root = SolutionsObject.ManageX;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={ManageXMainBanner}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
        logo={manageXLogo}
      />

      <ValueProposition
        mainContainerClass="imageContained"
        title={root.whatManageX.title}
        image={root.whatManageX.image}
        paragraph={root.whatManageX.paragraph}
      />

      <ScrollAnimation animateIn="pulse" duration={1} animateOnce={true}>
        <section className="sectionContainer bgDark">
          <div className="container">
            <MainTitle title={root.manageXOfferings.title} />
          </div>
          <div className="container">
            <div className="doCloud solution-managex-blog-card-main-wrapper">
              {root.manageXOfferings.manageXBlogs.map((item, index) => (
                <div
                  className="solution-managex-blog-card-main-contatier"
                  key={index.toString()}
                >
                  <img src={item.image} alt={item.title} />
                  <p dangerouslySetInnerHTML={{ __html: item.paragraph }}></p>
                  <Link to={item.link} target="_blank">
                    {item.linkText}{" "}
                  </Link>
                </div>
              ))}
            </div>
            <div className="blog-managex-body">
              {root.manageXOfferings.paragraph.map((item, index) => (
                <p
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: item
                  }}
                />
              ))}
            </div>
          </div>
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="pulse" duration={1} animateOnce={true}>
        <TextCarouselSlick
          bgBlack={true}
          slideScroll={2}
          title={root.quotesCustomers.title}
          points={root.quotesCustomers.point}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="pulse" duration={1} animateOnce={true}>
        <UniversitySlick
          title={root.customerStories.title}
          points={root.customerStories.university}
        />
      </ScrollAnimation>
    </React.Fragment>
  );
}
