import React, { useEffect, useState } from "react";
import InnerBanner from "../UiComponents/InnerBanner";
import { AboutUsObject } from "./AboutUsObject";
import banner from "../../assets/img/aboutUs/whoweare/whowearebanner.jpg";
import mapimg from "../../assets/img/aboutUs/whoweare/Locations.png";
import mapMobile from "../../assets/img/aboutUs/whoweare/Location_mobile.png";

import {
  HoverSlideUp,
  IconBlockVertical,
  MainTitle,
  MetaHelmet
} from "../UiComponents/ContentBlocks";
import ValueProposition from "../UiComponents/ValueProposition";
import { ContentCopy, LinkedIn } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { API_KIND, BASE_URL, orgId } from "../../utils/config";
import Flag_of_Australia from "../../assets/img/aboutUs/whoweare/Flag_of_Australia.svg.png";
import Flag_of_Canada from "../../assets/img/aboutUs/whoweare/Flag_of_Canada.png";
import Flag_of_India from "../../assets/img/aboutUs/whoweare/Flag_of_India.png";
import Ireland_flag from "../../assets/img/aboutUs/whoweare/Ireland_flag_300.png";
import Flag_of_the_United_Kingdom from "../../assets/img/aboutUs/whoweare/Flag_of_the_United_Kingdom.png";
import Flag_of_the_United_States from "../../assets/img/aboutUs/whoweare/Flag_of_the_United_States.svg.png";
import { Alert, Snackbar } from "@mui/material";

export default function WhoWeAre() {
  const [location, setLocation] = useState(null);
  const [openBar, setOpenBar] = useState(false);
  const handleCopyText = () => {
    setOpenBar(true);
  };
  const root = AboutUsObject.whoWeAre;
  const flags = {
    GBR: Flag_of_the_United_Kingdom,
    CAN: Flag_of_Canada,
    AUS: Flag_of_Australia,
    USA: Flag_of_the_United_States,
    IND: Flag_of_India,
    IRL: Ireland_flag
  };
  useEffect(() => {
    axios
      .post(
        BASE_URL,
        {
          kind: API_KIND.locationList,
          data: {
            orgId: orgId
          }
        },
        {}
      )
      .then((res) => {
        let updatedLocations = res?.data?.data?.content?.map(
          (location, index) => ({
            ...location,
            image: flags[location.countryCode]
          })
        );
        setLocation(updatedLocations);
      })
      .catch((err) => console.log(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        backgroundImage={banner}
        bannerTitle={root.pageTitle.heading}
        featureParagraph={root.pageTitle.paragraph}
        featureParagraphClass="titleCustomParagraph"
      />
      <ValueProposition
        image={root.outStory.image}
        title={root.outStory.title}
        isOntop={true}
        mainContainerClass="bgBlack"
        customConiainer="flip"
        customtextcontainer="flipped-text-container"
        paragraph={root.outStory.paragraph}
      />

      {/* <BlockTextCentered
        customContainerClass="whoweare-custom-container"
        title={root.ourUnique.title}
        paragraph={root.ourUnique.paragraph}
      /> */}

      <HoverSlideUp
        bgBlack
        title={root.whatWeDo.title}
        customcontainer={"bgDark"}
        points={root.whatWeDo.points}
        customClassContainer={"wwd-customClassContainer"}
        hoverContainer={"whoweare-hoverContainer"}
      />

      <IconBlockVertical
        title={root.ourCommitmentPage.title}
        points={root.ourCommitmentPage.points}
      />

      <section className="sectionContainer bgDark">
        <div className="container bss-wrapper">
          <MainTitle title={root.buisnessSnapShot.title} />
        </div>
        <div className="container">
          {root.buisnessSnapShot.sections.map((items, index) => {
            return (
              <React.Fragment key={index.toString()}>
                <div
                  className="bss-mainContainer"
                  // style={{ backgroundImage: `url(${items.image})` }}
                >
                  <h5 className="bss-maintitle">{items.title}</h5>
                  <div className="bss-valueWrapper">
                    {items.points.map((itemVal, idx) => {
                      return (
                        <div className="bss-valueContainer"  key={idx.toString()}>
                          <p
                            className="bss-value"
                            dangerouslySetInnerHTML={{ __html: itemVal.value }}
                          ></p>
                          <p
                            className="bss-title"
                            dangerouslySetInnerHTML={{ __html: itemVal.title }}
                          ></p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </section>
      <section className="sectionContainer ">
        <div className="container">
          <MainTitle title={root.OurLocations.title} />
        </div>
        <div className="container">
          <img
            src={isMobile ? mapMobile : mapimg}
            alt="locations"
            className="locationMapImage"
          />
        </div>
        <div className="container">
          {location?.map((item, index) => (
            <div className="locations" key={index.toString()}>
              <div className="mapTitle">
                <img src={item.image} alt="map location" />
                <h5>{item.name}</h5>
              </div>
              <p>
                {item.streetAddress +
                  " " +
                  item.city +
                  " " +
                  item.state +
                  " " +
                  item.postalCode}
              </p>
              <ContentCopy
                onClick={() => {
                  navigator.clipboard.writeText(
                    item.streetAddress +
                      " " +
                      item.city +
                      " " +
                      item.state +
                      " " +
                      item.postalCode
                  );
                  handleCopyText();
                }}
              />
            </div>
          ))}
        </div>
      </section>
      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle title="Our Experienced Leadership Team" />
        </div>
        <div className="container leadershipTeam">
          {root.leadership.map((item, index) => (
            <div className="leaders" key={index.toString()}>
              {item.image && <img src={item.image} alt={item.title} />}
              <h5>{item.name}</h5>
              <p>{item.designation}</p>
              {item.link && (
                <a href={item.link} target="_blank" rel="noreferrer">
                  <LinkedIn />
                </a>
              )}
            </div>
          ))}
        </div>
      </section>
      <Snackbar
        open={openBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => setOpenBar(false)}
      >
        <Alert
          onClose={() => setOpenBar(false)}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          Text Copied
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
