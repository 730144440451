import Slider from "react-slick";
import { MainTitle } from "./ContentBlocks";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SolutionsObject from "../Solutions/SolutionsObject";

const DemoComponents = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  const root = SolutionsObject.FourSite;
  const navSettings = {
    slidesToShow: root.benefits4Site.points.length,
    swipeToSlide: false,
    focusOnSelect: true,
    infinite: false,
    arrows: false,
    touchMove: false,
    asNavFor: nav1,
    className: "sliderNav",
    ref: (slider) => (sliderRef2 = slider)
  };

  const slideSettings = {
    asNavFor: nav2,
    ref: (slider) => (sliderRef1 = slider),
    arrows: false,
    className: "slickTitleCon"
  };

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);
  return (
    <section className="sectionContainer slickSolution">
      <div className="container">
        <MainTitle title="Demo components for testing purpose" />
      </div>
      <div className="slickTitleNav">
        <div className="container containerBlock">
          <Slider {...navSettings}>
            {root.benefits4Site.points.map((item, index) => (
              <div key={index}>{item.title}</div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="container containerBlock">
        <Slider {...slideSettings}>
          {root.benefits4Site.points.map((item, index) => (
            <div key={index}>
              <img src={item.image} alt={item.title} />
              <div className="slideTitleContent">
                <h5>{item.title}</h5>
                <p dangerouslySetInnerHTML={{ __html: item.paragraph }} />
                <Link to={item.link} className="links">
                  Learn More
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default DemoComponents;
