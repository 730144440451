import React from "react";
import InnerBanner from "../UiComponents/InnerBanner";
import bannerImage from "../../assets/img/bigData/BigData_Banner.jpg";
import SpecialitiesContentObject from "./SpecialitiesContentObject";
import ValueProposition from "../UiComponents/ValueProposition";
import {
  HoverShowContent,
  ImageContent,
  MainTitle,
  MetaHelmet,
  ResourcesBlock,
  TitleParagraphBlock
} from "../UiComponents/ContentBlocks";

export default function BigData() {
  const root = SpecialitiesContentObject.BigData;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <ValueProposition
        image={root.about.image}
        title={root.about.title}
        paragraph={root.about.paragraph}
        list={root.about.points}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle
            title={root.globalCommodity.title}
            paragraph={root.globalCommodity.paragraph}
          />
        </div>
        <div className="container">
          <HoverShowContent points={root.globalCommodity.points} />
        </div>
      </section>

      <section className="sectionContainer">
        <div className="container">
          <MainTitle title={root.solution.title} />
        </div>
        <div className="container">
          <TitleParagraphBlock marker points={root.solution.points} />
        </div>
      </section>

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle
            title={root.differentiates.title}
            paragraph={root.differentiates.paragraph}
          />
        </div>
        <div className="container">
          <ImageContent
            title={root.differentiates.title}
            image={root.differentiates.image}
            points={root.differentiates.points}
          />
        </div>
      </section>
      <ResourcesBlock
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
