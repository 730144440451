import React from "react";
import bannerImage from "../../assets/img/PublicSector/PubSec_MainBannerImage.jpg";
import IndustriesObject from "./IndustriesObject";
import InnerBanner from "../UiComponents/InnerBanner";
import {
  FlipBlock,
  HoverSlideUp,
  IconBlockVertical,
  MainTitle,
  MetaHelmet,
  ResourcesBlock,
  SolutionsBlock,
  VerticalCarouselSlick
} from "../UiComponents/ContentBlocks";

export default function PublicSector() {
  const root = IndustriesObject.PublicSector;
  return (
    <React.Fragment>
      <MetaHelmet helmet={root.helmet} />
      <InnerBanner
        patch
        backgroundImage={bannerImage}
        bannerTitle={root.heroBanner.title}
        featureParagraph={root.heroBanner.paragraph}
      />

      <section className="sectionContainer bgBlack">
        <div className="container">
          <MainTitle
            title={root.Transformed.title}
            paragraph={root.Transformed.paragraph}
          />
        </div>
        <div className="container">
          {root.Transformed.points.map((item, index) => (
            <FlipBlock
              bgImage
              customContainerClass={"three"}
              image={item.image}
              title={item.title}
              key={index.toString()}
              paragraph={item.paragraph}
              link={item.link}
            />
          ))}
        </div>
      </section>

      <HoverSlideUp
        title={root.Opportunities.title}
        paragraph={root.Opportunities.paragraph}
        points={root.Opportunities.points}
      />

      <IconBlockVertical
        bgBlack={true}
        title={root.Challenges.title}
        points={root.Challenges.points}
      />

      <VerticalCarouselSlick
        title={root.Solution.title}
        paragraph={root.Solution.paragraph}
        points={root.Solution.points}
      />

      <SolutionsBlock title={root.Terawe.title} points={root.Terawe.points} />

      <ResourcesBlock
        black={true}
        title={root.resources.title}
        resourceTitle={root.resources.resourceTitle}
      />
    </React.Fragment>
  );
}
