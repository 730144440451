import React from "react";

import { isMobile } from "react-device-detect";
import { menus } from "../utils/config";
import DemoComponents from "../Pages/UiComponents/DemoComponent";

const InsightDetails = React.lazy(() =>
  import("../Pages/insights/InsightDetails")
);
const TermsAndCondition = React.lazy(() =>
  import("../Pages/TermsAndCondition")
);
const Privacy = React.lazy(() => import("../Pages/Privacypolicy"));
const CookiePolicy = React.lazy(() => import("../Pages/Cookiepolicy"));
const Notfound = React.lazy(() => import("./Notfound"));
const Home = React.lazy(() => import("../Pages/Home"));
const HomeMobile = React.lazy(() => import("../Pages/HomeMobile"));
const Digital4Security = React.lazy(() =>
  import("../Pages/about/Digital4Security")
);

const mapMenusToRoutes = (menus) => {
  return menus.flatMap((menu) => {
    if (menu.link && menu.Component) {
      return [
        {
          path: `/${menu.link}`,
          name: menu.name,
          Component: menu.Component
        }
      ];
    } else if (menu.subMenu) {
      return mapMenusToRoutes(menu.subMenu);
    } else {
      return [];
    }
  });
};

const indexRoutes = [
  ...mapMenusToRoutes(menus),
  { path: "/insight/:id", name: "InsightDetails", Component: InsightDetails },
  {
    path: "/terms-and-conditions",
    name: "Terms & Condition",
    Component: TermsAndCondition
  },
  { path: "/privacy-policy", name: "Terms & Condition", Component: Privacy },
  { path: "/cookie-policy", name: "Cookie Condition", Component: CookiePolicy },
  {
    path: "/",
    name: "PresentationPage",
    Component: isMobile ? HomeMobile : Home
  },
  {
    path: "*",
    name: "404",
    Component: Notfound
  },

  {
    name: "Digital4Security",
    path: "/Digital4Security",
    Component: Digital4Security
  },

  {
    name: "demo",
    path: "/demo",
    Component: DemoComponents
  }
];

export default indexRoutes;
